import React, { Component, Fragment, Form , } from 'react';
import moment from 'moment';
import Backdrop from '../../Backdrop/Backdrop';
import Modal from '../../Modal/Modal';
import Input from '../../Form/Input/Input';
import { required, length } from '../../../util/validators';
import { generateBase64FromImage } from '../../../util/image';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {storage} from '../../../util/fire';

const PROJECT_FORM = {
  title: {
    value: '',
    valid: false,
    touched: false,
    validators: [required, length({ min: 5 })]
  },
  description: {
    value: '',
    valid: false,
    touched: false,
    validators: [required, length({ min: 5 })]
  },
  clients: {
    value: '',
    label: '',
    valid: true,
    touched: true,
    validators: [required]
  },
  craftsmen: {
    value: '',
    valid: true,
    touched: true,
    validators: [required]
  },
  startDate: {
    value: new Date(),
    selected: new Date(),
    valid: false,
    touched: false,
    validators: [required]
  },
  finishDate: {
    value: new Date(),
    selected: new Date(),
    valid: false,
    touched: false,
    validators: [required]
  }
};

const fileData = new FormData();


class ProjectFeedEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      clients: '',
      _id: '',
      clientChoice: '',
    projectForm: PROJECT_FORM,
    formIsValid: false,
    newListOfCraftsmen: '',
    newListOfCustomers: '',
    selectedOption: '',
    selectedCustomer: '',
    attachments: [],
    tempImages: [],
    selectedFile: null,
    data: new FormData(),
    //imagePreview: null
  };
}

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.editing &&
      prevProps.editing !== this.props.editing &&
      prevProps.selectedProject !== this.props.selectedProject
    ) {
      var momentStartDate = moment(this.props.selectedProject.startDate).format('MM-DD-YYYY');
      var momentFinishDate = moment(this.props.selectedProject.finishDate).format('MM-DD-YYYY');

      const craftsmenNames = this.props.selectedProject.assignees.map(( craftsman, index ) => {
        const { _id, firstname , lastname } = craftsman
        return {
          _id: _id,
          label: firstname + ' ' + lastname
        }
      });
      let assigneesList = [];
      this.props.selectedProject.assignees.forEach(({ _id, firstname, lastname }) => assigneesList.push({ _id, firstname, lastname }));
      const projectForm = {
        title: {
          ...prevState.projectForm.title,
          value: this.props.selectedProject.title,
          valid: true
        },
        description: {
          ...prevState.projectForm.description,
          value: this.props.selectedProject.description,
          valid: true
        },
        clients: {
          ...prevState.projectForm.clients,
          value: this.state.selectedCustomer ? this.state.selectedCustomer : this.props.selectedProject.clients._id,
          label: this.state.selectedCustomer ? this.state.selectedCustomer : this.props.selectedProject.clients.firstname + ' ' + this.props.selectedProject.clients.lastname,
          valid: true
        },
        craftsmen: {
          ...prevState.projectForm.craftsmen,
          value: this.state.selectedOption ? this.state.selectedOption.map(craftsman => craftsman.value) : assigneesList.map(assignee => assignee._id),
          label: this.state.selectedOption ? this.state.selectedOption : assigneesList.map(assignee => assignee.firstname + ` ` + assignee.lastname + ` ` ),
          valid: true
        },
        startDate: {
          ...prevState.projectForm.startDate,
          value: momentStartDate,
          valid: true
        },
        finishDate: {
          ...prevState.projectForm.finishDate,
          value: momentFinishDate,
          valid: true
        },
      };
      this.setState({ projectForm: projectForm, formIsValid: true, attachments: this.props.selectedProject.attachments });
    }
  }

  projectInputChangeHandler = (input, value, files) => {
    let errors = {};
    if (value === "Vælg én" || value === "Vælg én eller flere håndværkere") {
      errors["Kunde"] = "Vælg venlist en kunde fra listen";
      this.setState({ formIsValid: false, errors: errors })
    } else {
      this.setState({ errors: null })
    }
    if (files) {
      generateBase64FromImage(files[0])
        .then(b64 => {
          this.setState({ imagePreview: b64 });
        })
        .catch(e => {
          this.setState({ imagePreview: null });
        });
    }
    this.setState(prevState => {
      let isValid = true;
      let isStartDateValid = true;
      for (const validator of prevState.projectForm[input].validators) {
        isValid = isValid && validator(value);
      }
      
      if(input == "finishDate"){
        isValid = moment(value).isSameOrAfter(this.state.projectForm.startDate.value);

        if(!isValid){
          toast.error('Slutdatoen kan ikke være før startdatoen', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        }
        for (const validator of prevState.projectForm["startDate"].validators) {
          isStartDateValid = validator(value);
        }
      } else if (input == "startDate") {
        isValid = moment(value).isSameOrBefore(this.state.projectForm.finishDate.value);
        if(!isValid){
          toast.error('Startdatoen kan ikke være efter slutdatoen', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
      }
    }
    
      let updatedForm;
      if(input == "finishDate"){
        updatedForm = {
          ...prevState.projectForm,
          [input]: {
            ...prevState.projectForm[input],
            valid: isValid,
            value: files ? files[0] : value
          },
          "finishDate": {
            ...prevState.projectForm["finishDate"],
            valid: isStartDateValid,
            value: value
          },
        };
      } else {
        updatedForm = {
          ...prevState.projectForm,
          [input]: {
            ...prevState.projectForm[input],
            valid: isValid,
            value: files ? files[0] : value
          }
        };
      }
      let formIsValid = true;
      for (const inputName in updatedForm) {
        formIsValid = formIsValid && updatedForm[inputName].valid;
      }
      return {
        projectForm: updatedForm,
        formIsValid: formIsValid
      };
    });
  };

  inputBlurHandler = input => {
    this.setState(prevState => {
      return {
        projectForm: {
          ...prevState.projectForm,
          [input]: {
            ...prevState.projectForm[input],
            touched: true
          }
        }
      };
    });
  };

  cancelProjectChangeHandler = () => {
    fileData.delete('file');
    this.setState({
      projectForm: PROJECT_FORM,
      formIsValid: false,
      data: fileData,
      attachments: [],
      selectedFile: null,
      tempImages: [],
    });
    this.props.onCancelEdit();
  };

  acceptProjectChangeHandler = async () => {
    let checkList = this.state.selectedOption;
    let customerChoice = this.state.selectedCustomer;
    if (this.props.enableEdit === true) {
      if (checkList === "") {
        checkList = this.props.selectedProject.assignees.map(( craftsman, index ) => {
          const { _id } = craftsman
          return {
            _id: _id,
          }
        })
      }
    if (customerChoice === "") {
      customerChoice = this.state.projectForm.clients.value;
    }
    //TODO Add deletion of files from firebase-storage
    // if(this.state.tempImages.length > 0){
    //   this.state.tempImages.forEach(tempFile => {
    //     let desertRef = storage.refFromURL(tempFile);
    //     desertRef.delete().then(function() {
    //      //TODO HANDLE SUCCES
    //     }).catch(function(error) {
    //      //TODO HANDLE ERROR
    //     });
    //   })
    // }
      const project = {
        title: this.state.projectForm.title.value,
        description: this.state.projectForm.description.value,
        clients: customerChoice.value ? customerChoice.value : this.state.projectForm.clients.value,
        craftsmen: checkList ? checkList : this.state.projectForm.craftsmen.value.map(( craftsman, index ) => {
          const { value } = craftsman
          return {
            _id: value,
          }
        }),
        startDate: this.state.projectForm.startDate.value,
        finishDate: this.state.projectForm.finishDate.value,
        attachments: this.state.attachments,
      };
      this.props.onFinishEdit(project);
      this.setState({
        projectForm: PROJECT_FORM,
        selectedOption: '',
        selectedCustomer: '',
        formIsValid: false,
        //imagePreview: null
      });
    } else {
    if (this.state.errors === null && checkList !== null && checkList.length !== 0 && customerChoice !== null && customerChoice.length !== 0) {
    const listOfCraftsmen = this.state.selectedOption.map(( craftsman, index ) => {
      const { value } = craftsman
      return {
        _id: value,
      }
    });
    const project = {
      title: this.state.projectForm.title.value,
      description: this.state.projectForm.description.value,
      clients: this.state.selectedCustomer.value,
      craftsmen: listOfCraftsmen,
      startDate: this.state.projectForm.startDate.value,
      finishDate: this.state.projectForm.finishDate.value,
      attachments: this.state.attachments,
    };
    this.props.onFinishEdit(project);
    this.setState({
      projectForm: PROJECT_FORM,
      selectedOption: '',
      selectedCustomer: '',
      formIsValid: false,
      //imagePreview: null
    });
  }  else {
    toast.error("Udfyld venligst alle felterne korrekt.", {
      position: "top-center",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }
  };
}

  handleOptionChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  handleCustomerChange = (selectedCustomer) => {
    this.setState({ selectedCustomer });
  }

  onChangeHandler=event=>{
    this.setState({
      selectedFile: event.target.files[0],
    })

}
/* TODO Files are saved with selected filename. However on deletion from firebase-storage the URL link is used.
   This will delete any files from the storage that might have duplicate filenames. For now this will suffice however it might
   be an issue later on if multiple project contains files with the same filename. To fix this we need to implement deletion
   from firebase-storage with filenames instead of URL link and filenames should be given a unique randomized pre-or suffix.
    */ 
onSelectFile = () => {
  if(this.state.selectedFile){
    let uploadTask = storage
    .ref(`${this.state.selectedFile.name}`)
    .put(this.state.selectedFile);

    uploadTask.on(
      "state_changed",
      snapshot => {},
      error => {
        //TODO HANDLE ERROR
      },
      () => {
        storage
        .ref()
        .child(this.state.selectedFile.name)
        .getDownloadURL()
        .then(url => {
          this.setState({attachments: [...this.state.attachments, url], selectedFile: null});
        })
      }
    )
    
  }
}

onDeleteSelectedFile = (filename) => {
  const attachmentFiles = this.state.attachments;
  let desertRef = storage.refFromURL(filename);
  let filteredAttachments = attachmentFiles.filter(name => name !== filename);

  this.setState({attachments: filteredAttachments, tempImages: [...this.state.tempImages, filename]});

  //TODO Add deletion of files from firebase-storage
  // if(!this.props.selectedProject){
  // desertRef.delete().then(function() {
  //     //TODO HANDLE SUCCES 
  //   }).catch(function(error) {
  //     //TODO HANDLE ERROR
  //   });
  // }
}

  createSelectors() {
    let newCraftsmenList = [];
    this.props.craftsmen.forEach(({ _id, firstname }) => newCraftsmenList.push({ _id, firstname }));
    if (newCraftsmenList.length > 0) {
      this.setState({
        newestcraftsmenList: newCraftsmenList.map(craftsmen => {
          return {
            value: craftsmen._id,
            label: craftsmen.firstname + ' ' + craftsmen.lastname,
          }
        })
      })
    }
  }

  truncate(str, n){
    return (str.length > n) ? str.substr(0, n-1) + '...' : str;
  };

  render() {
    const CraftsmenList = this.props.newListOfCraftsmen;
    const CustomerList = this.props.newListOfCustomers;
    return this.props.editing ? (
      <Fragment>
        <ToastContainer />
        <Backdrop onClick={this.cancelProjectChangeHandler} />
        <Modal
          title="Projekt"
          acceptEnabled={this.state.formIsValid}
          onCancelModal={this.cancelProjectChangeHandler}
          onAcceptModal={this.acceptProjectChangeHandler}
          isLoading={this.props.loading}
          showCancelButton={true}
        >
          <form>
            <Input
              id="title"
              label="Titel"
              control="input"
              onChange={this.projectInputChangeHandler}
              onBlur={this.inputBlurHandler.bind(this, 'title')}
              valid={this.state.projectForm['title'].valid}
              touched={this.state.projectForm['title'].touched}
              value={this.state.projectForm['title'].value}
            />
            <Input
              id="description"
              label="Beskrivelse"
              control="textarea"
              rows="50"
              onChange={this.projectInputChangeHandler}
              onBlur={this.inputBlurHandler.bind(this, 'description')}
              valid={this.state.projectForm['description'].valid}
              touched={this.state.projectForm['description'].touched}
              value={this.state.projectForm['description'].value}
              placeholder={`I dette felt skal du opsummere projektet for kunden.
              Eks. “Efter aftale med “kunde” den “dato” vil vi starte på maling af alle vægge, loft og gulv i kundens lejlighed. Dette inkluderer afvaskning og grunding, 1. maling, 2. maling og tørretid. Der er afsat “x antal dage” til projektets forløb. Prisen for arbejdet er efter aftale på “xx kr.” Malermester “navn” er den ansvarlige for projektet og kan kontaktes på “telefonnummer”`}
            />
            <Input
              id="clients"
              isMulti={false}
              label="Kunde"
              control="selectMultiple"
              onChange={this.handleCustomerChange}
              onBlur={this.inputBlurHandler.bind(this, 'clients')}
              valid={this.state.projectForm['clients'].valid}
              touched={this.state.projectForm['clients'].touched}
              value={this.state.selectedCustomer}
              placeholder={this.state.projectForm['clients'].label}
              options={CustomerList}
            />
            <Input
              id="craftsmen"
              label="Vælg en eller flere håndværkere"
              control="selectMultiple"
              onChange={this.handleOptionChange}
              onBlur={this.inputBlurHandler.bind(this, 'craftsmen')}
              valid={this.state.projectForm['craftsmen'].valid}
              touched={this.state.projectForm['craftsmen'].touched}
              value={this.state.selectedOption}
              placeholder={this.state.projectForm['craftsmen'].label}
              options={CraftsmenList}
              isMulti={true}
            />
            <div style={{
              border: '1px solid',
              borderColor: '#D0D0D0',
              padding: '10px',
            }}>
              <h3>Tilføj filer</h3>
              <input type="file" name="file" onChange={this.onChangeHandler}/>
              <p onClick={()=>{this.onSelectFile()}} style={{color: '#29CC97', cursor: 'pointer'}}>Tilføj</p>
              {this.state.attachments && this.state.attachments.map((attachment, idx) => {
                return <div key={idx} style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}><p>{`${idx+1}. ${this.truncate(attachment, 30)}`}</p><p style={{color:'#F7685B', cursor: 'pointer'}} onClick={()=>{this.onDeleteSelectedFile(attachment)}}>Slet</p></div>;
              })}
            </div>
            <div className="datePickerCustom">
            <Input
              id="startDate"
              label="Vælg start dato"
              control="startDate"
              onChange={this.projectInputChangeHandler}
              onBlur={this.inputBlurHandler.bind(this, 'startDate')}
              valid={this.state.projectForm['startDate'].valid}
              touched={this.state.projectForm['startDate'].touched}
              value={this.state.projectForm['startDate'].value}
              selected={this.state.projectForm['startDate'].selected}
            />
            </div>
            <div className="datePickerCustom">
            <Input
              id="finishDate"
              label="Vælg slut dato"
              control="finishDate"
              onChange={this.projectInputChangeHandler}
              onBlur={this.inputBlurHandler.bind(this, 'finishDate')}
              valid={this.state.projectForm['finishDate'].valid}
              touched={this.state.projectForm['finishDate'].touched}
              value={this.state.projectForm['finishDate'].value}
              selected={this.state.projectForm['finishDate'].selected}
            />
            </div>
            
          </form>
        </Modal>
      </Fragment>
    ) : null;
  }
}

export default ProjectFeedEdit;
