import React, { Component } from 'react';

import api from '../../../util/config';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import './SingleProject.css';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ChatTwoToneIcon from '@material-ui/icons/ChatTwoTone';
import MilestoneFeedEdit from '../../../components/MilestoneFeed/MilestoneFeedEdit/MilestoneFeedEdit';
import TaskFeedEdit from '../../../components/TaskFeedEdit/TaskFeedEdit';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ReactComponent as ChatLogo } from '../../../assets/chat_icon.svg';
import { ReactComponent as BellLogo } from '../../../assets/bell_icon.svg';
import { ReactComponent as PenLogo } from '../../../assets/pen_icon.svg';
import { ReactComponent as ChatLogoDark } from '../../../assets/chat_icon_dark.svg';
import { ReactComponent as PaperPlusDark } from '../../../assets/paper_plus_icon_dark.svg';
import { NavLink } from 'react-router-dom';
import defaultAvatar from '../../../assets/default.png'
import Modal from '../../../components/Modal/CustomModal'

class SingleProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      ProjectTitle: '',
      ProjectDescription: '',
      ProjectStartDate: '',
      ProjectFinishDate: '',
      ProjectMilestones: '',
      ListOfAllTasks: [],
      client: '',
      assignees: '',
      creator: '',
      author: '',
      name: '',
      created: '',
      customers: [],
      craftsmen: [],
      CompanyAdminMilestones: [],
      totalCustomers: [],
      totalCraftsmen: [],
      isEditing: false,
      isTaskEditing: false,
      milestones: [],
      totalMilestones: 0,
      editMilestone: null,
      editingMilestone: false,
      tasks: [],
      status: '',
      milestonePage: 1,
      milestonesLoading: true,
      editLoading: false,
      foundMilestones: false,
      projectMilestone: '',
      milestonesTasks: [],
      allTasks: [],
      startRendering: false,
      unassignedTasks: [],
      newunassignedTaskList: [],
      startSelector: false,
      bellActive: false,
      bellRing: false,
      notificationList: [],
      openList: false,
      listEmpty: true,
      loading: false,
      unreadNotifications: false,
      finishedLoading: false,
      ProjectTODO: '',
      editingTodo: false,
      ProjectEditingTODO:'',
      attachments: [],
    };

  }

  handleChangeTODO = (event) => {
    this.setState({ ProjectEditingTODO: event.target.value });
  }

  handleSubmitTODO(event) {
    let craftsmenIds = [];
    for (let i = 0; i < this.state.assignees.length; i++) {
      if (this.state.assignees[i]._id === undefined) {
        craftsmenIds.push(this.state.assignees[i].value);
      } else {
        craftsmenIds.push(this.state.assignees[i]._id);
      }
    }

    const graphqlQuery = {
      query: `
      mutation UpdateExistingProject($projectId: ID!, $title: String!, $description: String!, $status: String, $startDate: String, $finishDate: String, $craftsmen: [ID!], $clients: ID!, $TODO: String, $attachments: [String]) {
        updateProject(id: $projectId, projectInput: {title: $title, description: $description, status: $status, startDate: $startDate, finishDate: $finishDate, aId: $craftsmen, cId: $clients, TODO: $TODO, attachments: $attachments}) {
          _id
          title
          description
          status
          startDate
          finishDate
          attachments
          TODO
          assignees {
            _id
            firstname
            lastname
            phone
            avatarImage
          }
          clients {
            _id
            firstname
            lastname
            avatarImage
          }
          createdAt
          updatedAt
        }
      }
    `,
      variables: {
        projectId: this.props.match.params.projectId,
        title: this.state.ProjectTitle,
        description: this.state.ProjectDescription,
        status: this.state.status,
        clients: this.state.client._id,
        craftsmen: craftsmenIds,
        startDate: this.state.ProjectStartDate,
        finishDate: this.state.ProjectFinishDate,
        TODO: this.state.ProjectEditingTODO,
        attachments: this.state.attachments,
      }
    }
    fetch(api.baseUrl, {
      method: 'POST',
      body: JSON.stringify(graphqlQuery),
      headers: {
        Authorization: 'Bearer ' + this.props.token,
        'Content-Type': 'application/json'
      },

    })
      .then(res => {
        this.setState({ProjectTODO: this.state.ProjectEditingTODO})
        return res.json();
      })
    event.preventDefault();
  }

  handleNotificationsList = () => {
    this.setState(state => {
      return {
        bellRing: false,
        bellActive: false,
        openList: !state.openList,
        unreadNotifications: false,
      };
    });
  };

  useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }));

  async componentDidMount() {
    this.loadCraftsmen();
    this.fetchInfo();
    this.getAllTasks();
  }

  componentDidUpdate() {

  }
  async fetchInfo(direction) {
    this.setState({
      startSelector: true,
      milestones: [],
      updatedMilestonesList: [],
      totalMilestones: [],
      ProjectTitle: '',
      ProjectDescription: '',
      ProjectMilestones: '',
      ProjectStartDate: '',
      ProjectFinishDate: '',
      client: '',
      allTasks: '',
      newListOfMilestones: '',
    })
    if (direction) {
      this.setState({ milestonesLoading: true, milestones: [] });
    }
    let page = this.state.milestonePage;
    if (direction === 'next') {
      page++;
      this.setState({ milestonePage: page });
    }
    if (direction === 'previous') {
      page--;
      this.setState({ milestonePage: page });
    }
    const projectId = this.props.match.params.projectId;
    const graphqlQuery = {
      query: `query FetchSingleProject($projectId: ID!) {
          project(id: $projectId) {
            _id
            title
            description
            startDate
            finishDate
            status
            TODO
            attachments
            tasks {
              _id
              title
              status
              description
              startDate
              deadlineDate
              craftsmen {
                _id
                firstname
                lastname
                avatarImage
              }
              milestone {
                _id
                title
                description
                status
              }
            }
            milestones {
              _id
              title
              description
              startDate
              status
              deadlineDate
              craftsmen {
                _id
                firstname
                lastname
                avatarImage
              }
              tasks {
                _id
                status
              }
            }
            clients {
              _id
              firstname
              lastname
              email
              avatarImage
            }
            assignees {
              _id
              firstname
              lastname
              phone
              avatarImage
            }
          }
        }
      `,
      variables: {
        projectId: projectId
      }
    };
    await fetch(api.baseUrl, {
      method: 'POST',
      body: JSON.stringify(graphqlQuery),
      headers: {
        Authorization: 'Bearer ' + this.props.token,
        'Content-Type': 'application/json'
      },

    })
      .then(res => {
        return res.json();
      })
      .then(resData => {
        this.setState({
          ProjectId: resData.data.project._id,
          ProjectTitle: resData.data.project.title,
          ProjectDescription: resData.data.project.description,
          ProjectMilestones: resData.data.project.milestones,
          ProjectStartDate: moment(resData.data.project.startDate).format('MM/DD/YYYY'),
          ProjectFinishDate: moment(resData.data.project.finishDate).format('MM/DD/YYYY'),
          assignees: resData.data.project.assignees,
          client: resData.data.project.clients,
          allTasks: resData.data.project.tasks,
          ProjectTODO: resData.data.project.TODO,
          ProjectEditingTODO: resData.data.project.TODO,
          attachments: resData.data.project.attachments,
        })
        if (resData.errors) {
          throw new Error('Failed to fetch project');
        }
      })
      .catch(err => {
        throw new Error(err);
      });
    if (this.state.ProjectMilestones.length > 0) {
      this.setState({
        foundMilestones: true,
        newListOfMilestones: this.state.ProjectMilestones.map(milestone => {
          return {
            value: milestone._id,
            label: milestone.title,
          }
        })
      })
    }
    if (this.state.foundMilestones === true) {
      let projectMilestoneList = [];
      for (let i = 0; i < this.state.ProjectMilestones.length; i++) {
        const graphqlQuery = {
          query: `query FetchSingleMilestone($milestoneId: ID!) {
              milestone(id: $milestoneId) {
                _id
                title
                description
                startDate
                deadlineDate
                status
                craftsmen {
                  _id
                  firstname
                  lastname
                  avatarImage
                }
                tasks {
                  _id
                  title
                  description
                  status
                  startDate
                  deadlineDate
                  milestone {
                    _id
                    title
                    status
                    description
                  }
                  craftsmen {
                    _id
                    firstname
                    lastname
                    avatarImage
                  }
                }
              }
            }
          `,
          variables: {
            milestoneId: this.state.ProjectMilestones[i]._id
          }
        };
        await fetch(api.baseUrl, {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + this.props.token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(graphqlQuery)
        })
          .then(res => {
            return res.json();
          })
          .then(resData => {
            if (resData.errors) {
              throw new Error('Failed to fetch project');
            }
            this.setState({
              projectMilestone: resData.data.milestone,
              milestonesTasks: projectMilestoneList,
            });
            projectMilestoneList.push(this.state.projectMilestone);
          })
          .catch(err => {
            throw new Error(err);
          });
      }
    }
    this.setState({
      startRendering: true
    })
  }



  async getAssigneesInfo() {
    const uaId = this.state.aId;
    const graphqlQuery = {
      query: `query FetchSingleClient($uaId: ID!) {
          user(id: $uaId) {
            _id
            firstname
            lastname
            phone
            email
            avatarImage
          }
        }
      `,
      variables: {
        uaId: uaId
      }
    };
    await fetch(api.baseUrl, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + this.props.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(graphqlQuery)
    })
      .then(res => {
        return res.json();
      })
      .then(resData => {
        if (resData.errors) {
          throw new Error('Failed to fetch project');
        }
        this.setState({
          afName: resData.data.user.firstname,
          alName: resData.data.user.lastname
        });
      })
      .catch(err => {
        throw new Error(err);
      });
  }
  newMilestoneHandler = () => {
    this.setState({ isEditing: true });
  };

  deleteMilestoneHandler = milestoneId => {
    this.setState({
      milestonesLoading: true,
      startRendering: false,
    });
    const graphqlQuery = {
      query: `
          mutation {
            deleteMilestone(id: "${milestoneId}")
          }
        `
    };
    fetch(api.baseUrl, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + this.props.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(graphqlQuery)
    })
      .then(res => {
        return res.json();
      })
      .then(resData => {
        if (resData.errors) {
          throw new Error('Deleting the milestone failed!');
        }
        try {
          this.fetchInfo();
        } catch (err) {
          throw new Error(err)
        }
        this.setState({
          startRendering: true
        })
        this.renderMilestoneTableData();
      })
      .catch(err => {
        this.setState({
          milestonesLoading: false,
          startRendering: true
        });
      });
  };

  deleteTaskHandler = taskId => {
    this.setState({
      tasksLoading: true,
      startRendering: false,
    });
    const graphqlQuery = {
      query: `
          mutation {
            deleteTask(id: "${taskId}")
          }
        `
    };
    fetch(api.baseUrl, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + this.props.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(graphqlQuery)
    })
      .then(res => {
        return res.json();
      })
      .then(resData => {
        if (resData.errors) {
          throw new Error('Deleting the task failed!');
        }
        this.fetchInfo();
      })
      .catch(err => {
        this.setState({
          tasksLoading: false
        });
      });
  };


  loadCraftsmen = () => {
    this.setState({
      totalCraftsmen: [],
      newListOfCraftsmen: [],
      craftsmen: []
    })
    const graphqlQuery = {
      query: `
        {
          users {
            users {
              _id
              firstname
              lastname
              role
              email
              avatarImage
              status
              company
            }
            
          }
        }
        `
    }
    // Fetch all users and select any who matches role 'Craftsman'. Then add to new list.
    fetch(api.baseUrl, {
      method: 'POST',
      body: JSON.stringify(graphqlQuery),
      headers: {
        Authorization: 'Bearer ' + this.props.token,
        'Content-Type': 'application/json'
      },
    })
      .then(res => {
        return res.json();
      })
      .then(resData => {
        if (resData.errors && resData.errors[0].status === 422) {
          throw new Error(
            'Validering fejlede. Invalid input'
          );
        }
        if (resData.errors) {
          throw new Error('Kunne ikke hente brugere.');
        }
        this.setState({ craftsmen: resData.data.users.users })
        for (let i = 0; i <= this.state.craftsmen.length - 1; i++) {
          if (this.state.craftsmen[i].role === 'Craftsman') {
            this.state.totalCraftsmen.push(this.state.craftsmen[i]);
          }
        }

        if (this.props.role == "SuperAdmin") {
          this.setState({
            totalCraftsmen: this.state.totalCraftsmen,
            newListOfCraftsmen: this.state.totalCraftsmen.map(craftsman => {
              return {
                value: craftsman._id,
                label: craftsman.firstname + ' ' + craftsman.lastname,
              }
            })
          });
        } else {
          let newListOfCompanyCraftsmen = [];
          this.state.totalCraftsmen.forEach(craftsman => {
            if (craftsman.company != null && craftsman.company == this.props.userId) {
              newListOfCompanyCraftsmen.push({
                value: craftsman._id,
                label: craftsman.firstname + ' ' + craftsman.lastname,
              });
            }
          });
          this.setState({
            totalCraftsmen: this.state.totalCraftsmen,
            newListOfCraftsmen: newListOfCompanyCraftsmen
          });
        }
      }).catch(this.catchError);
  };


  async getMilestoneInfo(milestoneId) {
    const graphqlQuery = {
      query:
        `query FetchSingleMilestone($milestoneId: ID!) {
          milestone(id: $milestoneId) {
              _id
              title
              description
              startDate
              deadlineDate
              status
              craftsmen {
                _id
                firstname
                lastname
                avatarImage
              }
              tasks {
                _id
                title
                description
                status
                startDate
                deadlineDate
                milestone {
                  _id
                  title
                  status
                  description
                }
                craftsmen {
                  _id
                  firstname
                  lastname
                  avatarImage
                }
              }
            }
          }
        `,
      variables: {
        milestoneId: milestoneId
      }
    };
    await fetch(api.baseUrl, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + this.props.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(graphqlQuery)
    })
      .then(res => {
        return res.json();
      })
      .then(resData => {
        if (resData.errors) {
          throw new Error('Failed to fetch milestone');
        }
        this.setState({
          newSelectedMilestone: resData.data.milestone.craftsmen
        });
      })
      .catch(err => {
        throw new Error(err);
      });
  }


  startEditMilestoneHandler = milestoneId => {
    this.getMilestoneInfo(milestoneId);
    this.setState(prevState => {
      const loadedMilestone = { ...prevState.ProjectMilestones.find(m => m._id === milestoneId) };
      return {
        isEditing: true,
        enableEdit: true,
        editMilestone: loadedMilestone
      };
    });
  };

  newTaskHandler = () => {
    this.setState({ isTaskEditing: true });
  };

  async getAllTasks() {
    const graphqlQuery = {
      query:
        `query {
          tasks {
            tasks{
              _id
              title
              description
              startDate
              status
              deadlineDate
              craftsmen {
                _id
                firstname
                lastname
                avatarImage
              }
              milestone {
                _id
                title
                description
                status
                startDate
                deadlineDate
              }
              }
            }
          }
        `,
    };
    await fetch(api.baseUrl, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + this.props.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(graphqlQuery)
    })
      .then(res => {
        return res.json();
      })
      .then(resData => {
        this.setState({
          ListOfAllTasks: resData.data.tasks.tasks,
        });
        if (resData.errors) {
          throw new Error(resData.errors);
        }
      })
      .catch(err => {
        this.hiddenException(err);
      });
  }

  hiddenException(err) {
    return `${err}`;
  }

  async getTaskInfo(taskId) {
    const graphqlQuery = {
      query:
        `query FetchSingleTask($taskId: ID!) {
          task(id: $taskId) {
              _id
              title
              description
              startDate
              status
              deadlineDate
              craftsmen {
                _id
                firstname
                lastname
                avatarImage
              }
              milestone {
                _id
                title
                description
                status
                startDate
                deadlineDate
              }
            }
          }
        `,
      variables: {
        taskId: taskId
      }
    };
    await fetch(api.baseUrl, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + this.props.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(graphqlQuery)
    })
      .then(res => {
        return res.json();
      })
      .then(resData => {
        if (resData.errors) {
          throw new Error(resData.errors);
        }
        this.setState({
          newSelectedTask: resData.data.task.craftsmen,
        });
      })
      .catch(err => {
        throw new Error(err);
      });
  }

  startEditTaskHandler = taskId => {
    this.getTaskInfo(taskId);
    this.setState(prevState => {
      const loadedTask = { ...prevState.allTasks.find(t => t._id === taskId) };
      return {
        isTaskEditing: true,
        enableEdit: true,
        editTask: loadedTask
      };
    });
  };

  cancelEditHandler = () => {
    this.setState({ isEditing: false, editMilestone: null, editTask: null });
  };

  finishEditHandler = (milestoneData) => {
    this.setState({
      editLoading: true
    });
    let craftsmenMilestoneIds = [];
    for (let i = 0; i < milestoneData.craftsmen.length; i++) {
      if (milestoneData.craftsmen[i]._id === undefined) {
        if (milestoneData.craftsmen[i].value) {
          craftsmenMilestoneIds.push(milestoneData.craftsmen[i].value);
        } else {
          craftsmenMilestoneIds.push(milestoneData.craftsmen[i])
        }
      } else if (milestoneData.craftsmen[i]._id !== undefined) {
        craftsmenMilestoneIds.push(milestoneData.craftsmen[i]._id);
      } else {
        craftsmenMilestoneIds.push(milestoneData.craftsmen[i].i)
      }
    }
    let graphqlQuery = {
      query: `
          mutation CreateNewMilestone($title: String!, $description: String!, $status: String, $startDate: String, $deadlineDate: String, $craftsmen: [ID!], $progress: Int, $projectId: ID, $creator: ID) {
            createMilestone(milestoneInput: {title: $title, description: $description, status: $status, startDate: $startDate, deadlineDate: $deadlineDate, aId: $craftsmen, progress: $progress, projectId: $projectId, creator: $creator }) {
              _id
              title
              description
              status
              startDate
              deadlineDate
              tasks {
                _id
              }
            }
          }
        `,
      variables: {
        title: milestoneData.title,
        description: milestoneData.description,
        craftsmen: craftsmenMilestoneIds,
        progress: 1,
        status: "Ikke påbegyndt",
        projectId: this.props.match.params.projectId,
        startDate: milestoneData.startDate,
        deadlineDate: milestoneData.deadlineDate,
        creator: this.props.userId
      }
    };

    if (this.state.editMilestone) {
      graphqlQuery = {
        query: `
            mutation UpdateExistingMilestone($milestoneId: ID!, $title: String!, $description: String!, $status: String, $startDate: String, $progress: Int, $deadlineDate: String, $craftsmen: [ID!] ) {
              updateMilestone(id: $milestoneId, milestoneInput: {title: $title, description: $description, status: $status, startDate: $startDate, progress: $progress, deadlineDate: $deadlineDate, aId: $craftsmen}) {
                _id
                title
                description
                status
                startDate
                deadlineDate
                tasks {
                  _id
                }
              }
            }
          `,
        variables: {
          milestoneId: this.state.editMilestone._id,
          title: milestoneData.title,
          description: milestoneData.description,
          progress: 1,
          status: "Ikke påbegyndt",
          craftsmen: craftsmenMilestoneIds,
          startDate: milestoneData.startDate,
          deadlineDate: milestoneData.deadlineDate
        }
      }
    }

    return fetch(api.baseUrl, {
      method: 'POST',
      body: JSON.stringify(graphqlQuery),
      headers: {
        Authorization: 'Bearer ' + this.props.token,
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        return res.json();
      })
      .then(resData => {
        if (resData.errors && resData.errors[0].status === 422) {
          throw new Error(
            'Validering fejlede. Invalid input'
          );
        }
        if (resData.errors) {
          throw new Error('Oprettelse af milepæl fejlede.');
        }
        let resDataField = 'createMilestone';
        if (this.state.editMilestone) {
          resDataField = 'updateMilestone';
        }
        const milestone = {
          _id: resData.data[resDataField]._id,
          title: resData.data[resDataField].title,
          description: resData.data[resDataField].description,
          status: resData.data[resDataField].status,
          craftsmen: resData.data[resDataField].craftsmen,
          createdAt: resData.data[resDataField].createdAt,
          startDate: resData.data[resDataField].startDate,
          deadlineDate: resData.data[resDataField].deadlineDate
        };
        this.setState(prevState => {
          let updatedMilestones = [...prevState.milestones];
          let updatedTotalMilestones = prevState.totalMilestones;
          if (prevState.editMilestone) {
            const milestoneIndex = prevState.milestones.findIndex(
              m => m._id === prevState.editMilestone._id
            );
            updatedMilestones[milestoneIndex] = milestone;
          } else {
            updatedTotalMilestones++;
            if (prevState.milestones.length >= 3) {
              updatedMilestones.pop();
            }
            updatedMilestones.unshift(milestone);
          }
          this.fetchInfo();
          try {
          } catch (err) {
            throw new Error(err)
          }
          return {
            milestones: updatedMilestones,
            isEditing: false,
            editMilestone: null,
            editLoading: false,
            totalMilestones: updatedTotalMilestones,
            newListOfMilestones: updatedMilestones
          };
        });
        this.componentDidMount();
      })
      .catch(err => {
        this.setState({
          isEditing: false,
          editMilestone: null,
          editLoading: false,
          error: err
        });
      });
  };

  cancelTaskEditHandler = () => {
    this.setState({ isTaskEditing: false, editTask: null });
  };

  finishTaskEditHandler = taskData => {
    this.setState({
      editLoading: true
    });
    if (taskData.milestone === "Denne opgave skal ikke tilknyttes en milepæl!" || taskData.milestone._id === "Denne opgave skal ikke tilknyttes en milepæl!" || taskData.milestone === undefined) {
      let craftsmenTaskIds = [];
      for (let i = 0; i < taskData.craftsmen.length; i++) {
        if (taskData.craftsmen[i]._id === undefined) {
          if (taskData.craftsmen[i].value) {
            craftsmenTaskIds.push(taskData.craftsmen[i].value);
          } else {
            craftsmenTaskIds.push(taskData.craftsmen[i])
          }
        } else if (taskData.craftsmen[i]._id !== undefined) {
          craftsmenTaskIds.push(taskData.craftsmen[i]._id);
        } else {
          craftsmenTaskIds.push(taskData.craftsmen[i].i)
        }
      }
      let graphqlQuery = {
        query: `
          mutation CreateNewTask($title: String!, $description: String!, $status: String, $startDate: String, $deadlineDate: String, $craftsmen: [ID!], $progress: Int, $projectId: ID, $creator: ID) {
            createTask(taskInput: {title: $title, description: $description, status: $status, startDate: $startDate, deadlineDate: $deadlineDate, aId: $craftsmen, progress: $progress, projectId: $projectId, creator: $creator }) {
              _id
              title
              description
              status
              startDate
              deadlineDate
              craftsmen {
                _id
              }
            }
          }
        `,
        variables: {
          title: taskData.title,
          description: taskData.description,
          craftsmen: craftsmenTaskIds,
          progress: 1,
          status: "Ikke påbegyndt",
          projectId: this.props.match.params.projectId,
          startDate: taskData.startDate,
          deadlineDate: taskData.deadlineDate,
          creator: this.props.userId
        }
      };
      if (this.state.editTask) {
        graphqlQuery = {
          query: `
            mutation UpdateExistingTask($taskId: ID!, $title: String!, $description: String!, $status: String, $startDate: String, $deadlineDate: String, $craftsmen: [ID!], $projectId: ID, ) {
              updateTask(id: $taskId, taskInput: {title: $title, description: $description, status: $status, startDate: $startDate, deadlineDate: $deadlineDate, aId: $craftsmen, projectId: $projectId,}) {
                _id
                title
                description
                status
                startDate
                deadlineDate
                craftsmen {
                  _id
                  firstname
                  lastname
                  avatarImage
                }
                milestone {
                  _id
                  title
                  status
                  description
                }
              }
            }
          `,
          variables: {
            taskId: this.state.editTask._id,
            title: taskData.title,
            description: taskData.description,
            status: "Ikke påbegyndt",
            craftsmen: craftsmenTaskIds,
            milestoneId: taskData.milestone,
            projectId: this.props.match.params.projectId,
            startDate: taskData.startDate,
            deadlineDate: taskData.deadlineDate
          }
        }
      }
      return fetch(api.baseUrl, {
        method: 'POST',
        body: JSON.stringify(graphqlQuery),
        headers: {
          Authorization: 'Bearer ' + this.props.token,
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          return res.json();
        })
        .then(resData => {
          if (resData.errors && resData.errors[0].status === 422) {
            throw new Error(
              'Validering fejlede. Invalid input'
            );
          }
          if (resData.errors) {
            throw new Error('Oprettelse af task fejlede.');
          }
          let resDataField = 'createTask';
          if (this.state.editTask) {
            resDataField = 'updateTask';
          }
          const task = {
            _id: resData.data[resDataField]._id,
            title: resData.data[resDataField].title,
            description: resData.data[resDataField].description,
            status: resData.data[resDataField].status,
            milestone: resData.data[resDataField].milestone,
            craftsmen: resData.data[resDataField].craftsmen,
            startDate: resData.data[resDataField].startDate,
            deadlineDate: resData.data[resDataField].deadlineDate
          };

          this.setState(prevState => {
            let updatedTasks = [...prevState.tasks];
            let updatedTotalTasks = prevState.totalTasks;
            if (prevState.editTask) {
              const taskIndex = prevState.tasks.findIndex(
                t => t._id === prevState.editTask._id
              );
              updatedTasks[taskIndex] = task;
            } else {
              updatedTotalTasks++;
              if (prevState.tasks.length >= 3) {
                updatedTasks.pop();
              }
              updatedTasks.unshift(task);
            }
            this.fetchInfo();
            return {
              tasks: updatedTasks,
              isTaskEditing: false,
              editTask: null,
              editLoading: false,
              totalTasks: updatedTotalTasks
            };
          }, window.location.reload());
          this.componentDidMount();
        })
        .catch(err => {
          this.setState({
            isTaskEditing: false,
            editTask: null,
            editLoading: false,
            error: err
          });
        });
    } else {
      let craftsmenTaskIds = [];
      for (let i = 0; i < taskData.craftsmen.length; i++) {
        if (taskData.craftsmen[i]._id === undefined) {
          if (taskData.craftsmen[i].value) {
            craftsmenTaskIds.push(taskData.craftsmen[i].value);
          } else {
            craftsmenTaskIds.push(taskData.craftsmen[i])
          }
        } else if (taskData.craftsmen[i]._id !== undefined) {
          craftsmenTaskIds.push(taskData.craftsmen[i]._id);
        } else {
          craftsmenTaskIds.push(taskData.craftsmen[i].i)
        }
      }
      let graphqlQuery = {
        query: `
          mutation CreateNewTask($title: String!, $description: String!, $status: String, $startDate: String, $deadlineDate: String, $craftsmen: [ID!], $progress: Int, $projectId: ID, $creator: ID, $milestoneId: ID) {
            createTask(taskInput: {title: $title, description: $description, status: $status, startDate: $startDate, deadlineDate: $deadlineDate, aId: $craftsmen, progress: $progress, projectId: $projectId, creator: $creator, milestoneId: $milestoneId }) {
              _id
              title
              description
              status
              startDate
              deadlineDate
              milestone {
                _id
              }
              craftsmen {
                _id
              }
            }
          }
        `,
        variables: {
          title: taskData.title,
          description: taskData.description,
          craftsmen: craftsmenTaskIds,
          progress: 1,
          status: "Ikke påbegyndt",
          milestoneId: taskData.milestone,
          projectId: this.props.match.params.projectId,
          startDate: taskData.startDate,
          deadlineDate: taskData.deadlineDate,
          creator: this.props.userId
        }
      };
      if (this.state.editTask) {
        graphqlQuery = {
          query: `
            mutation UpdateExistingTask($taskId: ID!, $title: String!, $description: String!, $startDate: String, $deadlineDate: String, $craftsmen: [ID!], $milestoneId: ID, $projectId: ID, ) {
              updateTask(id: $taskId, taskInput: {title: $title, description: $description, startDate: $startDate, deadlineDate: $deadlineDate, aId: $craftsmen, milestoneId: $milestoneId, projectId: $projectId,}) {
                _id
                title
                description
                status
                startDate
                deadlineDate
                craftsmen {
                  _id
                  firstname
                  lastname
                  avatarImage
                }
                milestone {
                  _id
                  title
                  status
                  description
                  startDate
                  deadlineDate
                }
              }
            }
          `,
          variables: {
            taskId: this.state.editTask._id,
            title: taskData.title,
            description: taskData.description,
            craftsmen: craftsmenTaskIds,
            milestoneId: taskData.milestone,
            projectId: this.props.match.params.projectId,
            startDate: taskData.startDate,
            deadlineDate: taskData.deadlineDate
          }
        }
      }

      return fetch(api.baseUrl, {
        method: 'POST',
        body: JSON.stringify(graphqlQuery),
        headers: {
          Authorization: 'Bearer ' + this.props.token,
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          return res.json();
        })
        .then(resData => {
          if (resData.errors && resData.errors[0].status === 422) {
            throw new Error(
              'Validering fejlede. Invalid input'
            );
          }
          if (resData.errors) {
            throw new Error('Oprettelse af milepæl fejlede.');
          }
          let resDataField = 'createTask';
          if (this.state.editTask) {
            resDataField = 'updateTask';
          }
          const task = {
            _id: resData.data[resDataField]._id,
            title: resData.data[resDataField].title,
            description: resData.data[resDataField].description,
            status: resData.data[resDataField].status,
            milestone: resData.data[resDataField].milestone._id,
            craftsmen: resData.data[resDataField].craftsmen,
            createdAt: resData.data[resDataField].createdAt,
            startDate: resData.data[resDataField].startDate,
            deadlineDate: resData.data[resDataField].deadlineDate
          };
          this.setState(prevState => {
            let updatedTasks = [...prevState.tasks];
            let updatedTotalTasks = prevState.totalTasks;
            if (prevState.editTask) {
              const taskIndex = prevState.tasks.findIndex(
                t => t._id === prevState.editTask._id
              );
              updatedTasks[taskIndex] = task;
            } else {
              updatedTotalTasks++;
              if (prevState.tasks.length >= 3) {
                updatedTasks.pop();
              }
              updatedTasks.unshift(task);
            }
            this.fetchInfo();
            return {
              tasks: updatedTasks,
              isTaskEditing: false,
              editTask: null,
              editLoading: false,
              totalTasks: updatedTotalTasks
            };
          }, window.location.reload());
          this.componentDidMount();
        })
        .catch(err => {
          this.setState({
            isTaskEditing: false,
            editTask: null,
            editLoading: false,
            error: err
          });
        });
    };
  }

  popupDeleteMilestone = _id => {
    confirmAlert({
      title: "Vil du slette denne sag?",
      message: "Hvis du trykker JA mister du al data på sagen!",
      buttons: [
        {
          label: "Ja",
          onClick: () => this.deleteMilestoneHandler(_id)
        },
        {
          label: "Nej",
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  }

  popupDeleteTask = _id => {
    confirmAlert({
      title: "Vil du slette denne opgave?",
      message: "Hvis du trykker JA mister du al data på opgaven!",
      buttons: [
        {
          label: "Ja",
          onClick: () => this.deleteTaskHandler(_id)
        },
        {
          label: "Nej",
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  }

  getClientInfo = () => {
    const ucId = this.state.cId;
    const graphqlQuery = {
      query: `query FetchUserClient($ucId: ID!) {
          user(id: ${ucId}) {
            _id
            firstname
            lastname
            email
            avatarImage
          }
        }
      `,
      variables: {
        ucId: ucId
      }
    };
    fetch(api.baseUrl, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + this.props.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(graphqlQuery)
    })
      .then(res => {
        return res.json();
      })
      .then(resData => {
        if (resData.errors) {
          throw new Error('Failed to fetch project');
        }
        this.setState({
          cfName: resData.data.user.firstname,
          clName: resData.data.user.lastname
        });
      })
      .catch(err => {
        throw new Error(err);
      });
  }

  routeChangeChat = (_id) => {
    const { history } = this.props;
    let path = `/chats/${_id}`;
    history.push(path);
  }

  checkStatus = (status) => {
    if (status === "Ikke påbegyndt") {
      return (
        <p className="badge" style={{ color: "#FFFFFF", backgroundColor: "#F7685B", transform: "none", position: "unset", margin: "0" }}>{status}</p>
      )
    } else if (status === "Sendt til godkendelse") {
      return (
        <p className="badge" style={{ color: "#FFFFFF", backgroundColor: "#4953B0", transform: "none", position: "unset", margin: "0" }}>{status}</p>
      )
    } else if (status === "Igangværende") {
      return (
        <p className="badge" style={{ color: "#FFFFFF", backgroundColor: "#FFD809", transform: "none", position: "unset", margin: "0" }}>{status}</p>
      )
    } else if (status === "Afsluttet") {
      return (
        <p className="badge" style={{ color: "#FFFFFF", backgroundColor: "#29CC97", transform: "none", position: "unset", margin: "0" }}>{status}</p>
      )
    } else if (status === "Forsinket") {
      return (
        <p className="badge" style={{ color: "#FFFFFF", backgroundColor: "#2F2E41", transform: "none", position: "unset", margin: "0" }}>{status}</p>
      )
    } else {
      return
    }
  }

  renderUnassignedTaskData() {
    const newTaskList = [];
    if (this.state.startRendering === true) {
      try {
        if (this.state.allTasks.length === 0) {
          return (
            <div>Dette projekt har ingen opgaver på nuværende tidspunkt.</div>
          )
        }
        for (let l = 0; l < this.state.allTasks.length; l++) {
          newTaskList.push(this.state.allTasks[l])
        }
        if (newTaskList.length > 0) {
          const unassignednewTasksList = [];
          newTaskList.map(task => {
            var unix_timestamp = parseInt(task.startDate)
            var dateObject = new Date(unix_timestamp)
            var humanDateFormat = dateObject
            var unix_timestamp2 = parseInt(task.deadlineDate)
            var dateObject2 = new Date(unix_timestamp2)
            var humanDateFormat2 = dateObject2
            unassignednewTasksList.push(
              <div className="card_task">
                <div>
                  <h2 className="single_project_sub_headline">
                    {task.title}
                  </h2>
                  <div style={{ display: "flex", alignItems: "baseline" }}>
                    <h2 style={{ marginTop: "4px", marginBottom: "1px" }} className="single_project_left_date_text">Start Dato: </h2>
                    <h2 style={{ marginTop: "4px", marginBottom: "1px" }} className="single_project_left_date">{moment(humanDateFormat).format('LL')}</h2>
                  </div>
                  <div style={{ display: "flex", alignItems: "baseline" }}>
                    <h2 style={{ marginTop: "0px", marginBottom: "2px" }} className="single_project_left_date_text">Slut Dato: </h2>
                    <h2 style={{ marginTop: "0px", marginBottom: "2px" }} className="single_project_left_date">{moment(humanDateFormat2).format('LL')}</h2>
                  </div>
                  <div style={{ display: "flex", alignItems: "center", overflow: "hidden", flexDirection: "row" }}>
                    <h2 style={{ marginTop: "0px", marginBottom: "2px" }} className="single_project_left_date_text"> Håndværkere:</h2>
                    {task.craftsmen.map((craftsman) =>
                      <div style={{ display: "flex", alignItems: "center", flexDirection: "row"}}>
                        <div class="single1-image-cropper"><img src={craftsman.avatarImage != null && craftsman.avatarImage != '' ? craftsman.avatarImage : defaultAvatar} alt="avatar" class="single1-image-pic"></img></div>
                        <h2 className="single_project_left_name_text">{craftsman.firstname} {craftsman.lastname}</h2>
                      </div>
                    )}
                  </div>
                </div>
                <div style={{ float: "right", display: "flex", marginRight: "0px", marginLeft: "auto" }}>
                  {this.checkStatus(task.status)}
                </div>
              </div>
            )
          });
          return (
            <div>
              <Card style={{ marginLeft: "-18px", width: "100%", height: "auto", border: "none", backgroundColor: "transparent", boxShadow: "none" }}>
                <CardContent>
                  {unassignednewTasksList}
                </CardContent>
              </Card>
              <br></br>
            </div>
          )
        }
      } catch (err) {
        throw new Error(err)
      }
    }
  }

  renderMilestoneTableData = () => {
    if (this.state.startRendering === true) {
      try {
        if (this.state.milestonesTasks.length === 0) {
          return (
            <div>Dette projekt har ingen milepæle på nuværende tidspunkt.</div>
          )
        }
        return this.state.milestonesTasks.map((milestone, index) => {
          const { _id, title, description, status, startDate, deadlineDate, tasks, craftsmen } = milestone
          if (tasks !== undefined) {
            const newTasksList = [];
            tasks.forEach(t => {
              var unix_timestamp = parseInt(t.startDate);
              var dateObject = new Date(unix_timestamp);
              var humanDateFormat = dateObject;
              var unix_timestamp2 = parseInt(t.deadlineDate);
              var dateObject2 = new Date(unix_timestamp2);
              var humanDateFormat2 = dateObject2;
              newTasksList.push(
                <div >
                  <div key={t._id} className="card_task" style={{ width: "350pt" }}>
                    <h2 className="single_project_sub_headline">
                      {t.title}
                    </h2>
                    <Typography>
                      Dato: {moment(humanDateFormat).format("MM/DD/YYYY") + ` - ` + moment(humanDateFormat2).format("MM/DD/YYYY")}
                    </Typography>
                    <Typography>
                      Håndværkere: {t.craftsmen.map(craftsman => craftsman.firstname + ` ` + craftsman.lastname + ` `)}
                    </Typography>
                    <Typography>
                      Milepæl: {t.milestone.title}
                    </Typography>
                    <IconButton color="primary" onClick={() => this.startEditTaskHandler(t._id)}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton color="secondary" onClick={() => this.popupDeleteTask(t._id)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </div>
                </div>
              )
            });
            return (
              <div key={_id}>
                <Card style={{ marginLeft: "-18px", width: "400pt", height: "auto", border: "none", backgroundColor: "transparent", boxShadow: "none" }}>
                  <CardContent>
                    <div className="card_milestone">
                      <CardHeader
                        title={"Milepæl: " + title}
                      />
                      <Typography >
                        Beskrivelse: {description}
                      </Typography>
                      <Typography>
                        Dato: {moment(startDate).format("MM/DD/YYYY") + ` - ` + moment(deadlineDate).format("MM/DD/YYYY")}
                      </Typography>
                      <Typography>
                        Håndværkere: {craftsmen.map(craftsman => craftsman.firstname + ` ` + craftsman.lastname + ` `)}
                      </Typography>
                      <IconButton color="primary" onClick={() => this.startEditMilestoneHandler(_id)}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton color="secondary" onClick={() => this.popupDeleteMilestone(_id)}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </div>
                    {newTasksList}
                  </CardContent>
                </Card>
                <br></br>
              </div>
            )
          }
        })
      } catch (err) {
        throw new Error(err)
      }
    }
  }

  render() {
    const messageEmpty = ["Ingen nye notifikationer"]
    let imgClient = defaultAvatar;
    if(this.state.client.avatarImage != null && this.state.client.avatarImage != '' ){
      imgClient = this.state.client.avatarImage;
    }

    if (this.state.startRendering === true) {

      const ProjectAssignees =
      //TODO HANDLE massive duplicate assignees
        this.state.assignees.map(assignee =>{
          let imgAssignee = defaultAvatar;
          if(assignee.avatarImage != null && assignee.avatarImage != ''){
            imgAssignee = assignee.avatarImage;
          }
          return (
          <div style={{ display: "flex", alignItems: "center", marginBottom: "20px", marginRight: "40px" }}>
            <div class="single-image-cropper"><img src={imgAssignee} alt="avatar" class="single-image-pic"></img></div>
            <div>
              <h2 style={{ width: "100%" }} className="single_project_text" >{assignee.firstname} {assignee.lastname} </h2>
              <h2 className="single_project_sub_text">{assignee.phone}</h2>
            </div>
          </div>);
        }
        )
      return (
        <div>
          <div className="notification_projects">
            <h2 className="dashboard-welcome">Projekt</h2>
            <div className="dashboard_icons">
              <div
                className="notificationBell"
                onClick={this.handleNotificationsList}>
                {this.state.openList && this.state.listEmpty ?
                  <div></div> :
                  <div className="notificationBell_circle">
                    {this.props.totalNotifications.length}
                  </div>
                }
                <BellLogo
                  width='30'
                  active={this.state.bellActive ? true : false}
                  animate={this.state.bellRing ? true : false}
                  count={9}
                  color="#C5C7CD" />
                {this.state.openList && (
                  <div class="dropdown">
                    <h3 className="dropdown_headline">Dine notifikationer</h3>
                    <ul>
                      {this.props.totalNotifications.length === 0 ? <li>{messageEmpty}</li> : this.props.totalNotifications.map((item, i) => <li key={i}>{item.id.title}<div>{item.id.text}</div></li>)}
                    </ul>
                  </div>
                )}
              </div>
              <NavLink className="chatLogo" to='/customer_service_chats' exact>
                <ChatLogo />
              </NavLink>
              <div className="dashboard_indicator" />
              <div>
                <h2 style={{ paddingRight: "12px" }} class="dashboard-user-name">
                  {this.props.role === 'SuperAdmin' ? localStorage.firstname : localStorage.companyName} {localStorage.lastname !== 'null' && localStorage.lastname != null && localStorage.lastname != '' && this.props.role === 'SuperAdmin' ? localStorage.lastname : ''}
                </h2>
              </div>
              <img class="profile_icon" src="https://cdn1.iconfinder.com/data/icons/avatars-1-5/136/51-512.png" /><img />
            </div>
          </div>
          <body className="single_project_body">
            <div className="single_project_leftcolumn">
              <div className="single_project_card">
                <div className="single_project_left_header_items">
                  <h2 className="single_project_headline">
                    {this.state.ProjectTitle}
                  </h2>
                  <PenLogo
                    width='30'
                    active={this.state.bellActive ? true : false}
                    animate={this.state.bellRing ? true : false}
                    count={9}
                    style={{ marginRight: "43px" }}
                    color="#C5C7CD"
                    cursor='pointer'
                    onClick={()=>{this.setState({editingTodo:true})}} />
                </div>
                <h3 class="single_project_left_sub_headline">Projektbeskrivelse:</h3>
                <h3 class="single_project_text"> {this.state.ProjectDescription}</h3>
                <h3 class="single_project_left_sub_headline">To do:</h3>
                <h3 class="single_project_text"> {this.state.ProjectTODO}</h3>
                <div>
                  {this.state.editingTodo ? <Modal 
                  title={'Ændre To do'}
                  onAcceptModal={(e) => { this.handleSubmitTODO(e); this.setState({editingTodo: false}) }}
                  accept={'Færdig'}
                  cancel={'Annuller'}
                  showCancelButton={true}
                  acceptEnabled={true}
                  onCancelModal={()=>{this.setState({editingTodo:false, ProjectEditingTODO: this.state.ProjectTODO})}}
                  button={'buton'}
                  >
                  <textarea
                      style={{ width: "70%" }}
                      cols="70"
                      type="text"
                      rows="10"
                      onChange={this.handleChangeTODO}
                      value={this.state.ProjectEditingTODO}
                    />
                    </Modal> : null}
                  <h2 style={{ marginBottom: "20px" }} className="single_project_headline">
                    Antal opgaver {this.state.allTasks.length}
                  </h2>
                  <div className="tasks_overview">
                    {this.state.startRendering ? this.renderUnassignedTaskData() : <div>Henter data...</div>}
                  </div>
                </div>
              </div>
            </div>
            <div className="single_project_rightcolumn">
              <div className="single_project_card">
                <h2 className="single_project_headline">Generel info:</h2>
                <h2 style={{ marginBottom: "20px" }} className="single_project_sub_headline">Kunde:</h2>
                <div className="single_project_right_content">
                  <div style={{ marginRight: "24px" }}>
                  <div class="single1client-image-cropper"><img src={imgClient != null && imgClient != '' ? imgClient : defaultAvatar} alt="avatar" class="single1client-image-pic"></img></div>
                  </div>
                  <div>
                    <h2 style={{ width: "100%" }} className="single_project_text">{this.state.client.firstname + ` ` + this.state.client.lastname}</h2>
                    <h2 className="single_project_sub_text">{this.state.client.email}</h2>
                  </div>
                </div>
                <h3 style={{ marginTop: "20px" }} className="single_project_sub_headline">Håndværkere:</h3>
                <p style={{ flexFlow: "row wrap" }}>{ProjectAssignees}</p>
                <h3 style={{ marginBottom: "4px" }} className="single_project_sub_headline">Start dato:</h3>
                <h4 className="single_project_right_date">{moment(this.state.ProjectStartDate).format('DD/MM/YYYY')}</h4>
                <h3 style={{ marginBottom: "4px" }} className="single_project_sub_headline">Slut dato:</h3>
                <h4 className="single_project_right_date">{moment(this.state.ProjectFinishDate).format('DD/MM/YYYY')}</h4>
                <div style={{ marginBottom: "15px" }} className="single_project_chatlogo" onClick={() => this.routeChangeChat(this.state.ProjectId)}>
                  <ChatLogoDark /> <h3 style={{ marginLeft: "18px" }} className="single_project_sub_headline">Gå til projektets chat</h3>
                </div>
                <div className="single_project_chatlogo" onClick={this.newTaskHandler}>
                  <PaperPlusDark /> <h3 style={{ marginLeft: "18px" }} className="single_project_sub_headline">Opret opgaver</h3>
                </div>
                <h3 style={{ marginTop: "20px" }} className="single_project_sub_headline">Filer:</h3>
                {this.state.attachments && this.state.attachments.map((file,idx) =>
                  <div className="polaroid">
                    <a href={`${file}`} target="_blank"> 
                  <img src={`${file}`} alt="5 Terre" className='polaroid_file'></img>
                  </a>
                  <div className="polaroid_container">
                  <p>{`${idx+1}`}</p>
                  </div>
                </div>
                )}
                </div>
            </div>
            <div>
              <section className="milestonefeed__control">
                <MilestoneFeedEdit
                  clients={this.state.totalCustomers}
                  craftsmen={this.state.totalCraftsmen}
                  editing={this.state.isEditing}
                  enableEdit={this.state.enableEdit}
                  selectedMilestone={this.state.editMilestone}
                  newSelectedMilestone={this.state.newSelectedMilestone}
                  loading={this.state.editLoading}
                  onCancelEdit={this.cancelEditHandler}
                  onFinishEdit={this.finishEditHandler}
                  startSelector={this.state.startSelector}
                  newListOfCraftsmen={this.state.newListOfCraftsmen}
                  milestonesTasks={this.state.milestonesTasks}
                  ListOfAllTasks={this.state.ListOfAllTasks}
                />
              </section>
            </div>
            <br></br>
            <br></br>
            <div>
              <section className="taskfeed__control">
                <TaskFeedEdit
                  clients={this.state.totalCustomers}
                  craftsmen={this.state.totalCraftsmen}
                  milestonesTasks={this.state.milestonesTasks}
                  editing={this.state.isTaskEditing}
                  newSelectedTask={this.state.newSelectedTask}
                  selectedTask={this.state.editTask}
                  loading={this.state.editLoading}
                  onCancelEdit={this.cancelTaskEditHandler}
                  onFinishEdit={this.finishTaskEditHandler}
                  startSelector={this.state.startSelector}
                  newListOfCraftsmen={this.state.newListOfCraftsmen}
                  newSelectedMilestone={this.state.newSelectedMilestone}
                  newListOfMilestones={this.state.newListOfMilestones}
                  ListOfAllTasks={this.state.ListOfAllTasks}
                />
              </section>
            </div>
          </body>
        </div>
      );
    } else {
      return (
        <h3>Henter data...</h3>
      )
    }
  }
}



export default SingleProject;
