import React, { Component, Fragment, Form , } from 'react';
import moment from 'moment';
import Backdrop from '../../Backdrop/Backdrop';
import Modal from '../../Modal/Modal';
import Input from '../../Form/Input/Input';
import { required, length, afterStartDate } from '../../../util/validators';
import { generateBase64FromImage } from '../../../util/image';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MILESTONE_FORM = {
  title: {
    value: '',
    valid: false,
    touched: false,
    validators: [required, length({ min: 5 })]
  },
  description: {
    value: '',
    valid: false,
    touched: false,
    validators: [required, length({ min: 5 })]
  },
  craftsmen: {
    value: '',
    valid: true,
    touched: false,
    validators: [required]
  },
  startDate: {
    value: new Date(),
    selected: new Date(),
    valid: false,
    touched: false,
    validators: [required]
  },
  deadlineDate: {
    value: new Date(),
    selected: new Date(),
    valid: false,
    touched: false,
    validators: [required]
  }
};

class MilestoneFeedEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      clients: '',
      _id: '',
      clientChoice: '',
    milestoneForm: MILESTONE_FORM,
    newListOfCraftsmen: this.props.newListOfCraftsmen,
    selectedOption: '',
    formIsValid: false,
    startRender: false,
  };
}

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.editing &&
      prevProps.editing !== this.props.editing &&
      prevProps.selectedMilestone !== this.props.selectedMilestone
    ) {
      let milestoneInfo = '';
      try {
        milestoneInfo = this.props.milestonesTasks.find(milestone => milestone._id === this.props.selectedMilestone._id);
      } catch (err) {
        throw new Error(err)
      }
      let assigneesList = [];
      if (this.props.newSelectedMilestone) {
      this.props.newSelectedMilestone.forEach(({ _id, firstname, lastname }) => assigneesList.push({ _id, firstname, lastname }));
      }
      const milestoneForm = {
        title: {
          ...prevState.milestoneForm.title,
          value: this.props.selectedMilestone.title,
          valid: true
        },
        description: {
          ...prevState.milestoneForm.description,
          value: this.props.selectedMilestone.description,
          valid: true
        },
        craftsmen: {
          ...prevState.milestoneForm.craftsmen,
          value: this.state.selectedOption ? this.state.selectedOption.map(craftsman => craftsman.value) : milestoneInfo.craftsmen.map(craftsman => craftsman._id),
          label: this.state.selectedOption ? this.state.selectedOption : milestoneInfo.craftsmen.map(craftsman => craftsman.firstname + ` ` + craftsman.lastname + ` ` ),
          valid: true
        },
        startDate: {
          ...prevState.milestoneForm.startDate,
          value: moment(milestoneInfo.startDate).format('MM/DD/YYYY'),
          valid: true
        },
        deadlineDate: {
          ...prevState.milestoneForm.deadlineDate,
          value: moment(milestoneInfo.deadlineDate).format('MM/DD/YYYY'),
          valid: true
        },
      };
      this.setState({ milestoneForm: milestoneForm, formIsValid: true });
    }
  }

  milestoneInputChangeHandler = (input, value, files) => {
    if (files) {
      generateBase64FromImage(files[0])
        .then(b64 => {
          this.setState({ imagePreview: b64 });
        })
        .catch(e => {
          this.setState({ imagePreview: null });
        });
    }
    this.setState(prevState => {
      let isValid = true;
      let isStartDateValid = true;
      for (const validator of prevState.milestoneForm[input].validators) {
        isValid = isValid && validator(value);
      }

      if(input == "deadlineDate"){
        isValid = moment(value).isSameOrAfter(this.state.milestoneForm.startDate.value);
        if(!isValid){
          toast.error('Slutdatoen kan ikke være før startdatoen', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        }
        for (const validator of prevState.milestoneForm["startDate"].validators) {
          isStartDateValid = validator(value);
        }
      } else if(input == "startDate"){
        isValid = moment(value).isSameOrBefore(this.state.milestoneForm.deadlineDate.value);
        if(!isValid){
          toast.error('Startdatoen kan ikke være efter slutdatoen', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        }
      }
      
      let updatedForm;
      if(input == "deadlineDate"){
        updatedForm = {
          ...prevState.milestoneForm,
          [input]: {
            ...prevState.milestoneForm[input],
            valid: isValid,
            value: files ? files[0] : value
          },
          "startDate": {
            ...prevState.milestoneForm["startDate"],
            valid: isStartDateValid,
            value: value
          },
        };
      } else {
        updatedForm = {
          ...prevState.milestoneForm,
          [input]: {
            ...prevState.milestoneForm[input],
            valid: isValid,
            value: files ? files[0] : value
          }
        };
      }
      let formIsValid = true;
      for (const inputName in updatedForm) {
        formIsValid = formIsValid && updatedForm[inputName].valid;
      }
      return {
        milestoneForm: updatedForm,
        formIsValid: formIsValid
      };
    });
  };

  inputBlurHandlerSelected = craftsmen => {
    this.setState(prevState => {
      return {
        projectForm: {
          ...prevState.milestoneForm,
          [craftsmen]: {
            ...prevState.milestoneForm[craftsmen],
            touched: true
          }
        }
      };
    });
  };

  inputBlurHandler = input => {
    this.setState(prevState => {
      return {
        milestoneForm: {
          ...prevState.milestoneForm,
          [input]: {
            ...prevState.milestoneForm[input],
            touched: true
          }
        }
      };
    });
  };

  cancelMilestoneChangeHandler = () => {
    this.setState({
        milestoneForm: MILESTONE_FORM,
      formIsValid: false
    });
    this.props.onCancelEdit();
  };

  acceptMilestoneChangeHandler = () => {
    let checkList = this.state.selectedOption ? this.state.selectedOption : this.state.milestoneForm.craftsmen;
    if (this.props.enableEdit === true) {
      if (checkList === '') {
        checkList = this.props.selectedProject.assignees.map(( craftsman, index ) => {
          const { _id } = craftsman
          return {
            _id: _id,
          }
        })
      }
    const milestone = {
      title: this.state.milestoneForm.title.value,
      description: this.state.milestoneForm.description.value,
      craftsmen: checkList ? checkList : this.state.milestoneForm.craftsmen.value.map(( craftsman, index ) => {
        const { value } = craftsman
        return {
          _id: value,
        }
      }),
      startDate: this.state.milestoneForm.startDate.value,
      deadlineDate: this.state.milestoneForm.deadlineDate.value
    };
    this.props.onFinishEdit(milestone);
    this.setState({
        milestoneForm: MILESTONE_FORM,
        selectedOption: '',
        formIsValid: false,
    });
   } else {
    if (this.state.errors === null && checkList !== null && checkList.length !== 0 && this.state.selectedOption.length != 0) {
    const listOfCraftsmen = this.state.selectedOption.map(( craftsman, index ) => {
      const { value } = craftsman
      return {
        _id: value,
      }
    });
    const milestone = {
      title: this.state.milestoneForm.title.value,
      description: this.state.milestoneForm.description.value,
      craftsmen: listOfCraftsmen,
      startDate: this.state.milestoneForm.startDate.value,
      deadlineDate: this.state.milestoneForm.deadlineDate.value
    };
    this.props.onFinishEdit(milestone);
    this.setState({
      milestoneForm: MILESTONE_FORM,
      selectedOption: '',
      formIsValid: false,
      //imagePreview: null
    });
  }  else {
    toast.error("Udfyld venligst alle felterne korrekt.", {
      position: "top-center",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }
  };
}

  handleOptionChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  createSelectors() {
    let newCraftsmenList = [];
    this.props.craftsmen.forEach(({ _id, firstname }) => newCraftsmenList.push({ _id, firstname }));
    if (newCraftsmenList.length > 0) {
      this.setState({
        newestcraftsmenList: newCraftsmenList.map(craftsmen => {
          return {
            value: craftsmen._id,
            label: craftsmen.firstname + ' ' + craftsmen.lastname,
          }
        })
      })
    }
  }


  render() {
    const CraftsmenList = this.props.newListOfCraftsmen;
    return this.props.editing ? (
      <Fragment>
        <ToastContainer />
        <Backdrop onClick={this.cancelMilestoneChangeHandler} />
        <Modal
          title="Milepæl"
          acceptEnabled={this.state.formIsValid}
          onCancelModal={this.cancelMilestoneChangeHandler}
          onAcceptModal={this.acceptMilestoneChangeHandler}
          isLoading={this.props.loading}
          showCancelButton={true}
        >
          <form>
            <Input
              id="title"
              label="Titel"
              control="input"
              onChange={this.milestoneInputChangeHandler}
              onBlur={this.inputBlurHandler.bind(this, 'title')}
              valid={this.state.milestoneForm['title'].valid}
              touched={this.state.milestoneForm['title'].touched}
              value={this.state.milestoneForm['title'].value}
            />
            <Input
              id="description"
              label="Beskrivelse"
              control="textarea"
              rows="5"
              onChange={this.milestoneInputChangeHandler}
              onBlur={this.inputBlurHandler.bind(this, 'description')}
              valid={this.state.milestoneForm['description'].valid}
              touched={this.state.milestoneForm['description'].touched}
              value={this.state.milestoneForm['description'].value}
            />
            <Input
              id="craftsmen"
              label="Vælg en eller flere håndværkere"
              control="selectMultiple"
              onChange={this.handleOptionChange}
              onBlur={this.inputBlurHandler.bind(this, 'craftsmen')}
              valid={this.state.milestoneForm['craftsmen'].valid}
              touched={this.state.milestoneForm['craftsmen'].touched}
              value={this.state.selectedOption}
              placeholder={this.state.milestoneForm['craftsmen'].label}
              options={CraftsmenList}
              isMulti={true}
            />
            <div className="datePickerCustom">
            <Input
              id="startDate"
              label="Vælg start dato"
              control="startDate"
              onChange={this.milestoneInputChangeHandler}
              onBlur={this.inputBlurHandler.bind(this, 'startDate')}
              valid={this.state.milestoneForm['startDate'].valid}
              touched={this.state.milestoneForm['startDate'].touched}
              value={this.state.milestoneForm['startDate'].value}
              selected={this.state.milestoneForm['startDate'].selected}
            />
            </div>
            <div className="datePickerCustom">
            <Input
              id="deadlineDate"
              label="Vælg slut dato"
              control="deadlineDate"
              onChange={this.milestoneInputChangeHandler}
              onBlur={this.inputBlurHandler.bind(this, 'deadlineDate')}
              valid={this.state.milestoneForm['deadlineDate'].valid}
              touched={this.state.milestoneForm['deadlineDate'].touched}
              value={this.state.milestoneForm['deadlineDate'].value}
              selected={this.state.milestoneForm['deadlineDate'].selected}
            />
            </div>
          </form>
        </Modal>
      </Fragment>
    ) : null;
  }
}

export default MilestoneFeedEdit;
