import React from 'react';
import { NavLink } from 'react-router-dom';
import {ReactComponent as Icon } from '../../../assets/logo-small.svg';
import {ReactComponent as IconText } from '../../../assets/dashboard-text.svg';

import NavigationItems from '../NavigationItems/NavigationItems';

import './MainNavigation.css';

const mainNavigation = props => (
  <nav className="main-nav">
    <NavLink to="/dashboard">
     <div className="main-nav__logo">
      <Icon className="small-icon"/>
     </div>
    </NavLink>
    <div className="spacer" />
    <ul className="main-nav__items">
      <NavigationItems isAuth={props.isAuth} onChoose={props.onChooseItem} onLogout={props.onLogout} role={props.role}/>
    </ul>
  </nav>
);

export default mainNavigation;
