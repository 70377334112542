import React, { Component } from 'react';

import 'react-calendar-timeline/lib/Timeline.css';
import moment from 'moment';
import localization from 'moment/locale/da';
import api from '../../util/config';
import './Calender.css'
import { ReactComponent as ChatLogo } from '../../assets/chat_icon.svg';
import { ReactComponent as BellLogo } from '../../assets/bell_icon.svg';
import { NavLink } from 'react-router-dom';

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";


class CalenderPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      projects: '',
      totalProjects: '',
      companyAdminProjects: [],
      scrolling: true,
      bellActive: false,
      bellRing: false,
      notificationList: [],
      openList: false,
      listEmpty: true,
      loading: false,
      unreadNotifications: false,
      finishedLoading: false,
      allMyEvents: [],
    }
  }

  async componentDidMount() {
    moment.updateLocale('da', localization);
    this.getProjects();
  }

  toProject = _id => {
    const { history } = this.props;
    history.push(`/projects/${_id}`)
  }

  getProjects = () => {
    const graphqlQuery = {
      query: `
            query {
                rooms {
                    totalProjectsData {
                    _id
                    title
                    startDate
                    finishDate
                    creator {
                      _id
                    }
                    milestones {
                      _id
                      title
                      startDate
                      deadlineDate
                    }
                    tasks {
                      _id
                      title
                      startDate
                      deadlineDate
                      status
                    }
                }
            }
        }
        `,
    };
    fetch(api.baseUrl, {
      method: 'POST',
      body: JSON.stringify(graphqlQuery),
      headers: {
        Authorization: 'Bearer ' + this.props.token,
        'Content-Type': 'application/json'
      },

    })
      .then(res => {
        return res.json();
      })
      .then(resData => {
        if (resData.errors && resData.errors[0].status === 422) {
          throw new Error(
            'Validering fejlede. Invalid input'
          );
        }
        if (resData.errors) {
          throw new Error('Kunne ikke hente projekter.');
        }
        if (this.props.role === 'Company') {
          this.setState({
            totalProjects: resData.data.rooms.totalProjectsData.map(project => {
              return {
                ...project,
              };
            }),
          })
          for (let i = 0; i <= this.state.totalProjects.length - 1; i++) {
            if (this.state.totalProjects[i].creator._id === this.props.userId) {
              this.state.companyAdminProjects.push(this.state.totalProjects[i])
            }

            const myTasks = [];

            this.state.companyAdminProjects.forEach((project) => {
              let project_id = project._id;
              let projectTitle = project.title;
              let color = '#' + Math.floor(Math.random() * 16777215).toString(16);
              let txtColor = '#FFFFFF';
              const brightness = (color) => {
                var r, g, b, hsp;
                if (color.match(/^rgb/)) {
                  color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

                  r = color[1];
                  g = color[2];
                  b = color[3];
                }
                else {
                  color = +("0x" + color.slice(1).replace(
                    color.length < 5 && /./g, '$&$&'));

                  r = color >> 16;
                  g = color >> 8 & 255;
                  b = color & 255;
                }
                hsp = Math.sqrt(
                  0.299 * (r * r) +
                  0.587 * (g * g) +
                  0.114 * (b * b)
                );
                if (hsp > 127.5) {
                  return 'light';
                }
                else {
                  return 'dark';
                }
              }

              if (project.tasks.length > 0) {
                project.tasks.forEach((task) => {
                  if (task.status === 'Afsluttet') {
                    color = '#29CC97'
                  }
                  if (task.status === 'Ikke påbegyndt' || task.status === 'Er overskredet') {
                    color = '#F7685B'
                  }
                  if (task.status === 'Påbegyndt' || task.status === 'Igangværende') {
                    color = '#FFB946'
                  }
                  if (task.status === 'Sendt til godkendelse') {
                    color = '#4953B0'
                  }
                  if (brightness(color) === 'light') {
                    txtColor = '#000000'
                  }
                  let SD = parseInt(task.startDate)
                  let DD = parseInt(task.deadlineDate)
                  let tasko = {
                    groupId: project_id,
                    title: `${projectTitle} - ${task.title}`,
                    start: moment(SD).format("YYYY[-]MM[-]DD"),
                    end: moment(DD).format("YYYY[-]MM[-]DD"),
                    backgroundColor: color,
                    textColor: txtColor,
                  }
                  myTasks.push(tasko);
                });
              }
            });

            this.setState({ allMyEvents: myTasks });

          }
        } else if (this.props.role === 'SuperAdmin') {

        }
      })
      .catch(this.catchError);
  };


  handleNotificationsList = () => {
    this.setState(state => {
      return {
        bellRing: false,
        bellActive: false,
        openList: !state.openList,
        unreadNotifications: false,
      };
    });
  };


  render() {
    const messageEmpty = ["Ingen nye notifikationer"]
    const myEvents = this.state.allMyEvents;

    return (
      <div style={{ backgroundColor: "#F1F6F9" }}>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.17.1/moment.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.17.1/locale/da.js"></script>
        <div className="notification_projects">
          <h2 className="dashboard-welcome">Kalender</h2>
          <div className="dashboard_icons">
            <div
              className="notificationBell"
              onClick={this.handleNotificationsList}>
              {this.state.openList && this.state.listEmpty ?
                <div></div> :
                <div className="notificationBell_circle">
                  {this.props.totalNotifications.length}
                </div>
              }
              <BellLogo
                width='30'
                active={this.state.bellActive ? true : false}
                animate={this.state.bellRing ? true : false}
                count={9}
                color="#C5C7CD" />
              {this.state.openList && (
                <div class="dropdown">
                  <h3 className="dropdown_headline">Dine notifikationer</h3>
                  <ul>
                    {this.props.totalNotifications.length === 0 ? <li>{messageEmpty}</li> : this.props.totalNotifications.map((item, i) => <li key={i}>{item.id.title}<div>{item.id.text}</div></li>)}
                  </ul>
                </div>
              )}
            </div>
            <NavLink className="chatLogo" to='/customer_service_chats' exact>
              <ChatLogo />
            </NavLink>
            <div className="dashboard_indicator" />
            <div>
              <h2 style={{ paddingRight: "12px" }} class="dashboard-user-name">
                {this.props.role === 'SuperAdmin' ? localStorage.firstname : localStorage.companyName}
              </h2>
            </div>
            <img class="profile_icon" src="https://cdn1.iconfinder.com/data/icons/avatars-1-5/136/51-512.png" />{''}<img />
          </div>
        </div>
        <div className="App">
          <FullCalendar
            defaultView="dayGridMonth"
            plugins={[dayGridPlugin]}
            eventClick={(arg) => { this.toProject(arg.event.groupId) }}
            events={myEvents}
            firstDay={1}
            progressiveEventRendering={true}
            locale='da'
            headerToolbar={{
              left: 'today prev next',
              center: 'title',
              //TODO outcomment when implementing day
              // right: 'dayGridMonth dayGridWeek dayGridDay',
              right: 'dayGridMonth dayGridWeek',
            }}
            buttonText={{
              today: 'I dag',
              month: 'Måned',
              week: 'Uge',
              day: 'Dag',
            }}
          />
        </div>
      </div>)
  }
}
export default CalenderPage;
