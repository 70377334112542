import React, { Component } from 'react';
import './QuickReply.css'

class QuickReply extends Component {
    state = {
        text1: 'Jeg ringer senere',
        text2: 'Ja',
        text3: 'Nej',
        text: '',
    }

    onMessageSend = () => {
        if (this.state.text === '') {
            return
        }
        if (this.state.text === this.state.text1) {
        const message = {
            created: Date.now(),
            text: this.state.text,
            author: this.props.userId,
            roomId: this.props.roomId,
            email: this.props.email,
            userFirstname: localStorage.firstname,
            userLastname: localStorage.lastname,
            role: localStorage.role
        } 
        this.props.sendMessage(message);
    } else if (this.state.text === this.state.text2) {
        const message = {
            created: Date.now(),
            text: this.state.text,
            author: this.props.userId,
            roomId: this.props.roomId,
            email: this.props.email,
            userFirstname: localStorage.firstname,
            userLastname: localStorage.lastname,
            role: localStorage.role
        } 
        this.props.sendMessage(message);
    } else if (this.state.text === this.state.text) {
        const message = {
            created: Date.now(),
            text: this.state.text3,
            author: this.props.userId,
            roomId: this.props.roomId,
            email: this.props.email,
            userFirstname: localStorage.firstname,
            userLastname: localStorage.lastname,
            role: localStorage.role
        } 
        this.props.sendMessage(message);
    }
    }

    render() {
        return (
            <form className="quick-reply-form">
                    <div className="control">
                        <input type="button"
                        className="send-quickmessage"
                        value={this.state.text1}
                        onClick={(e) => this.setState({text: e.target.value}, () => {
                            this.onMessageSend()
                        })
                        }>
                        </input>
                    </div>
                    <div className="control">
                        <input type="button"
                        float='left'
                        className="send-quickmessage"
                        value={this.state.text2}
                        onClick={(e) => this.setState({text: e.target.value}, () => {
                            this.onMessageSend()
                        })
                        }>
                        </input>
                    </div>
                    <div className="control">
                        <input type="button"
                        className="send-quickmessage"
                        value={this.state.text3}
                        onClick={(e) => this.setState({text: e.target.value}, () => {
                            this.onMessageSend()
                        })
                        }>
                        </input>
                    </div>
            </form>
        )
    }
}
export default QuickReply;
