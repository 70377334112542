import React, { Component, Fragment, Form, } from 'react';

import Backdrop from '../Backdrop/Backdrop';
import Modal from '../Modal/Modal';
import Input from '../Form/Input/Input';
import { required, email } from '../../util/validators';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const signupForm = {
    email: {
        value: '',
        valid: false,
        touched: false,
        validators: [required, email]
    },
    role: {
        value: 'Company',
        valid: true,
        validators: [required]
    },
    firstname: {
        value: '',
        valid: false,
        touched: false,
        validators: [required]
    },
    companyName: {
        value: '',
        valid: false,
        touched: false,
        validators: [required]
    },
    phone: {
        value: '',
        valid: false,
        touched: false,
        validators: [required]
    },
    cvr: {
        value: '',
        valid: false,
        touched: false,
        validators: [required]
    },
    companyUrl: {
        value: '',
        valid: false,
        touched: false,
        validators: [required]
    },
    address: {
        value: '',
        valid: false,
        touched: false,
        validators: [required]
    },
}


class CompanyEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: null,
            _id: '',
            clientChoice: '',
            signupForm: signupForm,
            formIsValid: false,
        };
    }


    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.editing &&
            prevProps.editing !== this.props.editing &&
            prevProps.selectedUser !== this.props.selectedUser
        ) {
            const signupForm = {
                email: {
                    ...prevState.signupForm.email,
                    value: this.props.selectedUser.email,
                    valid: true
                },
                role: {
                    ...prevState.signupForm.role,
                    value : 'Company',
                    valid: true
                },
                firstname: {
                    ...prevState.signupForm.firstname,
                    value: this.props.selectedUser.firstname,
                    valid: true
                },
                companyName: {
                    ...prevState.signupForm.companyName,
                    value: this.props.selectedUser.companyName,
                    valid: true
                },
                phone: {
                    ...prevState.signupForm.phone,
                    value: this.props.selectedUser.phone,
                    valid: true
                },
                cvr: {
                    ...prevState.signupForm.cvr,
                    value: this.props.selectedUser.cvr,
                    valid: true
                },
                companyUrl: {
                    ...prevState.signupForm.companyUrl,
                    value: this.props.selectedUser.companyUrl,
                    valid: true
                },
                address: {
                    ...prevState.signupForm.address,
                    value: this.props.selectedUser.address,
                    valid: true
                },

            };
            this.setState({ signupForm: signupForm, formIsValid: true });
        }
    }

    inputChangeHandler = (input, value) => {
        this.setState(prevState => {
            let isValid = true;
            for (const validator of prevState.signupForm[input].validators) {
                isValid = isValid && validator(value);
            }
            const updatedForm = {
                ...prevState.signupForm,
                [input]: {
                    ...prevState.signupForm[input],
                    valid: isValid,
                    value: value
                }
            };
            let formIsValid = true;
            for (const inputName in updatedForm) {
                formIsValid = formIsValid && updatedForm[inputName].valid;
            }
            return {
                signupForm: updatedForm,
                formIsValid: formIsValid
            };
        });
    };

    inputBlurHandler = input => {
        this.setState(prevState => {
            return {
                signupForm: {
                    ...prevState.signupForm,
                    [input]: {
                        ...prevState.signupForm[input],
                        touched: true
                    }
                }
            };
        });
    };

    cancelUserChangeHandler = () => {
        this.setState({
            signupForm: signupForm,
            formIsValid: false
        });
        this.props.onCancelEdit();
    };

    acceptUserChangeHandler = () => {
        if (this.state.errors === null) {
            const user = {
                email: this.state.signupForm.email.value,
                role: this.state.signupForm.role.value,
                firstname: this.state.signupForm.firstname.value,
                companyName: this.state.signupForm.companyName.value,
                phone: this.state.signupForm.phone.value,
                cvr: this.state.signupForm.cvr.value,
                companyUrl: this.state.signupForm.companyUrl.value,
                address: this.state.signupForm.address.value,
            };
            this.props.onFinishEdit(user);
            this.setState({
                signupForm: signupForm,
                formIsValid: false,
            });
        } else {
            alert("udfyld venligst alle felterne korrekt.")
        }
    };

    render() {
        return this.props.editing ? (
            <Fragment>
                <Backdrop onClick={this.cancelUserChangeHandler} />
                <Modal
                    title="Virksomhed"
                    acceptEnabled={this.state.formIsValid}
                    onCancelModal={this.cancelUserChangeHandler}
                    onAcceptModal={this.acceptUserChangeHandler}
                    isLoading={this.props.loading}
                    showCancelButton={true}
                >
                    <form >
                        <Input
                            id="email"
                            label="Virksomhedens email"
                            placeholder="james@example.com"
                            type="email"
                            control="input"
                            onChange={this.inputChangeHandler}
                            onBlur={this.inputBlurHandler.bind(this, 'email')}
                            value={this.state.signupForm['email'].value}
                            valid={this.state.signupForm['email'].valid}
                            touched={this.state.signupForm['email'].touched}
                        />
                        <Input
                            id="firstname"
                            label="Kontaktpersons fulde navn"
                            placeholder="Per"
                            type="firstname"
                            control="input"
                            onChange={this.inputChangeHandler}
                            onBlur={this.inputBlurHandler.bind(this, 'firstname')}
                            value={this.state.signupForm['firstname'].value}
                            valid={this.state.signupForm['firstname'].valid}
                            touched={this.state.signupForm['firstname'].touched}
                        />
                        <Input
                            id="companyName"
                            label="Firma navn"
                            placeholder="ex. Sales A/S"
                            type="companyName"
                            control="input"
                            onChange={this.inputChangeHandler}
                            onBlur={this.inputBlurHandler.bind(this, 'companyName')}
                            value={this.state.signupForm['companyName'].value}
                            valid={this.state.signupForm['companyName'].valid}
                            touched={this.state.signupForm['companyName'].touched}
                        />
                        <Input
                            id="phone"
                            label="Telefonnummer"
                            placeholder="ex. 11223344"
                            type="phone"
                            control="input"
                            onChange={this.inputChangeHandler}
                            onBlur={this.inputBlurHandler.bind(this, 'phone')}
                            value={this.state.signupForm['phone'].value}
                            valid={this.state.signupForm['phone'].valid}
                            touched={this.state.signupForm['phone'].touched}
                        />
                        <Input
                            id="address"
                            label="Virksomhedens adresse"
                            placeholder="ex. Rolighedsvej 123a"
                            control="textarea"
                            rows="3"
                            onChange={this.inputChangeHandler}
                            onBlur={this.inputBlurHandler.bind(this, 'address')}
                            value={this.state.signupForm['address'].value}
                            valid={this.state.signupForm['address'].valid}
                            touched={this.state.signupForm['address'].touched}
                        />
                        <Input
                            id="cvr"
                            label="Virksomheds cvr"
                            placeholder="ex 87654321"
                            type="cvr"
                            control="input"
                            onChange={this.inputChangeHandler}
                            onBlur={this.inputBlurHandler.bind(this, 'cvr')}
                            value={this.state.signupForm['cvr'].value}
                            valid={this.state.signupForm['cvr'].valid}
                            touched={this.state.signupForm['cvr'].touched}
                        />
                        <Input
                            id="companyUrl"
                            label="Virksomhedens URL"
                            placeholder="ex. www.virksomheds-url.dk"
                            type="companyUrl"
                            control="input"
                            onChange={this.inputChangeHandler}
                            onBlur={this.inputBlurHandler.bind(this, 'companyUrl')}
                            value={this.state.signupForm['companyUrl'].value}
                            valid={this.state.signupForm['companyUrl'].valid}
                            touched={this.state.signupForm['companyUrl'].touched}
                        />
                        <Input
                            id="role"
                            type="hidden"
                            onBlur={this.inputBlurHandler.bind(this, 'role')}
                            value={this.state.signupForm['role'].value}
                            valid={this.state.signupForm['role'].valid}
                            touched={this.state.signupForm['role'].touched}
                        />
                    </form>
                </Modal>
            </Fragment>
        ) : null;
    }
}

export default CompanyEdit;
