import React, { Component, Fragment } from 'react';
import './Dashboard.css';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import api from '../../util/config';
import Card from '../../components/Card/Card';
import 'react-notifications-component/dist/theme.css';
import 'react-dropdown/style.css';
import {ReactComponent as ChatLogo } from '../../assets/chat_icon.svg';
import {ReactComponent as BellLogo } from '../../assets/bell_icon.svg';
import { NavLink } from 'react-router-dom';
import Table from '../../components/Table/Table';
import moment from 'moment';
import MessageIcon from '@material-ui/icons/Message';
import { confirmAlert } from 'react-confirm-alert';
import defaultAvatar from '../../assets/default.png'
import { ReactComponent as IconChat } from "../../assets/chat_icon.svg";
import { ReactComponent as IconEdit } from "../../assets/edit.svg";
import { ReactComponent as IconDelete } from "../../assets/delete.svg";
import IconButton from '@material-ui/core/IconButton';
import ErrorHandler from '../../components/ErrorHandler/ErrorHandler';
import { roomRef } from '../../util/fire';
import ProjectFeedEdit from '../../components/ProjectFeed/ProjectFeedEdit/ProjectFeedEdit';
import Circle from 'react-circle';
import { Doughnut} from 'react-chartjs-2';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uId: this.props.uId,
            firstname: this.props.userFirstName,
            lastname: this.props.userLastName,
            email: this.props.userEmail,
            allProjects: [],
            allCustomers: [],
            allCraftsmen: [],
            allCompanies: [],
            companyAdminProjects: [],
            projects: '',
            users: [],
            totalProjects: '',
            projects: [],
            projectPage: 1,
            projectsLoading: true,
            totalProjects: '',
            bellActive: false,
            bellRing: false,
            notificationList: [],
            openList: false,
            listEmpty: true,
            loading: false,
            unreadNotifications: false,
            finishedLoading: false,
            isEditing: false,
            enableEdit: false,
            editLoading: false,
            editProject: null,
            startSelector: false,
            newListOfCraftsmen: '',
            newListOfCustomers: '',
            datePicker: null,
            todaydate: null,
        };
    }
    
    handleNotificationsList = () => {
      this.setState(state => {
        return {
          bellRing: false,
          bellActive: false,
          openList: !state.openList,
          unreadNotifications: false,
        };
      });
    };

    useStyles = makeStyles((theme) => ({
        root: {
          '& > *': {
            margin: theme.spacing(1),
          },
        },
        extendedIcon: {
          marginRight: theme.spacing(1),
        },
      }));

      getProjects = () => {
          const graphqlQuery = {
            query: `
            query
            {
                projects {
                }
            } 
            
            `,
          };
          fetch(api.baseUrl, {
            method: 'POST',
            body: JSON.stringify(graphqlQuery),
            headers: {
              Authorization: 'Bearer ' + this.props.token,
              'Content-Type': 'application/json'
            },
            
          })
            .then(res => {
              return res.json();
            })
            .then(resData => {
              if (resData.errors && resData.errors[0].status === 422) {
                throw new Error(
                  'Validering fejlede. Invalid input'
                );
              }
              if (resData.errors) {
                throw new Error('Kunne ikke hente projekter.');
              }
              this.setState({
                projects: resData.data.projects.projects.map(project =>{
                  return {
                    ...project,
                  };
                }),
                allProjects: resData.data.projects.allProjects,
              });
            })
            .catch(this.catchError);
        };

      getUsers = () => {
            const graphqlQuery = {
              query: `
              {
                users {
                  users {
                    _id
                    firstname
                    lastname
                    role
                    email
                    status
                    company
                  }
                  
                }
              }
              `
            }
            fetch(api.baseUrl, {
              method: 'POST',
              body: JSON.stringify(graphqlQuery),
              headers: {
                Authorization: 'Bearer ' + this.props.token,
                'Content-Type': 'application/json'
              },
            })
            .then(res =>{
              return res.json();
            })
            .then(resData => {
              if (resData.errors && resData.errors[0].status === 422) {
                throw new Error(
                  'Validering fejlede. Invalid input'
                );
              }
              if (resData.errors) {
                throw new Error('Kunne ikke hente brugere.');
              }
              this.setState({ users: resData.data.users.users})
              for (let i = 0; i <= this.state.users.length-1; i++) {
                if (this.state.users[i].role === 'Client') {
                  if(this.props.role !== "SuperAdmin"){
                    if(this.state.users[i].company.includes(this.props.userId)){
                      this.state.allCustomers.push(this.state.users[i]);
                    }
                  } else {
                    this.state.allCustomers.push(this.state.users[i]);
                  }
                } else if (this.state.users[i].role === 'Craftsman') {
                  if(this.props.role !== "SuperAdmin"){
                    if(this.state.users[i].company.includes(this.props.userId)){
                      this.state.allCraftsmen.push(this.state.users[i]);
                    }
                  } else {
                    this.state.allCraftsmen.push(this.state.users[i]);
                  }
                } else if (this.state.users[i].role === 'Company') {
                  this.state.allCompanies.push(this.state.users[i]);
                }
              }
            this.setState({ 
                allCustomers: this.state.allCustomers,
                allCraftsmen: this.state.allCraftsmen,
                allCompanies: this.state.allCompanies
            });
          }).catch(this.catchError);
        };

    componentDidMount= () => {
      if (!this.state.isLogged) {
        this.setState({
          isLogged: true
        })
      }
        const { history } = this.props;
        this.loadProjects();
        this.getUsers();
        const token = localStorage.getItem('token');
        const expiryDate = localStorage.getItem('expiryDate');
        const fName = localStorage.getItem('firstname');
        const lName = localStorage.getItem('lastname');
        const mail = localStorage.getItem('email');
        const today = moment(moment()).format('YYYY-MM-DD');
        if (!token || !expiryDate) {
        return;
        }
        this.setState({ firstname: fName, lastname: lName, email: mail, todaydate: today})

}
    
    loadProjects = direction => {
      if (direction) {
        this.setState({ projectsLoading: true, projects: [] });
      }
      let page = this.state.projectPage;
      if (direction === 'next') {
        page++;
        this.setState({ projectPage: page });
      }
      if (direction === 'previous') {
        page--;
        this.setState({ projectPage: page });
      }
      const graphqlQuery = {
        query: `
        query FetchProjects($page: Int) {
          projects(page: $page) {
            projects {
              _id
              title
              description
              createdAt
              assignees {
                _id
                firstname
                lastname
              }
              startDate
              status
              finishDate
              creator {
                _id
                firstname
              }
              clients {
                _id
                firstname
                lastname
                avatarImage
              }
              tasks {
                status
              }
            }
            totalProjects
          }
        }
        `,
        variables: {
          page: page
        }
      };
      fetch(api.baseUrl, {
        method: 'POST',
        body: JSON.stringify(graphqlQuery),
        headers: {
          Authorization: 'Bearer ' + this.props.token,
          'Content-Type': 'application/json'
        },
        
      })
        .then(res => {
          return res.json();
        })
        .then(resData => {
          if (resData.errors && resData.errors[0].status === 422) {
            throw new Error(
              'Validering fejlede. Invalid input'
            );
          }
          if (resData.errors) {
            throw new Error('Kunne ikke hente projekter.');
          }
          if (this.props.role === 'Company') {
            this.setState({
              companyAdminProjects: resData.data.projects.projects.map(project => {
                return {
                  ...project,
                };
              })
            })
              for (let i = 0; i <= this.state.companyAdminProjects.length-1; i++) {
                if(this.state.companyAdminProjects[i].creator !== null && this.state.companyAdminProjects[i].creator !== undefined){
                  if (this.state.companyAdminProjects[i].creator._id === this.props.userId) {
                    this.state.projects.push(this.state.totalProjects[i]);
                  }
                }
              }
              this.setState({
                totalProjects: this.state.projects.length
              })
          } else {
          this.setState({
            projects: resData.data.projects.projects.map(project =>{
              return {
                ...project,
              };
            }),
            totalProjects: resData.data.projects.totalProjects,
            projectsLoading: false
          }); 
        }
      })
      .catch(this.catchError);
    };
    routeChangeClients = () => {
      const { history } = this.props;
      history.push(`/clients`)
    }
    routeChangeCraftsmen = () => {
      const { history } = this.props;
      history.push(`/craftsmen`)
    }
    routeChangeProjects = () => {
      const { history } = this.props;
      history.push(`/projects`)
    }
    routeChangeChat = (_id) => {
      const { history } = this.props;
      let path = `/chats/${_id}`;
      history.push(path);
    }
    routeChangeProject = _id => {
      const { history } = this.props;
          history.push(`/projects/${_id}`)
    }

    popupDelete = _id => {
      confirmAlert({
        title: "Slet projekt",
        message: "Er du sikker?",
        buttons: [
          {
            label: "Ja",
            onClick: () => this.deleteProjectHandler(_id)
          },
          {
            label: "Nej",
          }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
      });
    }

    deleteProjectHandler = projectId => {
      this.setState({ projectsLoading: true });
      const graphqlQuery = {
        query: `
          mutation {
            deleteProject(id: "${projectId}")
          }
        `
      };
      fetch(api.baseUrl, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + this.props.token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(graphqlQuery)
      })
        .then(res => {
          return res.json();
        })
        .then(resData => {
          if (resData.errors) {
            throw new Error('Deleting the post failed!');
          }
          this.loadProjects();
        })
        .catch(err => {
          this.setState({ projectsLoading: false });
        });
    };

    startEditProjectHandler = projectId => {
      this.setState(prevState => {
        const loadedProject = { ...prevState.companyAdminProjects.find(p => p._id === projectId) };
        return {
          isEditing: true,
          enableEdit: true,
          editProject: loadedProject
        };
      });
    };

    cancelEditHandler = () => {
      this.setState({ isEditing: false, editProject: null });
    };

    errorHandler = () => {
      this.setState({ error: null });
    };

    finishEditHandler = projectData => {
      this.setState({
        editLoading: true
      });
      let craftsmenIds = [];
      for (let i=0; i < projectData.craftsmen.length; i++) {
        if (projectData.craftsmen[i]._id === undefined) {
          craftsmenIds.push(projectData.craftsmen[i].value);
        } else {
        craftsmenIds.push(projectData.craftsmen[i]._id);
        }
      }
      let graphqlQuery = {
        query: `
          mutation CreateNewProject($title: String!, $description: String!, $status: String, $startDate: String, $finishDate: String, $craftsmen: [ID!], $clients: ID!, ) {
            createProject(projectInput: {title: $title, description: $description, status: $status, startDate: $startDate, finishDate: $finishDate, aId: $craftsmen, cId: $clients}) {
              _id
              title
              description
              status
              startDate
              finishDate
              clients {
                _id
                firstname
                lastname
              }
              assignees {
                _id
                firstname
                lastname
              }
            }
          }
        `,
        variables: {
          title: projectData.title,
          description: projectData.description,
          clients: projectData.clients,
          craftsmen: craftsmenIds,
          status: `Ikke påbegyndt`,
          startDate: projectData.startDate,
          finishDate: projectData.finishDate
        } 
        
      };
      if (this.state.editProject) {
        graphqlQuery = {
          query: `
            mutation UpdateExistingProject($projectId: ID!, $title: String!, $description: String!, $status: String, $startDate: String, $finishDate: String, $craftsmen: [ID!], $clients: ID!) {
              updateProject(id: $projectId, projectInput: {title: $title, description: $description, status: $status, startDate: $startDate, finishDate: $finishDate, aId: $craftsmen, cId: $clients}) {
                _id
                title
                description
                status
                startDate
                finishDate
                assignees {
                  _id
                  firstname
                  lastname
                }
                clients {
                  _id
                  firstname
                  lastname
                }
                createdAt
                updatedAt
              }
            }
          `,
          variables: {
            projectId: this.state.editProject._id,
            title: projectData.title,
            description: projectData.description,
            status: "Ikke påbegyndt",
            clients: projectData.clients,
            craftsmen: craftsmenIds,
            startDate: projectData.startDate,
            finishDate: projectData.finishDate
          }
        }
      }
      fetch(api.baseUrl, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + this.props.token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(graphqlQuery)
      })
        .then(res => {
          return res.json();
        })
        .then(resData => {
          if (resData.errors && resData.errors[0].status === 422) {
            throw new Error(
              'Validering fejlede. Invalid input'
            );
          }
          if (resData.errors) {
            throw new Error('Oprettelse af nyt projekt fejlede.');
          }
          let resDataField = 'createProject';
          if (this.state.editProject) {
            resDataField = 'updateProject';
          }
  
          const project = {
            _id: resData.data[resDataField]._id,
            title: resData.data[resDataField].title,
            description: resData.data[resDataField].description,
            status: resData.data[resDataField].status,
            clients: resData.data[resDataField].clients,
            assignees: resData.data[resDataField].assignees,
            createdAt: resData.data[resDataField].createdAt,
            startDate: resData.data[resDataField].startDate,
            finishDate: resData.data[resDataField].finishDate
          };
          if (resDataField === 'createProject') {
          // Create chat room to firebase db based on project._id
          const room = {
            author: this.props.userId,
            name: project._id,
            created: Date.now()
          }
          roomRef.push(room.name);
          }
          this.setState(prevState => {
            let updatedProjects = [...prevState.projects];
            let updatedTotalProjects = prevState.totalProjects;
            if (prevState.editProject) {
              const projectIndex = prevState.projects.findIndex(
                p => p._id === prevState.editProject._id
              );
              updatedProjects[projectIndex] = project;
            } else {
              updatedTotalProjects++;
              if (prevState.projects.length >= 3) {
                updatedProjects.pop();
              }
              updatedProjects.unshift(project);
            }
            return {
              projects: updatedProjects,
              isEditing: false,
              editProject: null,
              editLoading: false,
              totalProjects: updatedTotalProjects
            };
          });
          this.loadProjects();
        })
        .catch(err => {
          this.setState({
            isEditing: false,
            editProject: null,
            editLoading: false,
            error: err
          });
        });
        
    };

    newProjectHandler = () => {
      this.setState({ isEditing: true, });
    };

    newDatePicker = (numDate, date ) => {
      this.setState({datePicker: numDate, todaydate: date })
    }

    renderProjectCards = (routeChat, startEdit, startDelete, routeProject) => {
      const projects = this.state.companyAdminProjects.filter( project => project.creator != null &&
         project.creator != undefined && project.creator._id === this.props.userId &&
          moment(moment(project.finishDate).format('YYYY-MM-DD')) - moment(this.state.todaydate) >= 0);

      return projects.map(function(project, i){
        const { _id, title, clients, status, startDate, finishDate } = project;
        const cfirstname = clients.firstname ? clients.firstname : "Ingen kunder tilknyttet";
        const clastname = clients.lastname ? clients.lastname : "";
        const cfullname = cfirstname + ` ` + clastname;
        let avatarImage = defaultAvatar;

        let className = 'company-projectcard-status-badge-pending';
        if(project.status === 'Afsluttet'){
          className = 'company-projectcard-status-badge-done';
        }
        if(project.status == 'Ikke påbegyndt'){
          className = 'company-projectcard-status-badge-notstarted';
        }

        if(project.clients.avatarImage != undefined && project.clients.avatarImage != null && project.clients.avatarImage != ''){
          avatarImage = project.clients.avatarImage;
        }

        return <div key={project._id} class='company-container-projects'>
        <div class='company-projectcard-container'>
          <div class='company-projectcard-info'>
            <div class='company-projectcard-info-title' onClick={()=>{routeProject(_id)}}><p>{title}</p></div>
            <div class='company-projectcard-info-date' onClick={()=>{routeProject(_id)}}><p class='start-info-date-title'>Start dato:</p><p class='start-info-date-date'>{moment(startDate).format('DD-MM-YYYY')}</p></div>
            <div class='company-projectcard-info-date' onClick={()=>{routeProject(_id)}}><p class='start-info-date-title'>Slut dato:</p><p class='start-info-date-date'>{moment(finishDate).format('DD-MM-YYYY')}</p></div>
            <div class='company-projectcard-info-customer' onClick={()=>{routeProject(_id)}}>
              <p class='company-projectcard-info-customer-title'>Kunde:</p>
              <div class='company-projectcard-info-customer-avatar image-cropper-dashboard'><img src={avatarImage} alt="avatar" class="profile-pic-dashboard "/></div>
              <p class='company-projectcard-info-customer-name'>{cfullname}</p>
            </div>
          </div>
          <div class='company-projectcard-status'>
            <div class='company-projectcard-status-buttons'>
              <div class='company-projectcard-status-button'><IconButton className={`icon-edit`} aria-label="edit" onClick={()=>{startDelete(_id)}}><IconDelete/> </IconButton></div>
              <div class='company-projectcard-status-button'><IconButton className={`icon-edit`} aria-label="edit" onClick={()=>{startEdit(_id)}}><IconEdit/> </IconButton></div>
              <div class='company-projectcard-status-button'><IconButton className={`icon-edit`} aria-label="edit" onClick={()=>{routeChat(_id)}}><IconChat/> </IconButton></div>
            </div>
            <div class={className} onClick={()=>{routeProject(_id)}}><p class='company-status-badge-title'>{status}</p></div>
          </div>
        </div>
      </div>
        })
    }

    render() {
      const messageEmpty = ["Ingen nye notifikationer"]
      const numClients = this.state.allCustomers.length
      const numCraftsmen = this.state.allCraftsmen.length
      const numCompanies = this.state.allCompanies.length
      const numProjects = this.state.totalProjects
      const today = moment();
      let currentWeek;
      let completedTask = 0;
      let pendingTask = 0;
      let notStartedTask = 0;
      let totalTasks = 0;
      let completedPercentage = 0;
      let pendingPercentage = 0;
      let notstartedPercentage= 0;

      this.state.companyAdminProjects.forEach(project => {
        
        // TODO Need to handle all status if Desktop graph needs to show more
        if(project.tasks.length != 0){
          project.tasks.forEach((task) => {
            if(task != null){
              if(task.status != null && task.status != undefined){
                if(task.status === 'Afsluttet'){
                  completedTask = completedTask + 1
                }
                if(task.status === 'Sendt til godkendelse'){
                  pendingTask = pendingTask + 1
                }
                if(task.status === 'Igangværende'){
                  pendingTask = pendingTask + 1
                }
                if(task.status === 'Ikke påbegyndt'){
                  notStartedTask = notStartedTask + 1
                }              
              }
            }
          })
        } 
      });



      totalTasks = completedTask + pendingTask + notStartedTask;
      if(completedTask > 0){
        completedPercentage = (completedTask/ totalTasks)*100;
      }else{
        completedPercentage = 0;
      }
      if(pendingTask > 0){
        pendingPercentage = (pendingTask/ totalTasks)*100;
      }else{
        pendingPercentage = 0;
      }
      
      if(notStartedTask > 0){
        notstartedPercentage = (notStartedTask/ totalTasks)*100;       
      }else{
        notstartedPercentage = 0;
      }

      const state = {
        labels: ['Afsluttet', 'Igangværende', 'Ikke påbegyndt'],
        datasets: [
          {
            label: 'Opgaver',
            backgroundColor: [
              '#2ED47A',
              '#FFB946',
              '#F7685B',
            ],
            hoverBackgroundColor: [
            '#2a8755',
            '#4B5000',
            '#501800',
            ],
            data: [Math.round(completedPercentage), Math.round(pendingPercentage), Math.round(notstartedPercentage)]
          }
        ]
      }
      
      if(today.day() === 1){
        currentWeek = {
          monday: moment(),
          tuesday: moment().add(1, 'days'),
          wednesday: moment().add(2, 'days'),
          thursday: moment().add(3, 'days'),
          friday: moment().add(4, 'days'),
          saturday: moment().add(5, 'days'),
          sunday: moment().add(6, 'days'),
        }
      }
      if(today.day() === 2){
        currentWeek = {
          monday: moment().subtract(1, 'days'),
          tuesday: moment(),
          wednesday: moment().add(1, 'days'),
          thursday: moment().add(2, 'days'),
          friday: moment().add(3, 'days'),
          saturday: moment().add(4, 'days'),
          sunday: moment().add(5, 'days'),
        }
      }
      if(today.day() === 3){
        currentWeek = {
          monday: moment().subtract(2, 'days'),
          tuesday: moment().subtract(1, 'days'),
          wednesday: moment(),
          thursday: moment().add(1, 'days'),
          friday: moment().add(2, 'days'),
          saturday: moment().add(3, 'days'),
          sunday: moment().add(4, 'days'),
        }
      }
      if(today.day() === 4){
        currentWeek = {
          monday: moment().subtract(3, 'days'),
          tuesday: moment().subtract(2, 'days'),
          wednesday: moment().subtract(1, 'days'),
          thursday: moment(),
          friday: moment().add(1, 'days'),
          saturday: moment().add(2, 'days'),
          sunday: moment().add(3, 'days'),
        }
      }
      if(today.day() === 5){
        currentWeek = {
          monday: moment().subtract(4, 'days'),
          tuesday: moment().subtract(3, 'days'),
          wednesday: moment().subtract(2, 'days'),
          thursday: moment().subtract(1, 'days'),
          friday: moment(),
          saturday: moment().add(1, 'days'),
          sunday: moment().add(2, 'days'),
        }
      }
      if(today.day() === 6){
        currentWeek = {
          monday: moment().subtract(5, 'days'),
          tuesday: moment().subtract(4, 'days'),
          wednesday: moment().subtract(3, 'days'),
          thursday: moment().subtract(2, 'days'),
          friday: moment().subtract(1, 'days'),
          saturday: moment(),
          sunday: moment().add(1, 'days'),
        }
      }
      if(today.day() === 0){
        currentWeek = {
          monday: moment().subtract(6, 'days'),
          tuesday: moment().subtract(5, 'days'),
          wednesday: moment().subtract(4, 'days'),
          thursday: moment().subtract(3, 'days'),
          friday: moment().subtract(2, 'days'),
          saturday: moment().subtract(1, 'days'),
          sunday: moment(),
        }
      }
      if (this.props.role !== 'SuperAdmin') {
        let adminLastname = '';
        if(this.state.lastname !== 'null' && this.state.lastname !== null && this.state.lastname !== ''){
          adminLastname = this.state.lastname;
        }
        return(
          <div>
               <div className="notification_projects">
               <h2 className="dashboard-welcome">Oversigt</h2>
               <div className="dashboard_icons">                
                <div 
                    className="notificationBell" 
                    onClick={this.handleNotificationsList}>
                      {this.state.openList && this.state.listEmpty ? 
                      <div></div> :
                      <div className="notificationBell_circle">
                      {this.props.totalNotifications.length}
                      </div>
                      }
                        <BellLogo
                          width='30' 
                          active={this.state.bellActive ? true: false} 
                          animate={this.state.bellRing ? true: false} 
                          count={9}
                          color="#C5C7CD"/>
                      {this.state.openList && (
                      <div class="dropdown">
                      <h3 className="dropdown_headline">Dine notifikationer</h3>
                        <ul>
                          {this.props.totalNotifications.length === 0 ? <li>{messageEmpty}</li>  : this.props.totalNotifications.map((item,i) => <li key={i}>{item.id.title}<div>{item.id.text}</div></li>)}
                        </ul>
                      </div>
                      )}
                </div>
                <NavLink className="chatLogo" to='/customer_service_chats' exact>
                <ChatLogo />
                </NavLink>
                <div className="dashboard_indicator"/>
                  <div>
                   <h2 style={{ paddingRight: "12px"}} class="dashboard-user-name">
                     {localStorage.companyName}
                   </h2>
                  </div>
                <img class="profile_icon" src="https://cdn1.iconfinder.com/data/icons/avatars-1-5/136/51-512.png" /><img/>
               </div>
              </div>

              <div class="company-container">
                <div class="company-flex-1">
                  <h2 class='company-dashboard-cal-week'>Projekter i denne uge</h2>
                  <hr class='solid'></hr>
                  <h2 class='company-dashboard-title'>{`${today.date()} ${today.format('MMMM')}, ${today.format('dddd')}`}</h2>
                  <div class='company-cal'>
                        <div class='company-cal-days'>
                          {
                          this.state.datePicker === 1 ?
                          <div class='company-days'>
                          {
                            today.day() === 1 ?
                            <p class='company-day-today'>Man</p>:
                            <p class='company-day-datepicker'>Man</p>
                          }
                          <p class='company-date-datepicker' onClick={()=>{this.newDatePicker(1, moment(currentWeek.monday).format('YYYY-MM-DD'))}}>{currentWeek.monday.date()}</p>
                          </div>
                          :
                          today.day() === 1 ? 
                          <div class='company-days'>
                          <p class='company-day-today'>Man</p>
                          <p class='company-date-today' onClick={()=>{this.newDatePicker(1, moment(currentWeek.monday).format('YYYY-MM-DD'))}}>{currentWeek.monday.date()}</p>
                          </div>
                          :
                          <div class='company-days'>
                          <p class='company-day'>Man</p>
                          <p class='company-date' onClick={()=>{this.newDatePicker(1, moment(currentWeek.monday).format('YYYY-MM-DD'))}}>{currentWeek.monday.date()}</p>
                          </div>
                          }
                          {
                          this.state.datePicker === 2 ?
                          <div class='company-days'>
                            {
                            today.day() === 2 ?
                            <p class='company-day-today'>Tir</p>:
                            <p class='company-day-datepicker'>Tir</p>
                          }
                          <p class='company-date-datepicker' onClick={()=>{this.newDatePicker(2, moment(currentWeek.tuesday).format('YYYY-MM-DD'))}}>{currentWeek.tuesday.date()}</p>
                          </div>
                          :
                          today.day() === 2 ?
                          <div class='company-days'>
                          <p class='company-day-today'>Tir</p>
                          <p class='company-date-today' onClick={()=>{this.newDatePicker(2, moment(currentWeek.tuesday).format('YYYY-MM-DD'))}}>{currentWeek.tuesday.date()}</p>
                          </div>
                          :
                          <div class='company-days'>
                          <p class='company-day'>Tir</p>
                          <p class='company-date' onClick={()=>{this.newDatePicker(2, moment(currentWeek.tuesday).format('YYYY-MM-DD'))}}>{currentWeek.tuesday.date()}</p>
                          </div>
                          }
                          {
                          this.state.datePicker === 3 ?
                          <div class='company-days'>
                            {
                            today.day() === 3 ?
                            <p class='company-day-today'>Ons</p>:
                            <p class='company-day-datepicker'>Ons</p>
                          }
                          <p class='company-date-datepicker' onClick={()=>{this.newDatePicker(3, moment(currentWeek.wednesday).format('YYYY-MM-DD'))}}>{currentWeek.wednesday.date()}</p>
                          </div>
                          :
                          today.day() === 3 ? 
                          <div class='company-days'>
                          <p class='company-day'>Ons</p>
                          <p class='company-date' onClick={()=>{this.newDatePicker(3, moment(currentWeek.wednesday).format('YYYY-MM-DD'))}}>{currentWeek.wednesday.date()}</p>
                          </div>
                          :
                          <div class='company-days'>
                          <p class='company-day'>Ons</p>
                          <p class='company-date' onClick={()=>{this.newDatePicker(3, moment(currentWeek.wednesday).format('YYYY-MM-DD'))}}>{currentWeek.wednesday.date()}</p>
                          </div>
                          }
                          {
                          this.state.datePicker === 4 ?
                          <div class='company-days'>
                            {
                            today.day() === 4 ?
                            <p class='company-day-today'>Tor</p>:
                            <p class='company-day-datepicker'>Tor</p>
                          }
                          <p class='company-date-datepicker' onClick={()=>{this.newDatePicker(4, moment(currentWeek.thursday).format('YYYY-MM-DD'))}}>{currentWeek.thursday.date()}</p>
                          </div> 
                          :
                          today.day() === 4 ?
                          <div class='company-days'>
                          <p class='company-day-today'>Tor</p>
                          <p class='company-date-today' onClick={()=>{this.newDatePicker(4, moment(currentWeek.thursday).format('YYYY-MM-DD'))}}>{currentWeek.thursday.date()}</p>
                          </div>
                          :
                          <div class='company-days'>
                          <p class='company-day'>Tor</p>
                          <p class='company-date' onClick={()=>{this.newDatePicker(4, moment(currentWeek.thursday).format('YYYY-MM-DD'))}}>{currentWeek.thursday.date()}</p>
                          </div> 
                          }
                          {
                          this.state.datePicker === 5 ?
                          <div class='company-days'>
                            {
                            today.day() === 5 ?
                            <p class='company-day-today'>Fre</p>:
                            <p class='company-day-datepicker'>Fre</p>
                          }
                          <p class='company-date-datepicker' onClick={()=>{this.newDatePicker(5, moment(currentWeek.friday).format('YYYY-MM-DD'))}}>{currentWeek.friday.date()}</p>
                          </div>
                          :
                          today.day() === 5 ? 
                          <div class='company-days'>
                          <p class='company-day-today'>Fre</p>
                          <p class='company-date-today' onClick={()=>{this.newDatePicker(5, moment(currentWeek.friday).format('YYYY-MM-DD'))}}>{currentWeek.friday.date()}</p>
                          </div>
                          :
                          <div class='company-days'>
                          <p class='company-day'>Fre</p>
                          <p class='company-date' onClick={()=>{this.newDatePicker(5, moment(currentWeek.friday).format('YYYY-MM-DD'))}}>{currentWeek.friday.date()}</p>
                          </div>
                          }
                          {
                          this.state.datePicker === 6 ?
                          <div class='company-days'>
                          {
                            today.day() === 6 ?
                            <p class='company-day-today'>Lør</p>:
                            <p class='company-day-datepicker'>Lør</p>
                          }
                          <p class='company-date-datepicker' onClick={()=>{this.newDatePicker(6, moment(currentWeek.saturday).format('YYYY-MM-DD'))}}>{currentWeek.saturday.date()}</p>
                          </div>
                          :
                          today.day() === 6 ?
                          <div class='company-days'>
                          <p class='company-day-today'>Lør</p>
                          <p class='company-date-today' onClick={()=>{this.newDatePicker(6, moment(currentWeek.saturday).format('YYYY-MM-DD'))}}>{currentWeek.saturday.date()}</p>
                          </div>
                          :
                          <div class='company-days'>
                          <p class='company-day'>Lør</p>
                          <p class='company-date' onClick={()=>{this.newDatePicker(6, moment(currentWeek.saturday).format('YYYY-MM-DD'))}}>{currentWeek.saturday.date()}</p>
                          </div>
                          }
                          {
                          this.state.datePicker === 0 ?
                          <div class='company-days'>
                            {
                            today.day() === 0 ?
                            <p class='company-day-today'>Søn</p>:
                            <p class='company-day-datepicker'>Søn</p>
                          }
                          <p class='company-date-datepicker' onClick={()=>{this.newDatePicker(0, moment(currentWeek.sunday).format('YYYY-MM-DD'))}}>{currentWeek.sunday.date()}</p>
                          </div>
                          :
                          today.day() === 0 ? 
                          <div class='company-days'>
                          <p class='company-day-today'>Søn</p>
                          <p class='company-date-today' onClick={()=>{this.newDatePicker(0, moment(currentWeek.sunday).format('YYYY-MM-DD'))}}>{currentWeek.sunday.date()}</p>
                          </div>:
                          <div class='company-days'>
                          <p class='company-day'>Søn</p>
                          <p class='company-date' onClick={()=>{this.newDatePicker(0, moment(currentWeek.sunday).format('YYYY-MM-DD'))}}>{currentWeek.sunday.date()}</p>
                          </div>
                          }
                        </div>
                        {this.renderProjectCards(this.routeChangeChat, this.startEditProjectHandler, this.popupDelete, this.routeChangeProject)}

                  </div>
                </div>
                <div class="company-container-2">
                  <div class="company-flex-2">
                  <h2 class='company-dashboard-title'>Oversigt</h2>
                  <hr class='solid'></hr>
                  <div class='company-dashboard-overview-container-boxes'>
                  <div class='company-dashboard-overview-container-boxes-1'>
                    <div class='company-dashboard-overview-cardbox'>
                      <Card className="dashboard_cards" title="Kundeoversigt" buttonText="Oversigt" buttonOnClick={this.routeChangeClients} typeOf='dashboard'>
                      <CardContent>
                        <Typography className="dashboard_number_of" variant="h4">
                        {numClients ? numClients:0}
                        </Typography>
                        </CardContent>
                      </Card>
                    </div>
                    <div class='company-dashboard-overview-cardbox'>
                    <Card className="dashboard_cards" title="Håndværkeroversigt" buttonText="Oversigt" buttonOnClick={this.routeChangeCraftsmen} typeOf='dashboard'>
                    <CardContent>
                      <Typography className="dashboard_number_of" variant="h4">
                      {numCraftsmen ? numCraftsmen:0}
                      </Typography>
                    </CardContent>
                    </Card>
                    </div>
                  </div>
                  <div class='company-dashboard-overview-container-boxes-2'>
                  <Card className="dashboard_cards" title="Projektoversigt" buttonText="Oversigt" buttonOnClick={this.routeChangeProjects } typeOf='dashboard'>
                  <CardContent>
                      <Typography className="dashboard_number_of" variant="h4">
                      {numProjects ? numProjects:0}
                      </Typography>
                  </CardContent>
                  </Card>
                  </div>
                  </div>

                  </div>
                  <div class="company-flex-3">
                  <h2 class='company-dashboard-title'>Projekter</h2>
                  <hr class='solid'></hr>
                  <div class='company-dashboard-body-container'>
                    <div class='company-dashboard-circle'>
                    <Doughnut
                    width={550}
                    height={550}
                    data={state}
                    options={{
                      title:{
                        display:true,
                        text: `${Math.round(completedPercentage)}% Afsluttet`,
                        fontSize:20,
                        fontColor: '#2ED47A',
                      },
                      legend:{
                        display:true,
                        position:'right'
                      },
                      maintainAspectRatio: false ,
                    }}
                    />
                    </div>
                  </div>
                  </div>
                </div>
              </div>

              <Fragment>
                <ErrorHandler error={this.state.error} onHandle={this.errorHandler} />
                <ProjectFeedEdit
                  clients={this.state.allCustomers}
                  craftsmen={this.state.allCraftsmen}
                  editing={this.state.isEditing}
                  enableEdit={this.state.enableEdit}
                  selectedProject={this.state.editProject}
                  loading={this.state.editLoading}
                  onCancelEdit={this.cancelEditHandler}
                  onFinishEdit={this.finishEditHandler}
                  startSelector={this.state.startSelector}
                  newListOfCraftsmen={this.state.newListOfCraftsmen}
                  newListOfCustomers={this.state.newListOfCustomers}
                />
              </Fragment>
          </div>
        );
      } else {
        let adminLastname = '';
        if(this.state.lastname !== 'null' && this.state.lastname !== null && this.state.lastname !== ''){
          adminLastname = this.state.lastname;
        }
          return(
            <div>
               <div className="notification_projects">
               <h2 className="dashboard-welcome">Oversigt</h2>
               <div className="dashboard_icons">                
                  <div 
                      className="notificationBell" 
                      onClick={this.handleNotificationsList}>
                        {this.state.openList && this.state.listEmpty ? 
                        <div></div> :
                        <div className="notificationBell_circle">
                        {this.props.totalNotifications.length}
                        </div>
                        }
                          <BellLogo
                            width='30' 
                            active={this.state.bellActive ? true: false} 
                            animate={this.state.bellRing ? true: false} 
                            count={9}
                            color="#C5C7CD"/>
                        {this.state.openList && (
                        <div class="dropdown">
                        <h3 className="dropdown_headline">Dine notifikationer</h3>
                          <ul>
                            {this.props.totalNotifications.length === 0 ? <li>{messageEmpty}</li>  : this.props.totalNotifications.map((item,i) => <li key={i}>{item.id.title}<div>{item.id.text}</div></li>)}
                          </ul>
                        </div>
                        )}
                  </div>
                  <NavLink className="chatLogo" to='/customer_service_chats' exact>
                  <ChatLogo />
                  </NavLink>
                  <div className="dashboard_indicator"/>
                    <div>
                     <h2 style={{ paddingRight: "12px"}} class="dashboard-user-name">
                       {this.state.firstname} {adminLastname}
                     </h2>
                    </div>
                  <img class="profile_icon" src="https://cdn1.iconfinder.com/data/icons/avatars-1-5/136/51-512.png" /><img/>
                 </div>
                </div>
                <div class='some-page-wrapper'>
  <div class='row'>
    <div class='column'>
      <div class='blue-column'>
                <Card className="card-body-overview" title="Virksomhedsoversigt" buttonText="Oversigt" buttonOnClick={this.routeChangeCraftsmen} typeOf="overview">
                    <CardContent>
                        <Typography className="dashboard_number_of" variant="h4">
                        {numCompanies ? numCompanies:0}
                        </Typography>
                    </CardContent>
                </Card>
      </div>
    </div>
    <div class='column'>
      <div class='green-column'>
                <Card className="card-body-overview" title="Projektoversigt" buttonText="Oversigt" buttonOnClick={this.routeChangeProjects} typeOf="overview">
                    <CardContent>
                        <Typography className="dashboard_number_of" variant="h4">
                        {numProjects ? numProjects:0}
                        </Typography>
                    </CardContent>
                </Card>
      </div>
    </div>
  </div>
  <div class='row'>
    <div class='column'>
      <div class='green-column'>
                <Card className="card-body-overview" title="Håndværkeroversigt" buttonText="Oversigt" buttonOnClick={this.routeChangeCraftsmen} typeOf="overview">
                    <CardContent>
                        <Typography className="dashboard_number_of" variant="h4">
                        {numCraftsmen ? numCraftsmen:0}
                        </Typography>
                    </CardContent>
                </Card>
      </div>
    </div>
    <div class='column'>
      <div class='blue-column'>
                <Card className="card-body-overview" title="Kundeoversigt" buttonText="Oversigt" buttonOnClick={this.routeChangeClients} typeOf="overview">
                    <CardContent>
                        <Typography className="dashboard_number_of" variant="h4">
                        {numClients ? numClients:0}
                        </Typography>
                    </CardContent>
                </Card>
      </div>
    </div>
  </div>
</div>
            </div>
        );
      }
    }       
  }

export default Dashboard;
