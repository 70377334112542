import React, { Component, useEffect, useRef } from 'react';


import './CustomerServiceChatPage.css';
import { makeStyles } from '@material-ui/core/styles';
import ChatPanel from '../../../../components/ChatPanel/ChatPanel';
import { auth, messageRef, roomRef } from '../../../../util/fire';
import api from '../../../../util/config';
import {ReactComponent as ChatLogo } from '../../../../assets/chat_icon.svg';
import {ReactComponent as BellLogo } from '../../../../assets/bell_icon.svg';
import { NavLink } from 'react-router-dom';

class CustomerServiceChatPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      scrollViewHeight: 0,
      inputHeight: 0,
      user: '',
      uid: '',
      role: localStorage.role,
      userFirstname: localStorage.firstname,
      userLastname: localStorage.lastname,
      isLoggedIn: false,
      rooms: '',
      totalRooms: '',
      projectId: this.props.match.params.projectId,
      project: '',
      assigneeIds: '',
      clientIds: '',
      selectedRoom: this.props.match.params.roomId,
      messages: {},
      senderUID: this.props.userId,
      bellActive: false,
      bellRing: false,
      notificationList: [],
      openList: false,
      listEmpty: true,
      loading: false,
      unreadNotifications: false,
      finishedLoading: false,
    }
}

handleNotificationsList = () => {
  this.setState(state => {
    return {
      bellRing: false,
      bellActive: false,
      openList: !state.openList,
      unreadNotifications: false,
    };
  });
};

setRoom = () => {
  const rId = this.state.selectedRoom;
  messageRef
    .orderByChild('roomId')
    .equalTo(rId)
    .once('value')
    .then(snapshot => {
      const messages = snapshot.val() || {};
      this.setState({
        messages
      })
    })
}

loadData = () => {
  roomRef.once('value')
        .then(snapshot => {
          const rooms = snapshot.val() || {};
          this.setState({
            rooms,
          });
          return messageRef
                    .orderByChild('roomId')
                    .equalTo(this.state.selectedRoom)
                    .once('value')
        })
        .then(snapshot => {
          const messages = snapshot.val() || {}
          this.setState({
            messages
          })
        })
        .catch(err => { return err.json })
};

componentDidMount = () => {
    auth.onAuthStateChanged(user => {
      if (user) {
        const {email, uid} = user;
        this.setState({
          email,
          uid,
          isLoggedIn: true
        })
        this.loadData();
        roomRef.on('value', snapshot => {
          const rooms = snapshot.val();
          this.setState({
            rooms
          })
        })
        messageRef.on('child_added', snapshot => {
                    const message = snapshot.val();
                    const key = snapshot.key;
                    if (message.roomId === this.state.selectedRoom) {
                      this.setState({
                        messages: {
                          ...this.state.messages,
                          [key]: message
                        }
                      })
                    }
                  })
      }
    })
}


    sendMessage = (message) => {
        messageRef.push(message);
        this.setRoom();
    }


render() {
  const messageEmpty = ["Ingen nye notifikationer"]
    return (
        <div>
          <div className="notification_projects">
          <h2 className="dashboard-welcome">Chat:</h2>
               <div className="dashboard_icons">                
                  <div 
                      className="notificationBell" 
                      onClick={this.handleNotificationsList}>
                        {this.state.openList && this.state.listEmpty ? 
                        <div></div> :
                        <div className="notificationBell_circle">
                        {this.props.totalNotifications.length}
                        </div>
                        }
                          <BellLogo
                            width='30' 
                            active={this.state.bellActive ? true: false} 
                            animate={this.state.bellRing ? true: false} 
                            count={9}
                            color="#C5C7CD"/>
                        {this.state.openList && (
                        <div class="dropdown">
                        <h3 className="dropdown_headline">Dine notifikationer</h3>
                          <ul>
                            {this.props.totalNotifications.length === 0 ? <li>{messageEmpty}</li>  : this.props.totalNotifications.map((item,i) => <li key={i}>{item.id.title}<div>{item.id.text}</div></li>)}
                          </ul>
                        </div>
                        )}
                  </div>
                  <NavLink className="chatLogo" to='/customer_service_chats' exact>
                  <ChatLogo />
                  </NavLink>
                  <div className="dashboard_indicator"/>
                    <div>
                     <h2 style={{ paddingRight: "12px"}} class="dashboard-user-name">
                     {this.props.role === 'SuperAdmin' ? `${localStorage.firstname} ${localStorage.lastname !== 'null' && localStorage.lastname != null && localStorage.lastname != '' && this.props.role === 'SuperAdmin' ? localStorage.lastname : ''}` : localStorage.companyName}
                     </h2>
                    </div>
                  <img class="profile_icon" src="https://cdn1.iconfinder.com/data/icons/avatars-1-5/136/51-512.png" /><img/>
            </div>
          </div>
            <div className="chat-page-div-users">
                <div className="chat-panel">
                  
                    <ChatPanel 
                    messages={this.state.messages}
                    roomId={this.state.selectedRoom}
                    email={this.props.email}
                    userId={this.props.userId}
                    sendMessage={this.sendMessage}
                    userFirstname={this.state.userFirstname}
                    userLastname={this.state.userLastname}
                    cId={this.state.clientIds}
                    aId={this.state.assigneeIds}
                    role={this.role}
                    senderUID={this.state.senderUID}
                    />
                </div>
            </div>
        </div>)
    }
}
export default CustomerServiceChatPage;
