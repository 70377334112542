import * as React from 'react';

import Button from '../../components/Button/Button';
import './Card.css';


export default class Card extends React.Component {

    render() {

        const { cardClass, title, buttonText, buttonOnClick, children, typeOf } = this.props;

        if(typeOf === "overview"){
            return (
                <div className={typeOf}>
                    <div className="card-header-overview">
                        <div className="card-title-overview">
                            <h3>{title}</h3>
                        </div>
                        <div className="card-buttons-overview">
                            <Button design="raised" onClick={buttonOnClick} type="submit">
                                {buttonText}
                            </Button>
                        </div>
                    </div>
                    <div className={`card-body-${typeOf}`}>
                        {children}
                    </div>
                </div>
            ); 
        }else if(typeOf === "dashboard"){
            return (
                <div className={typeOf}>
                    <div className="card-header-dashboard">
                        <div className="card-title-dashboard">
                            <h3>{title}</h3>
                        </div>
                        <div className="card-buttons-dashboard">
                            <Button design="raised" onClick={buttonOnClick} type="submit">
                                {buttonText}
                            </Button>
                        </div>
                    </div>
                    <div className={`card-body-${typeOf}`}>
                        {children}
                    </div>
                </div>
            ); 
        } else{
            return (
                <div className={"card" || cardClass }>
                    <div className="card-header">
                        <div className="card-title">
                            <h3>{title}</h3>
                        </div>
                        <div className="card-buttons">
                            <Button design="card-raised" onClick={buttonOnClick} type="submit">
                                {buttonText}
                            </Button>
                        </div>
                    </div>
                    <div className="card-body">
                        {children}
                    </div>
                </div>
            );
        }

    }
}
