import React, { Component, Fragment } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MessageIcon from '@material-ui/icons/Message';
import InfoIcon from '@material-ui/icons/Info';
import moment from 'moment';
import api from '../../util/config';
import Project from '../../components/ProjectFeed/Project/Project';
import Button from '../../components/Button/Button';
import ProjectFeedEdit from '../../components/ProjectFeed/ProjectFeedEdit/ProjectFeedEdit';
import Paginator from '../../components/Paginator/Paginator';
import Loader from '../../components/Loader/Loader';
import ErrorHandler from '../../components/ErrorHandler/ErrorHandler';
import './ProjectFeed.css';
import { roomRef } from '../../util/fire';
import Card from '../../components/Card/Card';
import Table from '../../components/Table/Table';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import firebaseApp from '../../util/fire';
import { auth, messageRef } from '../../util/fire';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { store } from 'react-notifications-component';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { ReactComponent as ChatLogo } from '../../assets/chat_icon.svg';
import { ReactComponent as BellLogo } from '../../assets/bell_icon.svg';
import { NavLink } from 'react-router-dom';

class ProjectFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      author: '',
      name: '',
      created: '',
      customers: [],
      craftsmen: [],
      CompanyAdminProjects: [],
      totalCustomers: [],
      totalCraftsmen: [],
      isEditing: false,
      enableEdit: false,
      projects: [],
      totalProjects: 0,
      editProject: null,
      status: '',
      isLogged: false,
      projectPage: 1,
      projectsLoading: true,
      editLoading: false,
      startSelector: false,
      newListOfCraftsmen: '',
      newListOfCustomers: '',
      updatedProjectsList: [],
      bellActive: false,
      bellRing: false,
      notificationList: [],
      openList: false,
      listEmpty: true,
      loading: false,
      unreadNotifications: false,
      finishedLoading: false,
    };
  }

  handleNotificationsList = () => {
    this.setState((state) => {
      return {
        bellRing: false,
        bellActive: false,
        openList: !state.openList,
        unreadNotifications: false,
      };
    });
  };

  componentDidMount() {
    if (!this.state.isLogged) {
      this.setState({
        isLogged: true,
      });
      this.loadProjects();
      this.loadCustomers();
      this.loadCraftsmen();
    }
  }

  componentDidUpdate() {}

  routeChangeProject = (_id) => {
    const { history } = this.props;
    let path = `/projects/${_id}`;
    history.push(path);
  };

  routeChangeChat = (_id) => {
    const { history } = this.props;
    let path = `/chats/${_id}`;
    history.push(path);
  };

  loadCustomers = () => {
    const graphqlQuery = {
      query: `
      {
        users {
          users {
            _id
            firstname
            lastname
            role
            email
            status
            company
          }
          
        }
      }
      `,
    };
    // Fetch all users and select any who matches role 'Client'. Then add to new list.
    fetch(api.baseUrl, {
      method: 'POST',
      body: JSON.stringify(graphqlQuery),
      headers: {
        Authorization: 'Bearer ' + this.props.token,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        if (resData.errors && resData.errors[0].status === 422) {
          throw new Error('Validering fejlede. Invalid input');
        }
        if (resData.errors) {
          throw new Error('Kunne ikke hente brugere.');
        }
        this.setState({ customers: resData.data.users.users });
        for (let i = 0; i <= this.state.customers.length - 1; i++) {
          if (this.state.customers[i].role === 'Client') {
            this.state.totalCustomers.push(this.state.customers[i]);
          }
        }
        if (this.props.role == 'SuperAdmin') {
          this.setState({
            totalCustomers: this.state.totalCustomers,
            newListOfCustomers: this.state.totalCustomers.map((customer) => {
              return {
                value: customer._id,
                label: customer.firstname + ' ' + customer.lastname,
              };
            }),
          });
        } else {
          let newListOfCompanyCustomers = [];
          this.state.totalCustomers.forEach((customer) => {
            if (
              customer.company != null &&
              customer.company == this.props.userId
            ) {
              newListOfCompanyCustomers.push({
                value: customer._id,
                label: customer.firstname + ' ' + customer.lastname,
              });
            }
          });
          this.setState({
            totalCustomers: this.state.totalCustomers,
            newListOfCustomers: newListOfCompanyCustomers,
          });
        }
      })
      .catch(this.catchError);
  };

  catchError = (error) => {
    this.setState({ error: error });
  };

  loadCraftsmen = () => {
    const graphqlQuery = {
      query: `
      {
        users {
          users {
            _id
            firstname
            lastname
            role
            email
            status
            company
          }
          
        }
      }
      `,
    };
    // Fetch all users and select any who matches role 'Craftsman'. Then add to new list.
    fetch(api.baseUrl, {
      method: 'POST',
      body: JSON.stringify(graphqlQuery),
      headers: {
        Authorization: 'Bearer ' + this.props.token,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        if (resData.errors && resData.errors[0].status === 422) {
          throw new Error('Validering fejlede. Invalid input');
        }
        if (resData.errors) {
          throw new Error('Kunne ikke hente brugere.');
        }
        this.setState({ craftsmen: resData.data.users.users });
        for (let i = 0; i <= this.state.craftsmen.length - 1; i++) {
          if (this.state.craftsmen[i].role === 'Craftsman') {
            this.state.totalCraftsmen.push(this.state.craftsmen[i]);
          }
        }

        if (this.props.role == 'SuperAdmin') {
          this.setState({
            totalCraftsmen: this.state.totalCraftsmen,
            newListOfCraftsmen: this.state.totalCraftsmen.map((craftsman) => {
              return {
                value: craftsman._id,
                label: craftsman.firstname + ' ' + craftsman.lastname,
              };
            }),
          });
        } else {
          let newListOfCompanyCraftsmen = [];
          this.state.totalCraftsmen.forEach((craftsman) => {
            if (
              craftsman.company != null &&
              craftsman.company == this.props.userId
            ) {
              newListOfCompanyCraftsmen.push({
                value: craftsman._id,
                label: craftsman.firstname + ' ' + craftsman.lastname,
              });
            }
          });
          this.setState({
            totalCraftsmen: this.state.totalCraftsmen,
            newListOfCraftsmen: newListOfCompanyCraftsmen,
          });
        }
      })
      .catch(this.catchError);
  };

  async loadProjects(direction) {
    this.setState({
      startSelector: true,
      projects: [],
      updatedProjectsList: [],
      totalProjects: [],
    });
    if (direction) {
      this.setState({ projectsLoading: true, projects: [] });
    }
    let page = this.state.projectPage;
    if (direction === 'next') {
      page++;
      this.setState({ projectPage: page });
    }
    if (direction === 'previous') {
      page--;
      this.setState({ projectPage: page });
    }
    const graphqlQuery = {
      query: `
      query {
        projects {
          projects {
            _id
            title
            description
            startDate
            finishDate
            status
            createdAt
            attachments
            TODO
            creator {
              _id
              firstname
            }
            clients {
              _id
              firstname
              lastname
              avatarImage
            }
            assignees {
              _id
              firstname
              lastname
            }
          }
          totalProjects
        }
      }
      `,
      variables: {
        page: page,
      },
    };
    await fetch(api.baseUrl, {
      method: 'POST',
      body: JSON.stringify(graphqlQuery),
      headers: {
        Authorization: 'Bearer ' + this.props.token,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        if (resData.errors && resData.errors[0].status === 422) {
          throw new Error('Validering fejlede. Invalid input');
        }
        if (resData.errors) {
          throw new Error('Kunne ikke hente projekter.');
        }
        if (this.props.role === 'Company') {
          this.setState({
            totalProjects: resData.data.projects.projects.map((project) => {
              return {
                ...project,
              };
            }),
          });
          for (let i = 0; i <= this.state.totalProjects.length - 1; i++) {
            if ( this.state.totalProjects[i].creator != null && this.state.totalProjects[i].creator != undefined && 
              this.state.totalProjects[i].creator._id === this.props.userId) {
              this.state.projects.push(this.state.totalProjects[i]);
            }
          }
          this.setState({
            projectsLoading: false,
          });
        } else {
          this.setState({
            projects: resData.data.projects.projects.map((project) => {
              return {
                ...project,
              };
            }),
            totalProjects: resData.data.projects.totalProjects,
            projectsLoading: false,
          });
        }
      })
      .catch(this.catchError);
    const listofprojects = [];
    try {
      for (let l = 0; l <= this.state.projects.length - 1; l++) {
        listofprojects.push(this.state.projects[l]);
      }
    } catch (error) {
      this.catchError(error);
    }
    for (let k = 0; k <= listofprojects.length - 1; k++) {
      if (listofprojects[k].clients === null) {
        listofprojects[k].clients = 'Ingen kunde tilknyttet';
      }
      if (listofprojects[k].assignees === null) {
        listofprojects[k].assignees = 'Ingen håndværker tilknyttet';
      }
    }
    this.setState({
      updatedProjectsList: listofprojects,
      finishedLoading: true,
    });
  }

  statusUpdateHandler = (event) => {
    event.preventDefault();
    fetch('URL')
      .then((res) => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("Can't update status!");
        }
        return res.json();
      })
      .then((resData) => {})
      .catch(this.catchError);
  };

  newProjectHandler = () => {
    this.setState({ isEditing: true });
  };

  startEditProjectHandler = (projectId) => {
    this.setState((prevState) => {
      const loadedProject = {
        ...prevState.projects.find((p) => p._id === projectId),
      };
      return {
        isEditing: true,
        enableEdit: true,
        editProject: loadedProject,
      };
    });
  };

  cancelEditHandler = () => {
    this.setState({ isEditing: false, editProject: null });
  };

  finishEditHandler = (projectData) => {
    this.setState({
      editLoading: true,
    });
    let craftsmenIds = [];
    let attachments = [];
    for (let i = 0; i < projectData.craftsmen.length; i++) {
      if (projectData.craftsmen[i]._id === undefined) {
        craftsmenIds.push(projectData.craftsmen[i].value);
      } else {
        craftsmenIds.push(projectData.craftsmen[i]._id);
      }
    }
    if(projectData.attachments){
      attachments = projectData.attachments;
    }
    let graphqlQuery = {
      query: `
        mutation CreateNewProject($title: String!, $description: String!, $status: String, $startDate: String, $finishDate: String, $craftsmen: [ID!], $clients: ID!, $attachments: [String] ) {
          createProject(projectInput: {title: $title, description: $description, status: $status, startDate: $startDate, finishDate: $finishDate, aId: $craftsmen, cId: $clients, attachments: $attachments}) {
            _id
            title
            description
            status
            startDate
            finishDate
            attachments
            clients {
              _id
              firstname
              lastname
            }
            assignees {
              _id
              firstname
              lastname
            }
          }
        }
      `,
      variables: {
        title: projectData.title,
        description: projectData.description,
        clients: projectData.clients,
        craftsmen: craftsmenIds,
        status: `Ikke påbegyndt`,
        startDate: projectData.startDate,
        finishDate: projectData.finishDate,
        attachments: attachments,
      },
    };
    if (this.state.editProject) {
      graphqlQuery = {
        query: `
          mutation UpdateExistingProject($projectId: ID!, $title: String!, $description: String!, $status: String, $startDate: String, $finishDate: String, $craftsmen: [ID!], $clients: ID!, $attachments: [String], $TODO: String) {
            updateProject(id: $projectId, projectInput: {title: $title, description: $description, status: $status, startDate: $startDate, finishDate: $finishDate, aId: $craftsmen, cId: $clients, attachments: $attachments, TODO: $TODO}) {
              _id
              title
              description
              status
              startDate
              finishDate
              attachments
              TODO
              assignees {
                _id
                firstname
                lastname
              }
              clients {
                _id
                firstname
                lastname
              }
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          projectId: this.state.editProject._id,
          title: projectData.title,
          description: projectData.description,
          status: 'Ikke påbegyndt',
          clients: projectData.clients,
          craftsmen: craftsmenIds,
          startDate: projectData.startDate,
          finishDate: projectData.finishDate,
          attachments: attachments,
          TODO: this.state.editProject.TODO,
        },
      };
    }
    fetch(api.baseUrl, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + this.props.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(graphqlQuery),
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        if (resData.errors && resData.errors[0].status === 422) {
          throw new Error('Validering fejlede. Invalid input');
        }
        if (resData.errors) {
          throw new Error('Oprettelse af nyt projekt fejlede.');
        }
        let resDataField = 'createProject';
        if (this.state.editProject) {
          resDataField = 'updateProject';
        }

        const project = {
          _id: resData.data[resDataField]._id,
          title: resData.data[resDataField].title,
          description: resData.data[resDataField].description,
          status: resData.data[resDataField].status,
          clients: resData.data[resDataField].clients,
          assignees: resData.data[resDataField].assignees,
          createdAt: resData.data[resDataField].createdAt,
          startDate: resData.data[resDataField].startDate,
          finishDate: resData.data[resDataField].finishDate,
          attachments: resData.data[resDataField].attachments,
          TODO: resData.data[resDataField].TODO,
        };
        if (resDataField === 'createProject') {
          // Create chat room to firebase db based on project._id
          const room = {
            author: this.props.userId,
            name: project._id,
            created: Date.now(),
          };
          roomRef.push(room.name);
        }
        this.setState((prevState) => {
          let updatedProjects = [...prevState.projects];
          let updatedTotalProjects = prevState.totalProjects;
          if (prevState.editProject) {
            const projectIndex = prevState.projects.findIndex(
              (p) => p._id === prevState.editProject._id
            );
            updatedProjects[projectIndex] = project;
          } else {
            updatedTotalProjects++;
            if (prevState.projects.length >= 3) {
              updatedProjects.pop();
            }
            updatedProjects.unshift(project);
          }
          return {
            projects: updatedProjects,
            isEditing: false,
            editProject: null,
            editLoading: false,
            totalProjects: updatedTotalProjects,
          };
        });
        this.loadProjects();
      })
      .catch((err) => {
        this.setState({
          isEditing: false,
          editProject: null,
          editLoading: false,
          error: err,
        });
      });
  };

  statusInputChangeHandler = (input, value) => {
    this.setState({ status: value });
  };

  deleteProjectHandler = (projectId) => {
    this.setState({ projectsLoading: true });
    const graphqlQuery = {
      query: `
        mutation {
          deleteProject(id: "${projectId}")
        }
      `,
    };
    fetch(api.baseUrl, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + this.props.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(graphqlQuery),
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        if (resData.errors) {
          throw new Error('Deleting the post failed!');
        }
        this.loadProjects();
      })
      .catch((err) => {
        this.setState({ projectsLoading: false });
      });
  };

  errorHandler = () => {
    this.setState({ error: null });
  };

  catchError = (error) => {
    this.setState({ error: error });
  };

  popupDelete = (_id) => {
    confirmAlert({
      title: 'Slet projekt',
      message: 'Er du sikker?',
      buttons: [
        {
          label: 'Nej',
        },
        {
          label: 'Ja',
          onClick: () => this.deleteProjectHandler(_id),
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };

  checkStatus = (status) => {
    if (status === 'Ikke påbegyndt') {
      return (
        <p
          className='badge'
          style={{ color: '#FFFFFF', backgroundColor: '#F7685B' }}
        >
          {status}
        </p>
      );
    } else if (status === 'Igangværende') {
      return (
        <p
          className='badge'
          style={{ color: '#FFFFFF', backgroundColor: '#FFB946' }}
        >
          {status}
        </p>
      );
    } else if (status === 'Afsluttet') {
      return (
        <p
          className='badge'
          style={{ color: '#FFFFFF', backgroundColor: '#29CC97' }}
        >
          {status}
        </p>
      );
    } else if (status === 'Forsinket') {
      return (
        <p
          className='badge'
          style={{ color: '#FFFFFF', backgroundColor: '#FFD809' }}
        >
          {status}
        </p>
      );
    } else {
      return;
    }
  };

  renderTableData() {
    return this.state.updatedProjectsList.map((projectParams, index) => {
      const {
        _id,
        title,
        clients,
        status,
        startDate,
        finishDate,
      } = projectParams;
      const cfirstname = clients.firstname
        ? clients.firstname
        : 'Ingen kunder tilknyttet';
      const clastname = clients.lastname ? clients.lastname : '';
      const cfullname = cfirstname + ` ` + clastname;
      const avatarImage = clients.avatarImage;

      return {
        id: _id,
        rows: [
          {
            text: title,
            type: 'name',
            email: cfullname,
            avatarImage,
            typeOf: 'projectpage',
          },
          { text: moment(startDate).format('DD-MM-YYYY'), type: 'date' },
          { text: moment(finishDate).format('DD-MM-YYYY'), type: 'date' },
          { text: this.checkStatus(status), type: 'status' },
          {
            type: 'actions',
            actions: [
              {
                icon: 'editnew',
                onClick: () => this.startEditProjectHandler(_id),
              },
              { icon: 'deletenew', onClick: () => this.popupDelete(_id) },
              {
                icon: <MessageIcon fontSize='small' />,
                onClick: () => this.routeChangeChat(_id),
              },
            ],
          },
          {
            type: 'actions',
            actions: [
              {
                icon: 'info-squarenew',
                onClick: () => this.routeChangeProject(_id),
              },
            ],
            typeOf: 'info',
          },
        ],
      };
    });
  }

  render() {
    const messageEmpty = ['Ingen nye notifikationer'];
    const ProjectList = this.state.updatedProjectsList;
    const headings = [
      'Titel og Kunde',
      'Start dato',
      'Slut dato',
      'Status',
      'Handlinger',
      'Info',
    ];
    if (ProjectList.length === 0) {
      return (
        <div>
          <Fragment>
            <ErrorHandler
              error={this.state.error}
              onHandle={this.errorHandler}
            />
            <ProjectFeedEdit
              clients={this.state.totalCustomers}
              craftsmen={this.state.totalCraftsmen}
              editing={this.state.isEditing}
              enableEdit={this.state.enableEdit}
              selectedProject={this.state.editProject}
              loading={this.state.editLoading}
              onCancelEdit={this.cancelEditHandler}
              onFinishEdit={this.finishEditHandler}
              startSelector={this.state.startSelector}
              newListOfCraftsmen={this.state.newListOfCraftsmen}
              newListOfCustomers={this.state.newListOfCustomers}
            />
            <Card
              className='card'
              title='Alle projekter'
              buttonText='Opret projekt'
              buttonOnClick={this.newProjectHandler}
            >
              <div style={{ textAlign: 'center' }}>
                Der er ingen projekter at vise.
              </div>
            </Card>
          </Fragment>
        </div>
      );
    }
    return (
      <div>
        <div className='notification_projects'>
          <h2 className='dashboard-welcome'>Projekter</h2>
          <div className='dashboard_icons'>
            <div
              className='notificationBell'
              onClick={this.handleNotificationsList}
            >
              {this.state.openList && this.state.listEmpty ? (
                <div></div>
              ) : (
                <div className='notificationBell_circle'>
                  {this.props.totalNotifications.length}
                </div>
              )}
              <BellLogo
                width='30'
                active={this.state.bellActive ? true : false}
                animate={this.state.bellRing ? true : false}
                count={9}
                color='#C5C7CD'
              />
              {this.state.openList && (
                <div class='dropdown'>
                  <h3 className='dropdown_headline'>Dine notifikationer</h3>
                  <ul>
                    {this.props.totalNotifications.length === 0 ? (
                      <li>{messageEmpty}</li>
                    ) : (
                      this.props.totalNotifications.map((item, i) => (
                        <li key={i}>
                          {item.id.title}
                          <div>{item.id.text}</div>
                        </li>
                      ))
                    )}
                  </ul>
                </div>
              )}
            </div>
            <NavLink className='chatLogo' to='/customer_service_chats' exact>
              <ChatLogo />
            </NavLink>
            <div className='dashboard_indicator' />
            <div>
              <h2 style={{ paddingRight: '12px' }} class='dashboard-user-name'>
                {this.props.role === 'SuperAdmin' ? localStorage.firstname : localStorage.companyName}
              </h2>
            </div>
            <img
              class='profile_icon'
              src='https://cdn1.iconfinder.com/data/icons/avatars-1-5/136/51-512.png'
            />
            <img />
          </div>
        </div>
        <Fragment>
          <ErrorHandler error={this.state.error} onHandle={this.errorHandler} />
          <ProjectFeedEdit
            clients={this.state.totalCustomers}
            craftsmen={this.state.totalCraftsmen}
            editing={this.state.isEditing}
            enableEdit={this.state.enableEdit}
            selectedProject={this.state.editProject}
            loading={this.state.editLoading}
            onCancelEdit={this.cancelEditHandler}
            onFinishEdit={this.finishEditHandler}
            startSelector={this.state.startSelector}
            newListOfCraftsmen={this.state.newListOfCraftsmen}
            newListOfCustomers={this.state.newListOfCustomers}
          />
          <Card
            className='card'
            title='Alle projekter'
            buttonText='Tilføj +'
            buttonOnClick={this.newProjectHandler}
          >
            <Table
              action={() => this.checkValue()}
              tableClass='test'
              headings={headings}
              rows={this.renderTableData()}
              changeRoute={this.routeChangeProject}
            />
          </Card>
        </Fragment>
      </div>
    );
  }
}

export default ProjectFeed;
