import React, { Component, Fragment, Form , } from 'react';

import Backdrop from '../Backdrop/Backdrop';
import Modal from '../Modal/Modal';
import Input from '../Form/Input/Input';
import DatePicker from 'react-datepicker';
import { required, length, email } from '../../util/validators';
import { generateBase64FromImage } from '../../util/image';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import moment from 'moment';

const signupForm = {
    email: {
      value: '',
      valid: false,
      touched: false,
      validators: [required, email]
    },
    role: {
      value: 'Craftsman',
      valid: true,
      touched: true,
      validators: [required]
    },
    firstname: {
      value: '',
      valid: false,
      touched: false,
      validators: [required]
    },
    lastname: {
      value: '',
      valid: false,
      touched: false,
      validators: [required]
    },
    phone: {
      value: '',
      valid: false,
      touched: false,
      validators: [required]
    }
  }


class CraftsmenEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      clients: '',
      _id: '',
      clientChoice: '',
    signupForm: signupForm,
    formIsValid: false,
  };
}


  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.editing &&
      prevProps.editing !== this.props.editing &&
      prevProps.selectedUser !== this.props.selectedUser
    ) {
      const signupForm = {
        email: {
          ...prevState.signupForm.email,
          value: this.props.selectedUser.email,
          valid: true
        },
        role: {
          ...prevState.signupForm.role,
          value: this.props.selectedUser.role,
          valid: true
        },
        firstname: {
          ...prevState.signupForm.firstname,
          value: this.props.selectedUser.firstname,
          valid: true
        },
        lastname: {
          ...prevState.signupForm.lastname,
          value: this.props.selectedUser.lastname,
          valid: true
        },
        phone: {
          ...prevState.signupForm.phone,
          value: this.props.selectedUser.phone,
          valid: true
        },
        
      };
      this.setState({ signupForm: signupForm, formIsValid: true });
    }
  }

  inputChangeHandler = (input, value) => {
    this.setState(prevState => {
      let isValid = true;
      for (const validator of prevState.signupForm[input].validators) {
        isValid = isValid && validator(value);
      }
      const updatedForm = {
        ...prevState.signupForm,
        [input]: {
          ...prevState.signupForm[input],
          valid: isValid,
          value: value
        }
      };
      let formIsValid = true;
      for (const inputName in updatedForm) {
        formIsValid = formIsValid && updatedForm[inputName].valid;
      }
      return {
        signupForm: updatedForm,
        formIsValid: formIsValid
      };
    });
  };

  inputBlurHandler = input => {
    this.setState(prevState => {
      return {
        signupForm: {
          ...prevState.signupForm,
          [input]: {
            ...prevState.signupForm[input],
            touched: true
          }
        }
      };
    });
  };

  cancelUserChangeHandler = () => {
    this.setState({
      signupForm: signupForm,
      formIsValid: false
    });
    this.props.onCancelEdit();
  };

  acceptUserChangeHandler = () => {
    if (this.state.errors === null) {
    const user = {
      email: this.state.signupForm.email.value,
      role: this.state.signupForm.role.value,
      firstname: this.state.signupForm.firstname.value,
      lastname: this.state.signupForm.lastname.value,
      phone: this.state.signupForm.phone.value,
    };
    this.props.onFinishEdit(user);
    this.setState({
      signupForm: signupForm,
      formIsValid: false,
    });
  } else {
    alert("udfyld venligst alle felterne korrekt.")
  }
  };

  render() {
    return this.props.editing ? (
      <Fragment>
        <Backdrop onClick={this.cancelUserChangeHandler} />
        <Modal
          title="Håndværker"
          acceptEnabled={this.state.formIsValid}
          onCancelModal={this.cancelUserChangeHandler}
          onAcceptModal={this.acceptUserChangeHandler}
          isLoading={this.props.loading}
          showCancelButton={true}
        >
          <form >
                <Input
                    id="email"
                    label="Email"
                    placeholder="james@example.com"
                    type="email"
                    control="input"
                    onChange={this.inputChangeHandler}
                    onBlur={this.inputBlurHandler.bind(this, 'email')}
                    value={this.state.signupForm['email'].value}
                    valid={this.state.signupForm['email'].valid}
                    touched={this.state.signupForm['email'].touched}
                />
                 <Input
                    id="firstname"
                    label="Fornavn"
                    placeholder="Per"
                    type="firstname"
                    control="input"
                    onChange={this.inputChangeHandler}
                    onBlur={this.inputBlurHandler.bind(this, 'firstname')}
                    value={this.state.signupForm['firstname'].value}
                    valid={this.state.signupForm['firstname'].valid}
                    touched={this.state.signupForm['firstname'].touched}
                />
                <Input
                    id="lastname"
                    label="Efternavn"
                    placeholder="Hansen"
                    type="lastname"
                    control="input"
                    onChange={this.inputChangeHandler}
                    onBlur={this.inputBlurHandler.bind(this, 'lastname')}
                    value={this.state.signupForm['lastname'].value}
                    valid={this.state.signupForm['lastname'].valid}
                    touched={this.state.signupForm['lastname'].touched}
                />
                <Input
                    id="phone"
                    label="Telefonnummer"
                    placeholder="ex. 11223344"
                    type="phone"
                    control="input"
                    onChange={this.inputChangeHandler}
                    onBlur={this.inputBlurHandler.bind(this, 'phone')}
                    value={this.state.signupForm['phone'].value}
                    valid={this.state.signupForm['phone'].valid}
                    touched={this.state.signupForm['phone'].touched}
                />
                <Input
                    id="role"
                    type="hidden"
                    onBlur={this.inputBlurHandler.bind(this, 'role')}
                    value={this.state.signupForm['role'].value}
                    valid={this.state.signupForm['role'].valid}
                    touched={this.state.signupForm['role'].touched}
                />
          </form>
        </Modal>
      </Fragment>
    ) : null;
  }
}

export default CraftsmenEdit;
