import firebase from 'firebase';
import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyBtPr0sqDXqZmDh9ewmhD4ZlBwV5urOdOA",
    authDomain: "mitprojekt-meew.firebaseapp.com",
    databaseURL: "https://mitprojekt-meew.firebaseio.com",
    projectId: "mitprojekt-meew",
    storageBucket: "mitprojekt-meew.appspot.com",
    messagingSenderId: "765358884027",
    appId: "1:765358884027:web:e388135222bed7132d159e",
    measurementId: "G-GYNVL7KL8J"
  };
  // Initialize Firebase
  const firebaseApp = firebase.initializeApp(firebaseConfig);
  export const auth = firebaseApp.auth();
  export const messageRef = firebaseApp.database().ref('messages');
  export const roomRef = firebaseApp.database().ref('rooms');
  export const storage = firebaseApp.storage();
  export default firebaseApp;
  