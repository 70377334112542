import React from 'react';
import { NavLink } from 'react-router-dom';
import {ReactComponent as OverViewLogo} from '../../../assets/overview_icon.svg';
import {ReactComponent as PersonLogo } from '../../../assets/person_icon.svg';
import {ReactComponent as PersonsLogo } from '../../../assets/persons_icon.svg';
import {ReactComponent as ProjectLogo } from '../../../assets/project_icon.svg';
import {ReactComponent as CalendarLogo } from '../../../assets/calendar_icon.svg';
import {ReactComponent as UnlockLogo } from '../../../assets/unlock_icon.svg';

import './NavigationItems.css';

const navItems = [
  { id: 'dashboard', text: 'Oversigt', link: '/dashboard', icon: <OverViewLogo className="logoNavbar"/>, auth: true },
  { id: 'clients', text: 'Kunder', link: '/clients', icon: <PersonLogo className="logoNavbar"/>, auth: true },
  { id: 'craftsmen', text: 'Håndværkere', link: '/craftsmen', icon: <PersonsLogo className="logoNavbar"/>, auth: true },
  { id: 'projects', text: 'Projekter', link: '/projects', icon: <ProjectLogo className="logoNavbar"/>, auth: true },
  { id: 'calender', text: 'Kalender', link: '/calender', icon: <CalendarLogo className="logoNavbar"/>, auth: true },
  { id: 'login', text: 'Login', link: '/login', icon: <OverViewLogo className="logoNavbar"/>, auth: false },

];
const navItemsAdmin = [
  { id: 'dashboard', text: 'Oversigt', link: '/dashboard', icon: <OverViewLogo className="logoNavbar"/>, auth: true },
  { id: 'clients', text: 'Kunder', link: '/clients', icon: <PersonLogo className="logoNavbar"/>, auth: true },
  { id: 'craftsmen', text: 'Virksomheder & Håndværkere', link: '/craftsmen', icon: <PersonsLogo className="logoNavbar"/>, auth: true },
  { id: 'projects', text: 'Projekter', link: '/projects', icon: <ProjectLogo className="logoNavbar"/>, auth: true },
  { id: 'login', text: 'Login', link: '/login', icon: <OverViewLogo className="logoNavbar"/>, auth: false },
  { id: 'signup', text: 'Opret license', link: '/signup', icon: <UnlockLogo className="logoNavbar"/>, auth: true },
];


const navigationItems = props => [
  ...navItems.filter(item => props.role != 'SuperAdmin' && item.auth === props.isAuth).map(item => (
    <li
      key={item.id}
      className={['navigation-item', props.mobile ? 'mobile' : ''].join(' ')}
    >
      <NavLink to={item.link} exact onClick={props.onChoose}>
      <div className="indicator"/>
      <div className="bar-item"> 
        {item.icon}
      </div>
      <div className="bar-item"> 
        {item.text}
      </div>
      </NavLink>
    </li>
  )),
  ...navItemsAdmin.filter(item => props.role === 'SuperAdmin' && item.auth === props.isAuth).map(item => (
    <li
      key={item.id}
      className={['navigation-item', props.mobile ? 'mobile' : ''].join(' ')}
    >
      <NavLink to={item.link} exact onClick={props.onChoose}>
      <div className="indicator"/>
      <div className="bar-item"> 
        {item.icon}
      </div>
      <div className="bar-item"> 
        {item.text}
      </div>
      </NavLink>
    </li>
  )),
  props.isAuth && (
    <li className="navigation-item" key="logout">
      <button onClick={() => {props.onLogout()}}>Log ud</button>
    </li>
  )
];

export default navigationItems;
