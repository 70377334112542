import React, { Component, Fragment } from 'react';

import Backdrop from '../Backdrop/Backdrop';
import Modal from '../Modal/Modal';
import Input from '../Form/Input/Input';
import { required, length } from '../../util/validators';
import { generateBase64FromImage } from '../../util/image';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TASK_FORM = {
  title: {
    value: '',
    valid: false,
    touched: false,
    validators: [required, length({ min: 5 })]
  },
  description: {
    value: '',
    valid: false,
    touched: false,
    validators: [required, length({ min: 5 })]
  },
  craftsmen: {
    value: '',
    valid: true,
    touched: true,
    validators: [required]
  },
  milestone: {
    value: '',
    valid: true,
    touched: true,
    validators: [required]
  },
  startDate: {
    value: new Date(),
    selected: new Date(),
    valid: false,
    touched: false,
    validators: [required]
  },
  deadlineDate: {
    value: new Date(),
    selected: new Date(),
    valid: false,
    touched: false,
    validators: [required]
  }
};

class TaskFeedEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      clients: '',
      _id: '',
      clientChoice: '',
    taskForm: TASK_FORM,
    newListOfCraftsmen: this.props.newListOfCraftsmen,
    selectedOption: '',
    selectedMilestone: {value: 0, label: "Denne opgave skal ikke tilknyttes en milepæl!"},
    newListOfMilestones: this.props.newListOfMilestones,
    formIsValid: false,
    startRendering: false,
    generatedMilestonesList: '',
  };
  
}

componentDidMount() {
  this.generateMilestoneList()
}

   componentDidUpdate(prevProps, prevState) {
    if (
      this.props.editing &&
      prevProps.editing !== this.props.editing &&
      prevProps.selectedTask !== this.props.selectedTask
    ) {
      this.generateMilestoneList()
      this.setState({
        selectedMilestone: this.props.newListOfMilestones ? this.props.newListOfMilestones : [{label: "Denne opgave skal ikke tilknyttes en milepæl", value: 0}]
      })
      let taskInfo = '';
      let milestoneTask = '';
      try {
        taskInfo = this.props.ListOfAllTasks.find(task => task._id === this.props.selectedTask._id);
      }catch (err) {
        throw new Error(err)
      }
      try {
        if (this.props.selectedTask.milestone._id !== undefined) {
          milestoneTask = this.props.milestonesTasks.find(milestone => milestone._id === this.props.selectedTask.milestone._id);
        }
      } catch (err) {
        milestoneTask = {_id: 0, title: "Denne opgave skal ikke tilknyttes en milepæl!"};
      }
      const taskForm = {
        title: {
          ...prevState.taskForm.title,
          value: this.props.selectedTask.title,
          valid: true
        },
        description: {
          ...prevState.taskForm.description,
          value: this.props.selectedTask.description,
          valid: true
        },
        craftsmen: {
          ...prevState.taskForm.craftsmen,
          value: this.state.selectedOption ? this.state.selectedOption.map(craftsman => craftsman.value) : taskInfo.craftsmen.map(craftsman => craftsman._id),
          label: this.state.selectedOption ? this.state.selectedOption : taskInfo.craftsmen.map(craftsman => craftsman.firstname + ` ` + craftsman.lastname + ` ` ),
          valid: true
        },
        milestone: {
          ...prevState.taskForm.milestone,
          value: this.state.selectedMilestone ? this.state.selectedMilestone : milestoneTask._id,
          label: this.state.selectedMilestone ? this.state.selectedMilestone : milestoneTask.title,
          valid: true
        },
        startDate: {
          ...prevState.taskForm.startDate,
          value: moment(taskInfo.startDate).format('MM/DD/YYYY'),
          valid: true
        },
        deadlineDate: {
          ...prevState.taskForm.deadlineDate,
          value: moment(taskInfo.deadlineDate).format('MM/DD/YYYY'),
          valid: true
        },
      };
      this.setState({ 
        taskForm: taskForm, 
        formIsValid: true, 
        startRendering: true });
    }
  }

  taskInputChangeHandler = (input, value, files) => {
    let errors = {};
    if (value === "Vælg én eller flere håndværkere") {
      errors["Kunde"] = "Vælg venlist en håndværker fra listen";
      this.setState({ formIsValid: false, errors: errors })
    } else {
      this.setState({ errors: null })
    }
    if (files) {
      generateBase64FromImage(files[0])
        .then(b64 => {
          this.setState({ imagePreview: b64 });
        })
        .catch(e => {
          this.setState({ imagePreview: null });
        });
    }
    this.setState(prevState => {
      let isValid = true;
      let isStartDateValid = true;
      for (const validator of prevState.taskForm[input].validators) {
        isValid = isValid && validator(value);
      }

      if(input == "deadlineDate"){
        isValid = moment(value).isSameOrAfter(this.state.taskForm.startDate.value);
        if(!isValid){
          toast.error('Slutdatoen kan ikke være før startdatoen', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        }
        for (const validator of prevState.taskForm["startDate"].validators) {
          isStartDateValid = validator(value);
        }
      } else if(input == "startDate"){
        isValid = moment(value).isSameOrBefore(this.state.taskForm.deadlineDate.value);
        if(!isValid){
          toast.error('Startdatoen kan ikke være efter slutdatoen', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        }
      }
      
      let updatedForm;
      if(input == "deadlineDate"){
        updatedForm = {
          ...prevState.taskForm,
          [input]: {
            ...prevState.taskForm[input],
            valid: isValid,
            value: files ? files[0] : value
          },
          "deadlineDate": {
            ...prevState.taskForm["deadlineDate"],
            valid: isStartDateValid,
            value: value
          },
        };
      } else {
        updatedForm = {
          ...prevState.taskForm,
          [input]: {
            ...prevState.taskForm[input],
            valid: isValid,
            value: files ? files[0] : value
          }
        };
      }
      let formIsValid = true;
      for (const inputName in updatedForm) {
        formIsValid = formIsValid && updatedForm[inputName].valid;
      }
      return {
        taskForm: updatedForm,
        formIsValid: formIsValid
      };
    });
  };

  inputBlurHandlerSelected = craftsmen => {
    this.setState(prevState => {
      return {
        projectForm: {
          ...prevState.taskForm,
          [craftsmen]: {
            ...prevState.taskForm[craftsmen],
            touched: true
          }
        }
      };
    });
  };

  inputBlurHandler = input => {
    this.setState(prevState => {
      return {
        taskForm: {
          ...prevState.taskForm,
          [input]: {
            ...prevState.taskForm[input],
            touched: true
          }
        }
      };
    });
  };

  cancelTaskChangeHandler = () => {
    this.setState({
        taskForm: TASK_FORM,
      formIsValid: false,
      selectedMilestone: [{label: "Denne opgave skal ikke tilknyttes en milepæl", value: 0}],
    });
    this.props.onCancelEdit();
  };

  acceptTaskChangeHandler = () => {
    let checkList = this.state.selectedOption ? this.state.selectedOption : this.state.taskForm.craftsmen;
    let milestoneChoice = this.state.taskForm.milestone;
    if (this.state.taskForm.milestone.value === 0) {
      try {
        milestoneChoice = this.props.milestonesTasks.find(milestone => milestone._id === this.state.selectedMilestone.value);
      } catch (err) {
        milestoneChoice = this.state.taskForm.milestone;
      }
    }
    if (this.props.enableEdit === true) {

      if (checkList === '') {
        checkList = this.props.selectedTask.assignees.map(( craftsman, index ) => {
          const { _id } = craftsman
          return {
            _id: _id,
          }
        })
      }
      const task = {
        title: this.state.taskForm.title.value,
        description: this.state.taskForm.description.value,
        milestone: milestoneChoice.value ? milestoneChoice.value : this.state.taskForm.milestone.value,
        craftsmen: checkList ? checkList : this.state.taskForm.craftsmen.value.map(( craftsman, index ) => {
          const { value } = craftsman
          return {
            _id: value,
          }
        }),
        startDate: this.state.taskForm.startDate.value,
        deadlineDate: this.state.taskForm.deadlineDate.value
      };
      this.props.onFinishEdit(task);
      this.setState({
          taskForm: TASK_FORM,
          selectedOption: '',
          selectedMilestone: '',
          formIsValid: false,
      });
     }
    else if (checkList !== null && checkList.length !== 0)  {
      if (milestoneChoice === "" || milestoneChoice === undefined || milestoneChoice.label === "Denne opgave skal ikke tilknyttes en milepæl!") {
        const listOfCraftsmen = this.state.selectedOption ? this.state.selectedOption.map(( craftsman, index ) => {
          const { value } = craftsman
          return {
            _id: value,
          }
        }): this.state.taskForm.craftsmen.value ;
          const task = {
            title: this.state.taskForm.title.value,
            description: this.state.taskForm.description.value,
            milestone: this.state.taskForm.milestone.label ? this.state.taskForm.milestone.label : milestoneChoice.label,
            craftsmen: listOfCraftsmen,
            startDate: this.state.taskForm.startDate.value,
            deadlineDate: this.state.taskForm.deadlineDate.value
          };
            this.props.onFinishEdit(task);
            this.setState({
                taskForm: TASK_FORM,
                selectedOption: '',
                selectedMilestone: '',
                formIsValid: false,
            });
    } else if (this.state.selectedMilestone !== '') {
    try {
      const listOfCraftsmen = this.state.selectedOption ? this.state.selectedOption.map(( craftsman, index ) => {
        const { value } = craftsman
        return {
          _id: value,
        }
    }) : this.state.taskForm.craftsmen.value;
    if (listOfCraftsmen === "") {
      toast.error("Udfyld venligst alle felterne korrekt.", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    } else {
    const task = {
      title: this.state.taskForm.title.value,
      description: this.state.taskForm.description.value,
      milestone: this.state.selectedMilestone[0].value,
      craftsmen: listOfCraftsmen,
      startDate: this.state.taskForm.startDate.value,
      deadlineDate: this.state.taskForm.deadlineDate.value
    };
    this.props.onFinishEdit(task);
    }
    this.setState({
        taskForm: TASK_FORM,
        selectedOption: '',
        selectedMilestone: '',
        formIsValid: false,
        });
    } catch (err) {
      const listOfCraftsmen = this.state.selectedOption ? this.state.selectedOption.map(( craftsman, index ) => {
        const { value } = craftsman
        return {
          _id: value,
        }
    }) : this.state.taskForm.craftsmen.value;
    try {
      milestoneChoice = this.props.milestonesTasks.find(milestone => milestone._id === this.state.selectedMilestone.value);
      if (milestoneChoice === undefined) {
        milestoneChoice = {value: 0, label: "Denne opgave skal ikke tilknyttes en milepæl!"}
      }
      this.setState({
        selectedMilestone: milestoneChoice
      })
    } catch (err) {
      milestoneChoice = this.props.newListOfMilestones.find(milestone => milestone.value === milestoneChoice.value)
      this.setState({
        selectedMilestone: milestoneChoice
      })
    }
    const task = {
      title: this.state.taskForm.title.value,
      description: this.state.taskForm.description.value,
      milestone: milestoneChoice._id ? milestoneChoice._id : this.state.selectedMilestone.label,
      craftsmen: listOfCraftsmen,
      startDate: this.state.taskForm.startDate.value,
      deadlineDate: this.state.taskForm.deadlineDate.value
    };
    this.props.onFinishEdit(task);
    this.setState({
        taskForm: TASK_FORM,
        selectedOption: '',
        selectedMilestone: '',
        formIsValid: false,
        });
        }
      }
    } else {
      toast.error("Udfyld venligst alle felterne korrekt.", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }
  };

  async generateMilestoneList() {
    const MilestoneList = [{value: 0, label: "Denne opgave skal ikke tilknyttes en milepæl!"}];
    try {
      await this.state.newListOfMilestones.forEach(({ value, label}) => MilestoneList.push({ value, label}));
      this.setState({
      generatedMilestonesList: MilestoneList,
      startRendering: true
    })
    } catch (err) {
      this.hiddenException(err);
    }
  }
  
  handleMilestoneChange = (selectedMilestone) => {
    this.setState({ selectedMilestone });
  }

  handleOptionChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  hiddenException(err) {
    return `${err}`;
  }

  render() {
    const MilestonesList = [{value: 0, label: "Denne opgave skal ikke tilknyttes en milepæl!"}];
    try {
      this.props.newListOfMilestones.forEach(({ value, label}) => MilestonesList.push({ value, label}));
    } catch (err) {
      this.hiddenException(err);
    }
    const newList = this.state.generatedMilestonesList;
    const CraftsmenList = this.props.newListOfCraftsmen;
    return this.props.editing ? (
      <Fragment>
        <ToastContainer />
        <Backdrop onClick={this.cancelTaskChangeHandler} />
        <Modal
          title="Opgave"
          acceptEnabled={this.state.formIsValid}
          onCancelModal={this.cancelTaskChangeHandler}
          onAcceptModal={this.acceptTaskChangeHandler}
          isLoading={this.props.loading}
          showCancelButton={true}
        >
          <form>
            <Input
              id="title"
              label="Titel"
              control="input"
              onChange={this.taskInputChangeHandler}
              onBlur={this.inputBlurHandler.bind(this, 'title')}
              valid={this.state.taskForm['title'].valid}
              touched={this.state.taskForm['title'].touched}
              value={this.state.taskForm['title'].value}
            />
            <Input
              id="description"
              label="Beskrivelse"
              control="textarea"
              rows="5"
              onChange={this.taskInputChangeHandler}
              onBlur={this.inputBlurHandler.bind(this, 'description')}
              valid={this.state.taskForm['description'].valid}
              touched={this.state.taskForm['description'].touched}
              value={this.state.taskForm['description'].value}
            />
            {/*ToDo remove when milestone are completely removed from app */}
            {/* <Input
              id="milestone"
              multiple={false}
              label="Milepæl"
              control="selectMultiple"
              onChange={this.handleMilestoneChange}
              onBlur={this.inputBlurHandler.bind(this, 'milestone')}
              valid={this.state.taskForm['milestone'].valid}
              touched={this.state.taskForm['milestone'].touched}
              value={this.state.selectedMilestone}
              placeholder={this.state.taskForm['milestone'].label}
              options={MilestonesList}
            /> */}
            <Input
              id="craftsmen"
              label="Vælg en eller flere håndværkere"
              control="selectMultiple"
              onChange={this.handleOptionChange}
              onBlur={this.inputBlurHandler.bind(this, 'craftsmen')}
              valid={this.state.taskForm['craftsmen'].valid}
              touched={this.state.taskForm['craftsmen'].touched}
              placeholder={this.state.taskForm['craftsmen'].label}
              value={this.state.selectedOption}
              options={CraftsmenList}
              isMulti={true}
            />
            <div className="datePickerCustom">
            <Input
              id="startDate"
              label="Vælg start dato"
              control="startDate"
              onChange={this.taskInputChangeHandler}
              onBlur={this.inputBlurHandler.bind(this, 'startDate')}
              valid={this.state.taskForm['startDate'].valid}
              touched={this.state.taskForm['startDate'].touched}
              value={this.state.taskForm['startDate'].value}
              selected={this.state.taskForm['startDate'].selected}
            />
            </div>
            <div className="datePickerCustom">
            <Input
              id="deadlineDate"
              label="Vælg slut dato"
              control="deadlineDate"
              onChange={this.taskInputChangeHandler}
              onBlur={this.inputBlurHandler.bind(this, 'deadlineDate')}
              valid={this.state.taskForm['deadlineDate'].valid}
              touched={this.state.taskForm['deadlineDate'].touched}
              value={this.state.taskForm['deadlineDate'].value}
              selected={this.state.taskForm['deadlineDate'].selected}
            />
            </div>
          </form>
        </Modal>
      </Fragment>
    ) : null;
  } 
}


export default TaskFeedEdit;
