import React, { Component } from 'react';

import Input from '../../components/Form/Input/Input';
import Button from '../../components/Button/Button';
import { required, length, email } from '../../util/validators';
import Auth from './Auth';
import { ReactComponent as Logo } from '../../assets/logo-big.svg'

class Login extends Component {
  state = {
    loginForm: {
      email: {
        value: '',
        valid: false,
        touched: false,
        validators: [required, email]
      },
      password: {
        value: '',
        valid: false,
        touched: false,
        validators: [required, length({ min: 8 })]
      },
      formIsValid: false
    },
    forgotPasswordForm: {
      email: {
        value: '',
        valid: false,
        touched: false,
        validators: [required, email]
      },
      formIsValid: false
    },
    formState: false,
  };

  inputChangeHandler = (input, value) => {
    this.setState(prevState => {
      let isValid = true;
      for (const validator of prevState.loginForm[input].validators) {
        isValid = isValid && validator(value);
      }
      const updatedForm = {
        ...prevState.loginForm,
        [input]: {
          ...prevState.loginForm[input],
          valid: isValid,
          value: value
        }
      };
      let formIsValid = true;
      for (const inputName in updatedForm) {
        formIsValid = formIsValid && updatedForm[inputName].valid;
      }
      return {
        loginForm: updatedForm,
        formIsValid: formIsValid
      };
    });
  };

  inputForgotPasswordChangeHandler = (input, value) => {
    this.setState(prevState => {
      let isValid = true;
      for (const validator of prevState.forgotPasswordForm[input].validators) {
        isValid = isValid && validator(value);
      }
      const updatedForm = {
        ...prevState.forgotPasswordForm,
        [input]: {
          ...prevState.forgotPasswordForm[input],
          valid: isValid,
          value: value
        }
      };
      let formIsValid = true;
      for (const inputName in updatedForm) {
        formIsValid = formIsValid && updatedForm[inputName].valid;
      }
      return {
        forgotPasswordForm: updatedForm,
        formIsValid: formIsValid
      };
    });
  };

  inputBlurHandler = input => {
    this.setState(prevState => {
      return {
        loginForm: {
          ...prevState.loginForm,
          [input]: {
            ...prevState.loginForm[input],
            touched: true
          }
        }
      };
    });
  };

  inputForgotPasswordBlurHandler = input => {
    this.setState(prevState => {
      return {
        forgotPasswordForm: {
          ...prevState.forgotPasswordForm,
          [input]: {
            ...prevState.forgotPasswordForm[input],
            touched: true
          }
        }
      };
    });
  };

  handleChange = () => {
    if (this.state.formState === false) {
      this.setState({
        formState: true
      })
    } else {
      this.setState({
        formState: false
      })
    }
  }

  render() {
    if (this.state.formState === false) {
    // Render the login form.
    return (
      <div className="login-container">
        <Logo className="logo" />
        <Auth>
          <div class='form-container'>
          <h2>Log ind på din konto</h2>
          <form
            onSubmit={e =>
              this.props.onLogin(e, {
                email: this.state.loginForm.email.value,
                password: this.state.loginForm.password.value
              })
            }
          >
            <Input
              id="email"
              label="Din e-mail"
              type="email"
              control="input"
              onChange={this.inputChangeHandler}
              onBlur={this.inputBlurHandler.bind(this, 'email')}
              value={this.state.loginForm['email'].value}
              valid={this.state.loginForm['email'].valid}
              touched={this.state.loginForm['email'].touched}
            />
            <Input
              id="password"
              label="Adgangskode"
              type="password"
              control="input"
              onChange={this.inputChangeHandler}
              onBlur={this.inputBlurHandler.bind(this, 'password')}
              value={this.state.loginForm['password'].value}
              valid={this.state.loginForm['password'].valid}
              touched={this.state.loginForm['password'].touched}
            />
            <Button design="raised" type="submit" loading={this.props.loading}>
              Log ind
            </Button>
            <div className="login-footer">
              <a href="#" onClick={() => this.handleChange()}>Har du glemt din adgangskode?</a>
            </div>
          </form>
          </div>
        </Auth>
      </div>
    )
    } else if (this.state.formState === true) {
    // Render the forgot password form.
      return (
        <div className="login-container">
          <Logo className="logo" />
          <Auth>
            <h2>Anmod on nyt kodeord</h2>
            <form
              onSubmit={e =>
                this.props.onForgotPassword(e, {
                  email: this.state.forgotPasswordForm.email.value,
                })
              }
            >
              <Input
                id="email"
                label="Din e-mail"
                type="email"
                control="input"
                onChange={this.inputForgotPasswordChangeHandler}
                onBlur={this.inputForgotPasswordBlurHandler.bind(this, 'email')}
                value={this.state.forgotPasswordForm['email'].value}
                valid={this.state.forgotPasswordForm['email'].valid}
                touched={this.state.forgotPasswordForm['email'].touched}
              />
              <Button design="raised" type="submit" loading={this.props.loading} >
                Send
              </Button>
              <div className="login-footer">
                <a href="#" onClick={() => this.handleChange()}>Tilbage til login?</a>
              </div>
            </form>
          </Auth>
        </div>
      )
    }
  }
}

export default Login;
