import React, { Component } from 'react';

import Input from '../../components/Form/Input/Input';
import Button from '../../components/Button/Button';
import Auth from '../Auth/Auth';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { required, length, email } from '../../util/validators';
import './CustomerPage.css';
import { makeStyles } from '@material-ui/core/styles';
import CustomerEdit from '../../components/CustomerEdit/CustomerEdit';
import ErrorHandler from '../../components/ErrorHandler/ErrorHandler';
import api from '../../util/config';
import Table from '../../components/Table/Table';
import Card from '../../components/Card/Card';
import Badge from '../../components/Badge/Badge';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ReactComponent as ChatLogo } from '../../assets/chat_icon.svg';
import { ReactComponent as BellLogo } from '../../assets/bell_icon.svg';
import { NavLink } from 'react-router-dom';

class CustomerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      totalCustomers: [],
      customers: [],
      editUser: null,
      usersLoading: true,
      isEditing: false,
      editLoading: false,
      selectedUser: '',
      bellActive: false,
      bellRing: false,
      notificationList: [],
      openList: false,
      listEmpty: true,
      loading: false,
      unreadNotifications: false,
      finishedLoading: false,
    };
  }

  useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }));

  async componentDidMount() {
    this.loadUsers();
  }

  startEditUserHandler = (_id) => {
    this.setState((prevState) => {
      const loadedUser = {
        ...prevState.totalCustomers.find((u) => u._id === _id),
      };
      return {
        isEditing: true,
        editUser: loadedUser,
      };
    });
  };

  newUserHandler = () => {
    this.setState({ isEditing: true });
  };

  cancelEditUserHandler = () => {
    this.setState({ isEditing: false, editUser: null });
  };

  loadUsers = () => {
    this.setState({
      totalCustomers: [],
      customers: [],
    });
    const graphqlQuery = {
      query: `
          {
            users {
              users {
                _id
                firstname
                lastname
                role
                email
                status
                phone
                company
                avatarImage
                confirmed_email
              }
              
            }
          }
          `,
    };
    // Fetch all users and select any who matches role 'Client'. Then add to new list.
    fetch(api.baseUrl, {
      method: 'POST',
      body: JSON.stringify(graphqlQuery),
      headers: {
        Authorization: 'Bearer ' + this.props.token,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        if (resData.errors && resData.errors[0].status === 422) {
          throw new Error(`${resData.errors[0].message}`);
        }
        if (resData.errors) {
          throw new Error(`${resData.errors[0].message}`);
        }
        this.setState({ customers: resData.data.users.users });
        for (let i = 0; i <= this.state.customers.length - 1; i++) {
          if (this.state.customers[i].role === 'Client') {
            if (this.props.role !== 'SuperAdmin') {
              if (this.state.customers[i].company.includes(this.props.userId)) {
                this.state.totalCustomers.push(this.state.customers[i]);
              }
            } else {
              this.state.totalCustomers.push(this.state.customers[i]);
            }
          }
        }
        this.setState({ totalCustomers: this.state.totalCustomers });
      })
      .catch(this.catchError);
    this.setState({ usersLoading: false });
  };

  catchError = (error) => {
    this.setState({ error: error });
  };

  inputChangeHandler = (input, value) => {
    this.setState((prevState) => {
      let isValid = true;
      for (const validator of prevState.signupForm[input].validators) {
        isValid = isValid && validator(value);
      }
      const updatedForm = {
        ...prevState.signupForm,
        [input]: {
          ...prevState.signupForm[input],
          valid: isValid,
          value: value,
        },
      };
      let formIsValid = true;
      for (const inputName in updatedForm) {
        formIsValid = formIsValid && updatedForm[inputName].valid;
      }
      return {
        signupForm: updatedForm,
        formIsValid: formIsValid,
      };
    });
  };

  inputBlurHandler = (input) => {
    this.setState((prevState) => {
      return {
        signupForm: {
          ...prevState.signupForm,
          [input]: {
            ...prevState.signupForm[input],
            touched: true,
          },
        },
      };
    });
  };

  finishEditUserHandler = (authData) => {
    this.setState({
      editLoading: true,
    });
    let graphqlQuery = {
      query: `
            mutation CreateNewUser($email: String!, $firstname: String!, $lastname: String, $phone: String, $role: String!, $status: String, $uId: String ) {
              createUser(userInput: {email: $email, firstname: $firstname, lastname: $lastname, phone: $phone, role: $role, status: $status, company: $uId}) {
              _id
              email
              firstname
              lastname
              phone
              role
            }
          }
          `,
      variables: {
        email: authData.email,
        firstname: authData.firstname,
        lastname: authData.lastname,
        phone: authData.phone,
        role: authData.role,
        status: 'Pending',
        uId: this.props.userId,
      },
    };
    if (this.state.editUser) {
      graphqlQuery = {
        query: `
              mutation UpdateExistingUser($userId: ID!, $firstname: String, $lastname: String, $email: String!, $phone: String, $role: String!, $status: String) {
                updateUser(id: $userId, userInput: {firstname: $firstname, lastname: $lastname, email: $email, phone: $phone, role: $role, status: $status}) {
                  _id
                  email
                  firstname
                  lastname
                }
              }
            `,
        variables: {
          userId: this.state.editUser._id,
          firstname: authData.firstname,
          lastname: authData.lastname,
          phone: authData.phone,
          email: authData.email,
          role: authData.role,
          status: 'confirmed',
        },
      };
    }
    fetch(api.baseUrl, {
      method: 'POST',
      body: JSON.stringify(graphqlQuery),
      headers: {
        Authorization: 'Bearer ' + this.props.token,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        if (resData.errors && resData.errors[0].status === 422) {
          throw new Error(`${resData.errors[0].message}`);
        }
        if (resData.errors) {
          throw new Error(`${resData.errors[0].message}`);
        }
        this.loadUsers();
        this.setState({
          editLoading: false,
          isEditing: false,
          editUser: null,
        });
      })
      .catch((err) => {
        this.setState({
          isEditing: false,
          editUser: null,
          editLoading: false,
          error: err,
        });
      });
  };

  errorHandler = () => {
    this.setState({ error: null });
  };

  deleteUserHandler = (_id) => {
    try {
      //TODO handle rolecheck and error if company_admin dont have permission to delete users
      // if (this.props.role !== 'SuperAdmin') {
      //   throw new Error(
      //     'Du har ikke mulighed for at slette denne bruger. Kontakt istedet en administrator.'
      //   );
      // } else {
        this.setState({ usersLoading: true });
        const graphqlQuery = {
          query: `
        mutation {
          deleteUser(id: "${_id}")
        }
      `,
        };
        fetch(api.baseUrl, {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + this.props.token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(graphqlQuery),
        })
          .then((res) => {
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              throw new Error(`${resData.errors[0].message}`);
            }
          })
          .catch((err) => {});
        this.setState({
          totalCustomers: [],
        });
        this.loadUsers();
      // }
    } catch (error) {
      this.setState({error: error});
    }
  };

  popup = (_id) => {
    confirmAlert({
      title: 'Slet bruger',
      message: 'Er du sikker?',
      buttons: [
        {
          label: 'Nej',
        },
        {
          label: 'Ja',
          onClick: () => this.deleteUserHandler(_id),
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };

  cancelEditHandler = () => {
    this.setState({ isEditing: false, editUser: null });
  };

  checkStatus = (status) => {
    if (status === false) {
      return (
        <p
          className='badge'
          style={{ color: '#f80031', backgroundColor: 'rgba(251,175,190,.5)' }}
        >
          {'Ikke bekræftet'}
        </p>
      );
    } else if (status === true) {
      return <p className='badge badge-confirm'>{'Bekræftet'}</p>;
    } else {
      return;
    }
  };

  renderTableData = () => {
    return this.state.totalCustomers.map((customer, index) => {
      const {
        _id,
        firstname,
        lastname,
        email,
        status,
        phone,
        avatarImage,
        confirmed_email,
      } = customer;
      return {
        key: _id,
        id: _id,
        rows: [
          {
            text: `${firstname} ${lastname}`,
            avatarImage,
            email,
            type: 'name',
          },
          { text: phone, type: 'phone' },
          { text: this.checkStatus(confirmed_email), type: 'status' },
          {
            type: 'actions',
            actions: [
              {
                icon: 'editnew',
                onClick: () => this.startEditUserHandler(_id),
              },
              { icon: 'deletenew', onClick: () => this.popup(_id) },
            ],
          },
        ],
      };
    });
  };

  handleNotificationsList = () => {
    this.setState((state) => {
      return {
        bellRing: false,
        bellActive: false,
        openList: !state.openList,
        unreadNotifications: false,
      };
    });
  };

  render() {
    const messageEmpty = ['Ingen nye notifikationer'];
    const headings = ['Navn og Email', 'Telefon', 'Status', 'Handlinger'];

    return (
      <div class='div-customerpage'>
        <div className='notification_projects'>
          <h2 className='dashboard-welcome'>Kunder</h2>
          <div className='dashboard_icons'>
            <div
              className='notificationBell'
              onClick={this.handleNotificationsList}
            >
              {this.state.openList && this.state.listEmpty ? (
                <div></div>
              ) : (
                <div className='notificationBell_circle'>
                  {this.props.totalNotifications.length}
                </div>
              )}
              <BellLogo
                width='30'
                active={this.state.bellActive ? true : false}
                animate={this.state.bellRing ? true : false}
                count={9}
                color='#C5C7CD'
              />
              {this.state.openList && (
                <div class='dropdown'>
                  <h3 className='dropdown_headline'>Dine notifikationer</h3>
                  <ul>
                    {this.props.totalNotifications.length === 0 ? (
                      <li>{messageEmpty}</li>
                    ) : (
                      this.props.totalNotifications.map((item, i) => (
                        <li key={i}>
                          {item.id.title}
                          <div>{item.id.text}</div>
                        </li>
                      ))
                    )}
                  </ul>
                </div>
              )}
            </div>
            <NavLink className='chatLogo' to='/customer_service_chats' exact>
              <ChatLogo />
            </NavLink>
            <div className='dashboard_indicator' />
            <div>
              <h2 style={{ paddingRight: '12px' }} class='dashboard-user-name'>
                {this.props.role !== 'SuperAdmin' ? localStorage.companyName : localStorage.firstname}
              </h2>
            </div>
            <img
              class='profile_icon'
              src='https://cdn1.iconfinder.com/data/icons/avatars-1-5/136/51-512.png'
            />
            <img />
          </div>
        </div>
        <ErrorHandler error={this.state.error} onHandle={this.errorHandler} />
        <CustomerEdit
          clients={this.state.totalCustomers}
          craftsmen={this.state.totalCraftsmen}
          editing={this.state.isEditing}
          loading={this.state.editLoading}
          onCancelEdit={this.cancelEditUserHandler}
          onFinishEdit={this.finishEditUserHandler}
          selectedUser={this.state.editUser}
        />
        <Card
          className='card'
          title='Alle kunder'
          buttonText='Tilføj +'
          buttonOnClick={this.newUserHandler}
        >
          <Table
            tableClass='test'
            headings={headings}
            rows={this.renderTableData()}
          />
        </Card>
      </div>
    );
  }
}

export default CustomerPage;
