import React from 'react';
import ReactDOM from 'react-dom';

import Button from '../Button/Button';
import Buton from '../Button/addUserButton';
import './Modal.css';

const modal = props =>
  ReactDOM.createPortal(
    <div className="modal">
      <header className="modal__header">
        <h1>{props.title}</h1>
      </header>
      <div className="modal__content">{props.children}</div>
      <div className="modal__actions">
      {props.showCancelButton === true &&(
        <Button design="danger" mode="flat" onClick={props.onCancelModal}>
          Annuller
        </Button>
      )}
        <Buton mode="raised" onClick={props.onAcceptModal} disabled={!props.acceptEnabled} loading={props.isLoading}>
        {props.buttonText ? props.buttonText : "Tilføj"} 
        </Buton>
      </div>
    </div>,
    document.getElementById('modal-root')
  );

export default modal;
