import React, { Component } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import './CraftsmanPage.css';
import { makeStyles } from '@material-ui/core/styles';
import ErrorHandler from '../../components/ErrorHandler/ErrorHandler';
import CraftsmenEdit from '../../components/CraftsmenEdit/CraftsmenEdit';
import api from '../../util/config';
import Table from '../../components/Table/Table';
import Card from '../../components/Card/Card';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import CompanyEdit from '../../components/CompanyEdit/CompanyEdit';
import { ReactComponent as ChatLogo } from '../../assets/chat_icon.svg';
import { ReactComponent as BellLogo } from '../../assets/bell_icon.svg';
import { NavLink } from 'react-router-dom';

class CraftsmanPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      totalCraftsmen: [],
      totalCompanies: [],
      craftsmen: [],
      editUser: null,
      usersLoading: true,
      isEditing: false,
      isEditingCompany: false,
      editLoading: false,
      bellActive: false,
      bellRing: false,
      notificationList: [],
      openList: false,
      listEmpty: true,
      loading: false,
      unreadNotifications: false,
      finishedLoading: false,
    };
  }

  useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }));

  async componentDidMount() {
    this.loadUsers();
  }
  loadUsers = () => {
    this.setState({
      totalCraftsmen: [],
      craftsmen: [],
      totalCompanies: [],
    });
    const graphqlQuery = {
      query: `
          {
            users {
              users {
                _id
                firstname
                lastname
                avatarImage
                role
                jobtitle
                email
                address
                cvr
                companyUrl
                companyName
                status
                phone
                company
                confirmed_email
              }
              
            }
          }
          `,
    };
    // Fetch all users and select any who matches role 'Craftsman'. Then add to new list.
    fetch(api.baseUrl, {
      method: 'POST',
      body: JSON.stringify(graphqlQuery),
      headers: {
        Authorization: 'Bearer ' + this.props.token,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        if (resData.errors && resData.errors[0].status === 422) {
          throw new Error(`${resData.errors[0].message}`);
        }
        if (resData.errors) {
          throw new Error(`${resData.errors[0].message}`);
        }
        this.setState({ craftsmen: resData.data.users.users });
        for (let i = 0; i <= this.state.craftsmen.length - 1; i++) {
          if (this.state.craftsmen[i].role === 'Craftsman') {
            if (this.props.role !== 'SuperAdmin') {
              if (this.state.craftsmen[i].company.includes(this.props.userId)) {
                this.state.totalCraftsmen.push(this.state.craftsmen[i]);
              }
            } else {
              this.state.totalCraftsmen.push(this.state.craftsmen[i]);
            }
          } else if (this.state.craftsmen[i].role === 'Company') {
            this.state.totalCompanies.push(this.state.craftsmen[i]);
          }
        }
        this.setState({
          totalCraftsmen: this.state.totalCraftsmen,
          totalCompanies: this.state.totalCompanies,
        });
      })
      .catch(this.catchError);
  };

  catchError = (error) => {
    this.setState({ error: error });
  };

  newUserHandler = () => {
    this.setState({ isEditing: true });
  };

  startEditUserHandler = (_id) => {
    this.setState((prevState) => {
      const loadedUser = {
        ...prevState.totalCraftsmen.find((u) => u._id === _id),
      };
      return {
        isEditing: true,
        isEditingCompany: false,
        editUser: loadedUser,
      };
    });
  };

  startEditCompanyHandler = (_id) => {
    this.setState((prevState) => {
      const loadedCompany = {
        ...prevState.totalCompanies.find((u) => u._id === _id),
      };
      return {
        isEditing: false,
        isEditingCompany: true,
        editUser: loadedCompany,
      };
    });
  };

  catchError = (error) => {
    this.setState({ error: error });
  };

  inputChangeHandler = (input, value) => {
    this.setState((prevState) => {
      let isValid = true;
      for (const validator of prevState.signupForm[input].validators) {
        isValid = isValid && validator(value);
      }
      const updatedForm = {
        ...prevState.signupForm,
        [input]: {
          ...prevState.signupForm[input],
          valid: isValid,
          value: value,
        },
      };
      let formIsValid = true;
      for (const inputName in updatedForm) {
        formIsValid = formIsValid && updatedForm[inputName].valid;
      }
      return {
        signupForm: updatedForm,
        formIsValid: formIsValid,
      };
    });
  };

  inputBlurHandler = (input) => {
    this.setState((prevState) => {
      return {
        signupForm: {
          ...prevState.signupForm,
          [input]: {
            ...prevState.signupForm[input],
            touched: true,
          },
        },
      };
    });
  };

  finishEditUserHandler = (authData) => {
    this.setState({ editLoading: true });
    let graphqlQuery = {
      query: `
            mutation CreateNewUser($email: String!, $firstname: String!, $lastname: String, $phone: String, $role: String!, $uId: String) {
              createUser(userInput: {email: $email, firstname: $firstname, lastname: $lastname, phone: $phone, role: $role, company: $uId }) {
              _id
              email
              firstname
              lastname
              phone
              role
            }
          }
          `,
      variables: {
        email: authData.email,
        firstname: authData.firstname,
        lastname: authData.lastname,
        phone: authData.phone,
        role: 'Craftsman',
        status: 'Pending',
        uId: this.props.userId,
      },
    };
    if (this.state.editUser) {
      graphqlQuery = {
        query: `
              mutation UpdateExistingUser($userId: ID!, $firstname: String, $lastname: String, $phone: String, $role: String!, $status: String, $email: String!) {
                updateUser(id: $userId, userInput: {firstname: $firstname, lastname: $lastname, phone: $phone, role: $role, status: $status, email: $email}) {
                  _id
                  email
                  firstname
                  lastname
                  phone
                  address
                }
              }
            `,
        variables: {
          userId: this.state.editUser._id,
          firstname: authData.firstname,
          lastname: authData.lastname,
          role: 'Craftsman',
          phone: authData.phone,
          status: 'Aktiv',
          email: authData.email,
        },
      };
    }
    if (this.state.editUser && this.state.isEditingCompany) {
      graphqlQuery = {
        query: `
              mutation UpdateExistingCompany($userId: ID!, $firstname: String, $phone: String, $cvr: String, $companyName: String, $companyUrl: String, $address: String, $role: String!, $status: String, $email: String!) {
                updateUser(id: $userId, userInput: {firstname: $firstname, phone: $phone, cvr: $cvr, companyName: $companyName, companyUrl: $companyUrl, address: $address, role: $role, status: $status, email: $email}) {
                  _id
                  email
                  firstname
                  phone
                  address
                }
              }
            `,
        variables: {
          userId: this.state.editUser._id,
          firstname: authData.firstname,
          role: 'Company',
          companyName: authData.companyName,
          phone: authData.phone,
          cvr: authData.cvr,
          companyUrl: authData.companyUrl,
          address: authData.address,
          status: 'Aktiv',
          email: authData.email,
        },
      };
    }
    fetch(api.baseUrl, {
      method: 'POST',
      body: JSON.stringify(graphqlQuery),
      headers: {
        Authorization: 'Bearer ' + this.props.token,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        if (resData.errors && resData.errors[0].status === 422) {
          throw new Error(`${resData.errors[0].message}`);
        }
        if (resData.errors) {
          throw new Error(`${resData.errors[0].message}`);
        }
        this.loadUsers();
        this.setState({
          editLoading: false,
          isEditing: false,
          isEditingCompany: false,
          editUser: null,
        });
      })
      .catch((err) => {
        this.setState({
          isEditing: false,
          isEditingCompany: false,
          editUser: null,
          editLoading: false,
          error: err,
        });
      });
  };

  errorHandler = () => {
    this.setState({ error: null });
  };

  deleteUserHandler = (_id) => {
    try {
      if (this.props.role !== 'SuperAdmin') {
        throw new Error(
          'Du har ikke mulighed for at slette denne bruger. Kontakt istedet en administrator.'
        );
      } else {
        this.setState({ usersLoading: true });
        const graphqlQuery = {
          query: `
        mutation {
          deleteUser(id: "${_id}")
        }
      `,
        };
        fetch(api.baseUrl, {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + this.props.token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(graphqlQuery),
        })
          .then((res) => {
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              throw new Error(`${resData.errors[0].message}`);
            }
          })
          .catch((err) => {});
        this.setState({ totalCraftsmen: [] });
        this.loadUsers();
      }
    } catch (error) {
      return {
        Error: error,
      };
    }
  };

  checkStatus = (status) => {
    if (status === false) {
      return (
        <p
          className='badge'
          style={{ color: '#f80031', backgroundColor: 'rgba(251,175,190,.5)' }}
        >
          {'Ikke bekræftet'}
        </p>
      );
    } else if (status === true) {
      return <p className='badge badge-confirm'>{'Bekræftet'}</p>;
    } else {
      return;
    }
  };

  popup = (_id) => {
    confirmAlert({
      title: 'Slet bruger',
      message: 'Er du sikker?',
      buttons: [
        {
          label: 'Nej',
        },
        {
          label: 'Ja',
          onClick: () => this.deleteUserHandler(_id),
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };

  cancelEditUserHandler = () => {
    this.setState({
      isEditing: false,
      isEditingCompany: false,
      editUser: null,
    });
  };

  renderTableData() {
    if (this.props.role !== 'SuperAdmin') {
      return this.state.totalCraftsmen.map((craftsman, index) => {
        const { _id, firstname, lastname, email, status, phone, companyUrl, address, cvr, jobtitle, avatarImage, confirmed_email } = craftsman
        return {
          key: _id,
          id: _id,
          rows: [
            { text: `${firstname} ${lastname}`, email, type: 'name', avatarImage},
          { text: jobtitle, typeOf: 'jobtitel', type: 'virksomheder' },
          { text: phone, typeOf: 'phone', type:'virksomheder' },
          { text: this.checkStatus(confirmed_email), type: "status", typeOf: 'virksomheder' },
          { type: "actions", actions: [ 
            { icon: 'editnew', onClick: () => this.startEditUserHandler(_id) }, 
            { icon: 'deletenew', onClick: () => this.popup(_id) }
            ]},
          ]
        }
      });
    } else {
      return this.state.totalCompanies.map((company, index) => {
        let lastName = ''
        const {
          _id,
          firstname,
          lastname,
          email,
          status,
          phone,
          companyUrl,
          address,
          cvr,
          companyName,
          avatarImage,
          confirmed_email,
        } = company;
        if(lastname === null){
          lastName = '';
        }else{
          lastName = lastname
        }
        return {
          key: _id,
          id: _id,
          rows: [
            {
              text: companyName,
              email: `CVR: ${cvr}`,
              avatarImage,
              type: 'name',
            },
            {
              text: address,
              email: email,
              type: 'virksomheder',
              typeOf: 'address',
            },
            {
              text: `${firstname} ${lastName}`,
              email: phone,
              type: 'virksomheder',
              typeOf: 'name',
            },
            { text: this.checkStatus(confirmed_email), type: 'status' },
            {
              type: 'actions',
              actions: [
                {
                  icon: 'editnew',
                  onClick: () => this.startEditCompanyHandler(_id),
                },
                { icon: 'deletenew', onClick: () => this.popup(_id) },
              ],
            },
          ],
        };
      });
    }
  }

  renderCraftsmanTableData() {
    return this.state.totalCraftsmen.map((craftsman, index) => {
      const {
        _id,
        firstname,
        lastname,
        email,
        status,
        phone,
        companyUrl,
        address,
        cvr,
        jobtitle,
        avatarImage,
        confirmed_email,
      } = craftsman;
      return {
        key: _id,
        id: _id,
        rows: [
          {
            text: `${firstname} ${lastname}`,
            email,
            type: 'name',
            avatarImage,
          },
          { text: jobtitle, typeOf: 'jobtitel', type: 'virksomheder' },
          { text: phone, typeOf: 'phone', type: 'virksomheder' },
          {
            text: this.checkStatus(confirmed_email),
            type: 'status',
            typeOf: 'virksomheder',
          },
          {
            type: 'actions',
            actions: [
              {
                icon: 'editnew',
                onClick: () => this.startEditUserHandler(_id),
              },
              { icon: 'deletenew', onClick: () => this.popup(_id) },
            ],
          },
        ],
      };
    });
  }

  handleNotificationsList = () => {
    this.setState((state) => {
      return {
        bellRing: false,
        bellActive: false,
        openList: !state.openList,
        unreadNotifications: false,
      };
    });
  };

  render() {
    const messageEmpty = ["Ingen nye notifikationer"]
    if (this.props.role !== 'SuperAdmin') {
    const headings = [
      "Navn og Email",
      "Titel",
      "Telefon",
      "Status",
      "Handlinger",
    ]

    return (
        <div>
          <div className="notification_projects">
          <h2 className="dashboard-welcome">Håndværkere</h2>
          <div className="dashboard_icons">
            <div 
                className="notificationBell" 
                onClick={this.handleNotificationsList}>
                  {this.state.openList && this.state.listEmpty ? 
                  <div></div> :
                  <div className="notificationBell_circle">
                  {this.props.totalNotifications.length}
                  </div>
                  }
                    <BellLogo
                      width='30' 
                      active={this.state.bellActive ? true: false} 
                      animate={this.state.bellRing ? true: false} 
                      count={9}
                    />
                  {this.state.openList && (
                  <div class="dropdown">
                  <h3 className="dropdown_headline">Dine notifikationer</h3>
                    <ul>
                      {this.props.totalNotifications.length === 0 ? <li>{messageEmpty}</li>  : this.props.totalNotifications.map((item,i) => <li key={i}>{item.id.title}<div>{item.id.text}</div></li>)}
                    </ul>
                  </div>
                  )}
                </div>
                <NavLink className="chatLogo" to='/customer_service_chats' exact>
                  <ChatLogo />
                  </NavLink>
                  <div className="dashboard_indicator"/>
                    <div>
                     <h2 style={{ paddingRight: "12px"}} class="dashboard-user-name">
                     {localStorage.companyName}
                     </h2>
                    </div>
                  <img class="profile_icon" src="https://cdn1.iconfinder.com/data/icons/avatars-1-5/136/51-512.png" /><img/>
            </div>
          </div>
            {/* <h1 id="craftsmanHeader">Håndværkere</h1> */}
            <ErrorHandler error={this.state.error} onHandle={this.errorHandler} />
              <CraftsmenEdit
                clients={this.state.totalCustomers}
                craftsmen={this.state.totalCraftsmen}
                editing={this.state.isEditing}
                loading={this.state.editLoading}
                onCancelEdit={this.cancelEditUserHandler}
                onFinishEdit={this.finishEditUserHandler}
                selectedUser={this.state.editUser}
              />
            <Card className="card" title="Liste over håndværkerer" buttonText="Tilføj +" buttonOnClick={this.newUserHandler}>
              <Table tableClass="test" headings={headings} rows={this.renderTableData()} />
            </Card>
      </div>
    );
  } else if (this.props.role === 'SuperAdmin') {
      const headings = [
        'Virksomhedsnavn',
        'Adresse',
        'Kontakt',
        'Status',
        'Handlinger',
      ];
      const craftsmenHeadings = [
        'Navn og Email',
        'Titel',
        'Telefon',
        'Status',
        'Handlinger',
      ];
      const { history } = this.props;
      return (
        <div>
          <div className='notification_projects'>
            <h2 className='dashboard-welcome'>Virksomheder og Håndværkere</h2>
            <div className='dashboard_icons'>
              <div
                className='notificationBell'
                onClick={this.handleNotificationsList}
              >
                {this.state.openList && this.state.listEmpty ? (
                  <div></div>
                ) : (
                  <div className='notificationBell_circle'>
                    {this.props.totalNotifications.length}
                  </div>
                )}
                <BellLogo
                  width='30'
                  active={this.state.bellActive ? true : false}
                  animate={this.state.bellRing ? true : false}
                  count={9}
                />
                {this.state.openList && (
                  <div class='dropdown'>
                    <h3 className='dropdown_headline'>Dine notifikationer</h3>
                    <ul>
                      {this.props.totalNotifications.length === 0 ? (
                        <li>{messageEmpty}</li>
                      ) : (
                        this.props.totalNotifications.map((item, i) => (
                          <li key={i}>
                            {item.id.title}
                            <div>{item.id.text}</div>
                          </li>
                        ))
                      )}
                    </ul>
                  </div>
                )}
              </div>
              <NavLink className='chatLogo' to='/customer_service_chats' exact>
                <ChatLogo />
              </NavLink>
              <div className='dashboard_indicator' />
              <div>
                <h2
                  style={{ paddingRight: '12px' }}
                  class='dashboard-user-name'
                >
                  {localStorage.firstname}
                </h2>
              </div>
              <img
                class='profile_icon'
                src='https://cdn1.iconfinder.com/data/icons/avatars-1-5/136/51-512.png'
              />
              <img />
            </div>
          </div>
          <ErrorHandler error={this.state.error} onHandle={this.errorHandler} />
          <CompanyEdit
            editing={this.state.isEditingCompany}
            onCancelEdit={this.cancelEditUserHandler}
            onFinishEdit={this.finishEditUserHandler}
            selectedUser={this.state.editUser}
          />
          <Card
            className='card'
            title='Alle virksomheder'
            buttonText='Tilføj +'
            buttonOnClick={() => history.push('/signup')}
          >
            <Table
              tableClass='test'
              headings={headings}
              rows={this.renderTableData()}
            />
          </Card>
          {/* <h2 className="dashboard-welcome">Håndværkere</h2> */}
          <ErrorHandler error={this.state.error} onHandle={this.errorHandler} />
          <CraftsmenEdit
            clients={this.state.totalCustomers}
            craftsmen={this.state.totalCraftsmen}
            editing={this.state.isEditing}
            loading={this.state.editLoading}
            onCancelEdit={this.cancelEditUserHandler}
            onFinishEdit={this.finishEditUserHandler}
            selectedUser={this.state.editUser}
          />
          <Card
            className='card'
            title='Alle håndværkerer'
            buttonText='Tilføj +'
            buttonOnClick={this.newUserHandler}
          >
            <Table
              tableClass='test'
              headings={craftsmenHeadings}
              rows={this.renderCraftsmanTableData()}
            />
          </Card>
        </div>
      );
    }
  }
}

export default CraftsmanPage;
