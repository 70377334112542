import React, { Component } from 'react';
import moment from 'moment';
import ErrorHandler from '../../../components/ErrorHandler/ErrorHandler';
import './CustomerServiceChat.css';
import { auth, messageRef, roomRef } from '../../../util/fire';
import api from '../../../util/config';
import CustomerServiceChatPage from './CustomerServiceChatPage/CustomerServiceChatPage';
import {ReactComponent as ChatLogo } from '../../../assets/chat_icon.svg';
import {ReactComponent as BellLogo } from '../../../assets/bell_icon.svg';
import { NavLink } from 'react-router-dom';
import ChatPanel from '../../../components/ChatPanel/ChatPanel';

class CustomerServiceChat extends Component {

    constructor(props) {
    super(props)
    this.state = {
      scrollViewHeight: 0,
      inputHeight: 0,
      user: '',
      uid: '',
      role: localStorage.role,
      userFirstname: localStorage.firstname,
      userLastname: localStorage.lastname,
      isLoggedIn: false,
      rooms: '',
      roomNames: '',
      totalRooms: '',
      projectId: this.props.match.params.projectId,
      project: '',
      assigneeIds: '',
      clientIds: '',
      selectedRoom: '',
      selectedUserFirstname: '',
      selectedUserLastname: '',
      selectedUserEmail: '',
      messages: {},
      senderUID: this.props.userId,
      bellActive: false,
      bellRing: false,
      notificationList: [],
      openList: false,
      listEmpty: true,
      loading: false,
      unreadNotifications: false,
      finishedLoading: false,
    }
}

componentDidMount = () => {
    auth.onAuthStateChanged(user => {
      if (user) {
        const {email, uid} = user;
        this.setState({
          email,
          uid,
          isLoggedIn: true
        })
        this.loadData();
        this.getData();
        roomRef.on('value', snapshot => {
          const rooms = snapshot.val();
          this.setState({
            rooms
          })
        })
        messageRef.on('child_added', snapshot => {
                    const message = snapshot.val();
                    const key = snapshot.key;
                    if (message.roomId === this.state.selectedRoom) {
                      this.setState({
                        messages: {
                          ...this.state.messages,
                          [key]: message
                        }
                      })
                    }
                  })
      }
    })
}

async getData() {
  var roomList = [];
  var filteredList = [];
  var orderedRoomList = [];
  await messageRef
    .once("value")
    .then(snapshot => {
      snapshot.forEach(room => { roomList.push({...room.val(), key: room.key})  } )
    })
    for (var i=0; i < roomList.length; i++) {
      if (this.state.role === "SuperAdmin") {
      var roomValue = roomList[i].roomId;
      if (roomValue.indexOf('lobe') > -1) {
        orderedRoomList.push(roomList[i])
      }} else if (this.state.role === "Company") {
        var roomValue = roomList[i].author;
        const uid = this.state.senderUID
        if (roomValue.indexOf(uid) > -1) {
          orderedRoomList.push(roomList[i])
        }
      }
    }
    this.setState({
        rooms: orderedRoomList
      })
      if (this.state.role === "SuperAdmin") {
      for (var j=0; j < this.state.rooms.length; j++) {
        if (this.state.rooms[j].author !== this.props.userId) {
          filteredList.push(this.state.rooms[j])
        }
      }} else if (this.state.role === "Company") {
        filteredList.push(this.state.rooms[0]);
      }
    const output = [...new Map(filteredList.map(uniqueRoom => [uniqueRoom?.author, uniqueRoom])).values()]
    this.setState({
      roomNames: output
    })
}

loadData = () => {
  roomRef.once('value')
        .then(snapshot => {
          const rooms = snapshot.val() || {};
          this.setState({
            rooms,
          });
          if (this.state.role === "SuperAdmin") {
          return messageRef
                    .orderByChild('roomId')
                    .equalTo(this.state.selectedRoom)
                    .once('value')
        } else if (this.state.role === "Company") {
          this.setState({
            selectedRoom: this.state.senderUID
          })
          return messageRef
                    .orderByChild('author')
                    .equalTo(this.state.selectedRoom)
                    .once('value')
        }})
        .then(snapshot => {
          const messages = snapshot.val() || {}
          this.setState({
            messages
          })
        })
        .catch(err => { return err.json })
};

sendMessage = (message) => {
        messageRef.push(message);
        this.setRoom(this.state.selectedRoom, this.state.selectedUserFirstname, this.state.selectedUserLastname, this.state.selectedUserEmail);
    }

handleNotificationsList = () => {
  this.setState(state => {
    return {
      bellRing: false,
      bellActive: false,
      openList: !state.openList,
      unreadNotifications: false,
    };
  });
};

setRoom = (roomId, firstname, lastname, email) => {
  const rId = roomId;
  this.state.selectedRoom = roomId;
  messageRef
    .orderByChild('roomId')
    .equalTo(rId)
    .once('value')
    .then(snapshot => {
      const messages = snapshot.val() || {};
      this.setState({
        messages
      })
    }
    )
 this.state.selectedUserFirstname = firstname;
 this.state.selectedUserLastname = lastname;
 this.state.selectedUserEmail = email;
}

handleChatNavigation = (roomId) => {
  const { history } = this.props;
    let path = `/customer_service_chats/${roomId}`;
    history.push(path);
}

render() {
    const messageEmpty = ["Ingen nye notifikationer"]
    var assigneeID = '';
    var listUniqueRooms = 
    <div style={{width:"290px", padding:"15px"}} >
    "...henter data. Vent venligst";
    </div>
    if (this.state.role === "SuperAdmin") {
    try {
      // TODO: Remember to add the correct icons when we know the final design!
      listUniqueRooms = this.state.roomNames.map((room) => {
        assigneeID = room.author;
        return(
      <div onClick={() => this.setRoom(room.roomId, room.userFirstname, room.userLastname, room.email)} key={room.author} 
      class="room_child">
        <li className="room_child_li" key={room.roomId}>
          <div style={{display:"flex", alignItems:"center", marginTop:"10px", marginBottom:"10px"}} key={room.roomId} >
           <img style={{height: "45px", width: "45px", marginRight:"16px", marginLeft:"16px"}} alt="test1" src="https://cdn1.iconfinder.com/data/icons/avatars-1-5/136/51-512.png" />
         <div style={{width:"50%", overflow:"hidden", overflowWrap:"break-word", marginRight:"20%"}}>
        <div className="chat_card_name"> {room.userFirstname} {room.userLastname} </div>
        <div className="chat_card_mes_preview">{room.email}</div>
         </div>
         <div className="chat_card_time_preview">  </div>
         </div> 
          </li>
          </div>)})
    } catch (err) {
      // TODO: Handle error correctly.
    }
    
return (
        <div>
           <div className="notification_projects">
           <h2 className="dashboard-welcome">Chat oversigt</h2>
           <div className="dashboard_icons">
            <div 
                className="notificationBell" 
                onClick={this.handleNotificationsList}>
                  {this.state.openList && this.state.listEmpty ? 
                  <div></div> :
                  <div className="notificationBell_circle">
                  {this.props.totalNotifications.length}
                  </div>
                  }
                    <BellLogo
                      width='30' 
                      active={this.state.bellActive ? true: false} 
                      animate={this.state.bellRing ? true: false} 
                      count={9}
                    />
                  {this.state.openList && (
                  <div class="dropdown">
                  <h3 className="dropdown_headline">Dine notifikationer</h3>
                    <ul>
                      {this.props.totalNotifications.length === 0 ? <li>{messageEmpty}</li>  : this.props.totalNotifications.map((item,i) => <li key={i}>{item.id.title}<div>{item.id.text}</div></li>)}
                    </ul>
                  </div>
                  )}
              </div>
              <NavLink className="chatLogo" to='/customer_service_chats' exact>
                  <ChatLogo />
                  </NavLink>
                  <div className="dashboard_indicator"/>
                    <div>
                     <h2 style={{ paddingRight: "12px"}} class="dashboard-user-name">
                     {localStorage.firstname} {localStorage.lastname}
                     </h2>
                    </div>
                  <img class="profile_icon" src="https://cdn1.iconfinder.com/data/icons/avatars-1-5/136/51-512.png" /><img/>
            </div>
          </div>
            <ErrorHandler error={this.state.error} onHandle={this.errorHandler} />
          <div className="chat_card_background">

          <div className="chat_card">
            {listUniqueRooms}
          </div>


          <div className="chat_right_body">

          <div className="chat_header">
          <div style={{display:"flex", alignItems:"center"}}>
           <img style={{height: "45px", width: "45px", marginRight:"16px", marginLeft:"24px"}} alt="test1" src="https://cdn1.iconfinder.com/data/icons/avatars-1-5/136/51-512.png" />
         <div style={{marginRight:"20%"}}>
        <div className="chat_card_name"> {this.state.selectedUserFirstname} {this.state.selectedUserLastname} </div>
        <div className="chat_card_mes_preview">{this.state.selectedUserEmail}</div>
         </div>
         </div>        
</div>
<div className="chat_chat">
<div className="chat-page-div-users">
                <div className="chat-panel">                             
                 <ChatPanel 
                    messages={this.state.messages}
                    roomId={this.state.selectedRoom}
                    email={this.props.email}
                    userId={this.props.userId}
                    sendMessage={this.sendMessage}
                    userFirstname={this.state.userFirstname}
                    userLastname={this.state.userLastname}
                    cId={this.state.clientIds}
                    aId={this.state.assigneeIds}
                    role={this.role}
                    senderUID={this.state.senderUID}
                    assigneeID={assigneeID}
                    />
                </div>
            </div>
          </div>
          </div>
         </div> 
      </div>
    );
  } else if (this.state.role === "Company") {
    try {
      // TODO: Remember to add the correct icons when we know the final design!
      listUniqueRooms = this.state.roomNames.map((room) => <div onClick={() => this.handleChatNavigation(room.roomId)} key={room.author} class="room_child" >
        <li className="room_child_li" key={room.roomId}>
          {}</li>
          </div>)
    } catch (err) {
      // TODO: Handle error correctly.
    }
    
return (
        <div>
           <div className="notification_projects">
           <h2 className="dashboard-welcome">Chat oversigt</h2>
           <div className="dashboard_icons">
            <div 
                className="notificationBell" 
                onClick={this.handleNotificationsList}>
                  {this.state.openList && this.state.listEmpty ? 
                  <div></div> :
                  <div className="notificationBell_circle">
                  {this.props.totalNotifications.length}
                  </div>
                  }
                    <BellLogo
                      width='30' 
                      active={this.state.bellActive ? true: false} 
                      animate={this.state.bellRing ? true: false} 
                      count={9}
                    />
                  {this.state.openList && (
                  <div class="dropdown">
                  <h3 className="dropdown_headline">Dine notifikationer</h3>
                    <ul>
                      {this.props.totalNotifications.length === 0 ? <li>{messageEmpty}</li>  : this.props.totalNotifications.map((item,i) => <li key={i}>{item.id.title}<div>{item.id.text}</div></li>)}
                    </ul>
                  </div>
                  )}
              </div>
              <NavLink className="chatLogo" to='/customer_service_chats' exact>
                  <ChatLogo />
                  </NavLink>
                  <div className="dashboard_indicator"/>
                    <div>
                     <h2 style={{ paddingRight: "12px"}} class="dashboard-user-name">
                     {localStorage.companyName}
                     </h2>
                    </div>
                  <img class="profile_icon" src="https://cdn1.iconfinder.com/data/icons/avatars-1-5/136/51-512.png" /><img/>
            </div>
          </div>
            <ErrorHandler error={this.state.error} onHandle={this.errorHandler} />
          <div>
            <div onClick={() => this.handleChatNavigation(this.state.senderUID)} key={this.state.senderUID} class="room_child" ><li className="room_child_li" key={this.state.senderUID}><img style={{height: "2rem", width: "2rem", marginRight:"3rem"}} alt="test1" src="https://image.flaticon.com/icons/png/512/15/15491.png" /><img src="" />Virksomhedens interne chat</li></div>
          </div>
      </div>
    );
  }}
}

export default CustomerServiceChat;
