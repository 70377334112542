import React, { Component, useEffect } from 'react';
import './SendMessageForm.css'
import {storage} from '../../util/fire';
import api from '../../util/config';

class SendMessageForm extends Component {
    state = {
        text: '',
    }

    sendChatNotification = () => {
        let creator = '';
        let assigneeIDS = [];
        let client = this.props.clientIds;
        for (let i = 0; i < this.props.assigneeIds?.length; i++) {
            const assigneeId = this.props.assigneeIds[i]?._id;
            assigneeIDS.push(assigneeId);
        }
        if(localStorage.role === 'Company' && this.props.assigneeIds === null || this.props.assigneeIds === undefined){
           let aIds = localStorage.sortedAssignees.split(',');
            assigneeIDS = aIds;
        }
        if(localStorage.role === 'SuperAdmin' && assigneeIDS.length > 0){
            assigneeIDS = [this.props.assigneeID]
        }
        if(client === null || client === undefined){
            client = localStorage.uId;
        }
        if(localStorage.role === 'Company'){
            creator = localStorage.companyName;
          }
          else if(localStorage.role === 'SuperAdmin'){
            creator = localStorage.firstname;
          }
          else {
            creator = `${localStorage.firstname} ${localStorage.lastname}`;
          }
        const graphqlQuery = {
            query: `
            query sendChatNotifications($users:[String]!, $title: String!, $text: String!, $dataID: String) {
                sendChatNotifications(users: $users, title: $title, text: $text, dataID: $dataID)
              }
            `,
            variables: {
              users: [client, ...assigneeIDS],
              title: 'Ny besked',
              text: `${creator} har sendt en ny besked`,
              dataID: this.props.roomId,
            }
          }
          fetch(api.baseUrl, {
            method: 'POST',
            body: JSON.stringify(graphqlQuery),
            headers: {
              Authorization: 'Bearer ' + localStorage.token,
              'Content-Type': 'application/json'
            }
          });
    }

    onMessageSend = (e, url) => {
        e.preventDefault();
        let message = {
            created: Date.now(),
            text: this.state.text,
            author: this.props.userId,
            roomId: this.props.roomId,
            email: this.props.email,
            userFirstname: localStorage.firstname,
            userLastname: localStorage.lastname !== null && localStorage.lastname !== 'null' ? localStorage.lastname : '',
            role: localStorage.role,
        }
        if(this.state.text === '' && this.props.attachment !== ''){
            let uploadTask = storage
            .ref(`${this.props.attachment.name}`)
            .put(this.props.attachment);
            uploadTask.on(
                "state_changed",
                snapshot => 
                {},
                error => {
                    //TODO HANDLE ERROR
                },() => {
                storage.ref()
                .child(this.props.attachment.name)
                .getDownloadURL()
                .then(url => {
                    message = {
                        created: Date.now(),
                        text: this.state.text,
                        author: this.props.userId,
                        roomId: this.props.roomId,
                        email: this.props.email,
                        userFirstname: localStorage.firstname,
                        userLastname: localStorage.lastname !== null && localStorage.lastname !== 'null' ? localStorage.lastname : '',
                        role: localStorage.role,
                        imageId: url,
                    }
                    this.props.sendMessage(message);
                    this.props.onFinishFile();
                    this.sendChatNotification();
                })
            })
        } 
        if(this.state.text !== ''){
            if(localStorage.role === 'Company'){
                message = {
                    created: Date.now(),
                    text: this.state.text,
                    author: this.props.userId,
                    roomId: this.props.roomId,
                    email: this.props.email,
                    userFirstname: localStorage.companyName,
                    userLastname: '',
                    role: localStorage.role,
    
                }
            } else {
                message = {
                    created: Date.now(),
                    text: this.state.text,
                    author: this.props.userId,
                    roomId: this.props.roomId,
                    email: this.props.email,
                    userFirstname: localStorage.firstname,
                    userLastname: localStorage.lastname !== null && localStorage.lastname !== 'null' ? localStorage.lastname : '',
                    role: localStorage.role,
                }
            }
            this.props.sendMessage(message);
            this.sendChatNotification();
        
        this.setState({ text: ''})
        }
    }

    render() {
        return (
            <form onSubmit={this.onMessageSend}>
                <div className="control">
                    <input type="text"
                    className="sendmessage"
                    value={this.props.attachment !== undefined &&  this.props.attachment !== null ? this.state.text : this.state.text}
                    placeholder="Start din besked her..."
                    onChange={(e) => this.setState({text: e.target.value})}>
                    </input>
                </div>
            </form>
        )
    }
}
export default SendMessageForm;
