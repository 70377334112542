import React, { Component, Fragment, createRef } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import api from './util/config';
import Layout from './components/Layout/Layout';
import Backdrop from './components/Backdrop/Backdrop';
import Toolbar from './components/Toolbar/Toolbar';
import MainNavigation from './components/Navigation/MainNavigation/MainNavigation';
import MobileNavigation from './components/Navigation/MobileNavigation/MobileNavigation';
import ErrorHandler from './components/ErrorHandler/ErrorHandler';
import ProjectPage from './pages/ProjectFeed/ProjectFeed';
import SingleProjectPage from './pages/ProjectFeed/SingleProject/SingleProject';
import CustomerPage from './pages/Customer/CustomerPage';
import CraftsmanPage from './pages/Craftsman/CraftsmanPage';
import LoginPage from './pages/Auth/Login';
import SignupPage from './pages/Auth/Signup';
import './App.css';
import Dashboard from './pages/Dashboard/Dashboard';
import ChatPage from './pages/Chat/Chat';
import CalenderPage from './pages/Calender/Calender';
import CustomerServiceChat from './pages/Chat/CustomerServiceChat/CustomerServiceChat';
import firebaseApp from './util/fire';
import { auth, messageRef } from './util/fire';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { store } from 'react-notifications-component';
import 'react-dropdown/style.css';
import CustomerServiceChatPage from './pages/Chat/CustomerServiceChat/CustomerServiceChatPage/CustomerServiceChatPage';
import Modal from './components/Modal/CustomModal'

console.log(`Connecting to backend application at ${process.env.REACT_APP_BUILDMATE_API_URL}`)

class App extends Component {
  constructor(props) {
    super(props);
    this.toolbarRef = createRef();
  this.state = {
    showBackdrop: false,
    showMobileNav: false,
    isAuth: false,
    firstname: '',
    lastname: '',
    email: '',
    token: null,
    uId: null,
    authLoading: false,
    error: null,
    role: '',
    user: '',
    uid: '',
    phone: '',
    resetPassword: false,
    refreshToken: null,
    expiration: 5000,
    fcmToken: null,
    bellActive: false,
    bellRing: false,
    notificationCounter: 0,
    notificationList: [],
    openList: false,
    totalNotifications: [],
    onFinishAddCompany:false,
    companyEmail:'',
    companyName:'',
  }
  
};

  componentDidMount() {
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');
    const expiryDate = localStorage.getItem('expiryDate');
    if (!token || !expiryDate) {
      return;
    }
    var currentTime = new Date(Date.now());
    var expirationDate = new Date(expiryDate);
    var dif =  expirationDate.getTime() - currentTime.getTime()
    // If token is about to expire.
    if (dif < 20000) {
      const userID = localStorage.getItem('uId');
      this.refreshToken(userID);
    }
    if (new Date(expiryDate) <= new Date()) {
      this.logoutHandler();
      return;
    }
    const uId = localStorage.getItem('uId');
    const role = localStorage.getItem('role');
    const remainingMilliseconds =
      new Date(expiryDate).getTime() - new Date().getTime();
    this.setState({ isAuth: true, token: token, refreshToken: refreshToken, uId: uId, role: role, expiration: remainingMilliseconds });
    this.setAutoLogout(remainingMilliseconds);
    auth.onAuthStateChanged(user => {
      if (user) {
        const {email, uid} = user;
        firebaseApp.messaging().getToken().then((token) =>{
          this.setState({
            email,
            uid,
            fcmToken: token,
            isLoggedIn: true
          });
          this.sendFcmToken(token,uId);
          this.getFirebaseData();
        });
      }
    })
  }

  componentWillMount() {
    if(document.getElementsByClassName('toolbar').length > 0){
      window.toolbarSize = document.getElementsByClassName('toolbar')[0].offsetWidth;
    } else {
      window.toolbarSize = 280;
    }
  }

  componentDidUpdate() {
    if(document.getElementsByClassName('toolbar').length > 0){
      window.toolbarSize = document.getElementsByClassName('toolbar')[0].offsetWidth;
    } else {
      window.toolbarSize = 280;
    }
      var newNotificationList = [];
      const messaging = firebaseApp.messaging();
      messaging.onMessage(function(payload) {
      const notification = {
        title: payload.data.title,
        message: payload.data.text,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 6000,
          onScreen: true
        }
      }
      store.addNotification({
        ...notification,
        container: "top-right",
      })
        newNotificationList.push(notification)
      var user = firebaseApp.auth().currentUser;
      var timeStamp = Date.now()
      firebaseApp.database().ref('notifications/'+ user.uid + '/besked' + timeStamp).set({
        title: payload.data.title,
        text: payload.data.text,
        messageSent: Date.now()
        });
      }.bind(this))
  }

  async sendFcmToken(fcmToken, uId)  {
    const graphqlQuery = {
      query: `{
        addfcmToken(id: "${uId}", fcmToken: "${fcmToken}") {
          fcmToken
        }
      }`
    }  
    this.setState({ authLoading: true });
    await fetch(api.baseUrl, {
      method: 'POST',
      body: JSON.stringify(graphqlQuery),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        return res.json();
      })
      .then(resData => {
        if (resData.errors && resData.errors[0].status === 422) {
          throw new Error(
            `${resData.errors[0].message}`
          );
        }
        if (resData.errors) {
          throw new Error(
            `${resData.errors[0].message}`
          )
        }
      })
      .catch(err => {
        this.setState({
          error: err
        });
      }); 
    };

  async refreshToken(userID) {
    const graphqlQuery = {
      query: `{
        refreshTokens(id: "${userID}") {
          token
          refreshToken
          firstname
          lastname
          email
          role
          uId
        }
      }`
    }  
    this.setState({ authLoading: true });
    await fetch(api.baseUrl, {
      method: 'POST',
      body: JSON.stringify(graphqlQuery),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        return res.json();
      })
      .then(resData => {
        if (resData.errors && resData.errors[0].status === 422) {
          throw new Error(
            `${resData.errors[0].message}`
          );
        }
        if (resData.errors) {
          throw new Error(
            `${resData.errors[0].message}`
          )
        }
        this.setState({
          isAuth: true,
          token: resData.data.refreshTokens.token,
          authLoading: false,
          uId: resData.data.refreshTokens.uId,
          role: resData.data.refreshTokens.role,
          firstname: resData.data.refreshTokens.firstname,
          lastname: resData.data.refreshTokens.lastname,
          name: resData.data.refreshTokens.firstname + ' ' + resData.data.refreshTokens.lastname,
          email: resData.data.refreshTokens.email,
          refreshToken: resData.data.refreshTokens.refreshToken,
        });
        localStorage.setItem('token', resData.data.refreshTokens.token);
        localStorage.setItem('refreshToken', resData.data.refreshTokens.refreshToken);
        localStorage.setItem('uId', resData.data.refreshTokens.uId);
        localStorage.setItem('role', resData.data.refreshTokens.role);
        localStorage.setItem('firstname', resData.data.refreshTokens.firstname);
        localStorage.setItem('lastname', resData.data.refreshTokens.lastname);
        localStorage.setItem('email', resData.data.refreshTokens.email);
        localStorage.setItem('name', resData.data.refreshTokens.firstname + ' ' + resData.data.refreshTokens.lastname);
        const remainingMilliseconds = 60 * 60 * 1500;
        const expiryDate = new Date(
          new Date().getTime() + remainingMilliseconds
        );
        localStorage.setItem('expiryDate', expiryDate.toISOString());
        this.setAutoLogout(remainingMilliseconds);
      })
      .catch(err => {
        this.setState({
          isAuth: false,
          authLoading: false,
          error: err
        });
      }); 
    };


  mobileNavHandler = isOpen => {
    this.setState({ showMobileNav: isOpen, showBackdrop: isOpen });
  };

  backdropClickHandler = () => {
    this.setState({ showBackdrop: false, showMobileNav: false, error: null });
  };

  logoutHandler = () => {
    this.setState({ isAuth: false, token: null, isOpen: false, showBackdrop: false });
    localStorage.removeItem('email');
    localStorage.removeItem('firstname');
    localStorage.removeItem('lastname');
    localStorage.removeItem('token');
    localStorage.removeItem('expiryDate');
    localStorage.removeItem('uId')
    localStorage.removeItem('role');
    localStorage.removeItem('name');
    localStorage.removeItem('companyName');
    localStorage.removeItem('sortedAssignees');
    firebaseApp.auth().signOut();
  };

  loginHandler = (event, authData) => {
    var errorCode;
    var errorMessage;
    event.preventDefault();
      firebaseApp.auth().signInWithEmailAndPassword(authData.email, authData.password)
      .then(user => { 
         this.setState({
           resetPassword: true
        }, () => {
          this.validateUser(authData.email, authData.password, authData.role);
        })})
      .catch(error => {
        errorCode = error.code;
        errorMessage = error.message;
        if (errorCode === 'auth/wrong-password') {
          errorMessage = `Du har indtastet et forkert kodeord til denne konto. Prøv venligst igen.`;
        } else if (errorCode === 'auth/too-many-requests') {
          errorMessage = `Du har brugt for mange forsøg på at logge ind. Vent venligst 10min. og prøv derefter igen.`;
        } else if (errorCode === 'auth/user-not-found') {
          errorMessage = `Der findes ikke en bruger med den indtastede mail. Husk at tage højde for store og små bogstaver! Prøv venligst igen.`;
        }
        
        this.setState({
          error: {
            message: errorMessage
          },
          errorButtonText: "Prøv igen"
        });
      });
  };

  async validateUser(email, password, role) {
    if (this.state.resetPassword === true) {
    var graphqlQuery = {
      query: `
        mutation UpdateUserNewPassword($email: String, $password: String) {
          updatePassword(userInput: { email: $email, password: $password }) {
            _id
            email
            password
        }
      }
      `,
      variables: {
        password: password,
        email: email
      }
    }
    await fetch(api.baseUrl, {
      method: 'POST',
      body: JSON.stringify(graphqlQuery),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        return res.json();
      })
      .then(resData => {
        if (resData.errors && resData.errors[0].status === 422) {
          throw new Error(
            `${resData.errors[0].message}`
          );
        }
        if (resData.errors) {
          throw new Error(`${resData.errors[0].message}`);
        }
      })
      .catch(err => {
        this.setState({
          error: err,
          errorButtonText: "Prøv igen"
        });
      }); 
      graphqlQuery = {
        query: `{
          loginSuperAdmin(email: "${email}", password: "${password}", role: "${role}") {
            token
            refreshToken
            firstname
            lastname
            email
            role
            uId
            sortedAssignees
          }
        }`
      }  
      this.setState({ authLoading: true });
      await fetch(api.baseUrl, {
        method: 'POST',
        body: JSON.stringify(graphqlQuery),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          return res.json();
        })
        .then((resData) => {
          if (resData.errors && resData.errors[0].status === 422) {
            throw new Error(
              `${resData.errors[0].message}`
            );
          }
          if (resData.errors) {
            throw new Error(
              `${resData.errors[0].message}`
            )
          }
          localStorage.setItem('sortedAssignees', resData.data.loginSuperAdmin.sortedAssignees);
          graphqlQuery = {
            query: `{
              user(id: "${resData.data.loginSuperAdmin.uId}") {
                companyName
              }
            }`
          }
          fetch(api.baseUrl, {
            method: 'POST',
            body: JSON.stringify(graphqlQuery),
            headers: {
            'Content-Type': 'application/json'
          }
            }).then((res) => {
            return res.json();
          }).then(resData => {
            this.setState({companyName: resData.data.user.companyName});
            localStorage.setItem('companyName', resData.data.user.companyName);
          });

          this.setState({
            isAuth: true,
            token: resData.data.loginSuperAdmin.token,
            authLoading: false,
            uId: resData.data.loginSuperAdmin.uId,
            role: resData.data.loginSuperAdmin.role,
            firstname: resData.data.loginSuperAdmin.firstname,
            lastname: resData.data.loginSuperAdmin.lastname,
            name: resData.data.loginSuperAdmin.firstname + ' ' + resData.data.loginSuperAdmin.lastname,
            email: resData.data.loginSuperAdmin.email,
            refreshToken: resData.data.loginSuperAdmin.refreshToken,
          });
          localStorage.setItem('token', resData.data.loginSuperAdmin.token);
          localStorage.setItem('refreshToken', resData.data.loginSuperAdmin.refreshToken);
          localStorage.setItem('uId', resData.data.loginSuperAdmin.uId);
          localStorage.setItem('role', resData.data.loginSuperAdmin.role);
          localStorage.setItem('firstname', resData.data.loginSuperAdmin.firstname);
          localStorage.setItem('lastname', resData.data.loginSuperAdmin.lastname);
          localStorage.setItem('email', resData.data.loginSuperAdmin.email);
          localStorage.setItem('name', resData.data.loginSuperAdmin.firstname + ' ' + resData.data.loginSuperAdmin.lastname);
          const remainingMilliseconds = 60 * 60 * 1500;
          const expiryDate = new Date(
            new Date().getTime() + remainingMilliseconds
          );
          localStorage.setItem('expiryDate', expiryDate.toISOString());
          this.setAutoLogout(remainingMilliseconds);
          const { history } = this.props;
          history.push('/dashboard')
        })
        .catch(err => {
          this.setState({
            isAuth: false,
            authLoading: false,
            error: err
          });
        }); 
      }
    }

  forgotPasswordHandler = (event, userInputForgotPassword) => {
    event.preventDefault();
    const { history } = this.props;
    const graphqlQuery = {
      query: `
        mutation UpdateUserPassword( $email: String) {
          forgotPassword(userInput: { email: $email }) {
            email
        }
      }
      `,
      variables: {
        email: userInputForgotPassword.email
      }
    }
    fetch(api.baseUrl, {
      method: 'POST',
      body: JSON.stringify(graphqlQuery),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        return res.json();
      })
      .then(resData => {
        if (resData.errors && resData.errors[0].status === 422) {
          throw new Error(
            `${resData.errors[0].message}`
          );
        }
        if (resData.errors) {
          throw new Error(`${resData.errors[0].message}`);
        } else {
          alert(`Nyt kodeord sendt til ${resData.data.forgotPassword.email}`)
        }
        history.push('/')
      })
      .catch(err => {
        this.setState({
          error: err
        });
      }); 
  };



  createCustomerHandler = (event, authData) => {
    event.preventDefault();
    this.setState({ authLoading: true });
    fetch(api.baseUrl + 'user/create-customer', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: authData.signupForm.email.value,
        password: authData.signupForm.password.value,
        firstname: authData.signupForm.firstname.value,
        lastname: authData.signupForm.lastname.value,
        phone: authData.signupForm.phone.value,
        role: authData.signupForm.role.value
      })
    })
      .then(res => {
        if (res.status === 422) {
          throw new Error(
            "Der findes allerede en kunde med denne email."
          );
        }
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Fejl! Kunne ikke oprette ny kunde.');
        }
        return res.json();
      })
      .then(resData => {
        this.setState({ isAuth: false, authLoading: false });
      })
      .catch(err => {
        this.setState({
          isAuth: false,
          authLoading: false,
          error: err
        });
      });
  };

  setAutoLogout = milliseconds => {
    setTimeout(() => {
      this.logoutHandler();
    }, milliseconds);
  };

  craftsmansignupHandler = (e, authData) => {
    e.preventDefault();
    this.setState({ authLoading: true });
    const graphqlQuery = {
      query: `
      mutation {
        createUser(userInput: {
          email: "${authData.signupForm.email.value}", 
          role: "${authData.signupForm.role.value}",
          firstname: "${authData.signupForm.firstname.value}",
          lastname: "${authData.signupForm.lastname.value}",
          phone: "${authData.signupForm.phone.value}",
          address: "${authData.signupForm.address.value}",
          cvr: "${authData.signupForm.cvr.value}",
          company: "${this.state.uId}",
          companyUrl: "${authData.signupForm.companyUrl.value}", }) {
          _id
          email
          firstname
          lastname
          phone
          companyUrl
          cvr
          address
          role
        }
      }
      ` 
    }
    fetch(api.baseUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(graphqlQuery)
    })
      .then(res => {
        return res.json();
      })
      .then(resData => {
        if (resData.errors && resData.errors[0].status === 422) {
          throw new Error(
            'Validering fejlede. Invalid input'
          );
        }
        if (resData.errors) {
          throw new Error(`Oprettelse af ${authData.signupForm.role.value} bruger fejlede.`);
        }
        this.setState({ authLoading: false });
      })
      .catch(err => {
        this.setState({
          authLoading: false,
          error: err
        });
      });
  };

  async getFirebaseData() {
    var user = firebaseApp.auth().currentUser;
    if (user) {
    return await firebaseApp.database().ref('notifications/' + this.state.uId).once('value').then(snapshot => {
      var items = [];
      snapshot.forEach(notification => {
        items.push({
          id: notification.val()
        })
      });
      this.setState({
        totalNotifications: items
      })
        });
      }
  }

  companyAdminSignupHandler = (e, authData) => {
    e.preventDefault();
    this.setState({ authLoading: true, companyEmail: authData.signupForm.email.value });
    const graphqlQuery = {
      query: `
      mutation {
        createUser(userInput: {
          email: "${authData.signupForm.email.value}", 
          role: "${authData.signupForm.role.value}",
          firstname: "${authData.signupForm.firstname.value}",
          phone: "${authData.signupForm.phone.value}",
          address: "${authData.signupForm.address.value}",
          companyName: "${authData.signupForm.companyName.value}",
          cvr: "${authData.signupForm.cvr.value}",
          companyUrl: "${authData.signupForm.companyUrl.value}", }) {
          _id
          email
          firstname
          companyName
          companyUrl
          phone
          role
          cvr
        }
      }
      ` 
    }
    fetch(api.baseUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(graphqlQuery)
    })
      .then(res => {
        return res.json();
      })
      .then(resData => {
        if (resData.errors && resData.errors[0].status === 422) {
          throw new Error(
            'Validering fejlede. Indtast venligst alle oplysninger!'
          );
        }
        else if (resData.errors) {
          throw new Error(`Oprettelse af ${authData.signupForm.role.value} bruger fejlede.`);
        }
        else {
          this.setState({onFinishAddCompany: true});
        }
        this.setState({ 
          authLoading: false });
      })
      .catch(err => {
        this.setState({
          authLoading: false,
          error: err
        });
      });
  };

  signupHandler = (e, authData) => {
    e.preventDefault();
    this.setState({ authLoading: true });
    const graphqlQuery = {
      query: `
      mutation {
        createUser(userInput: {
          email: "${authData.signupForm.email.value}", 
          role: "${authData.signupForm.role.value}",
          firstname: "${authData.signupForm.firstname.value}",
          lastname: "${authData.signupForm.lastname.value}",
          phone: "${authData.signupForm.phone.value}",
          address: "${authData.signupForm.address.value}",
          cvr: "${authData.signupForm.cvr.value}",
          companyUrl: "${authData.signupForm.companyUrl.value}", }) {
          _id
          email
          firstname
          lastname
          phone
          role
        }
      }
      ` 
    }
    fetch(api.baseUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(graphqlQuery)
    })
      .then(res => {
        return res.json();
      })
      .then(resData => {
        if (resData.errors && resData.errors[0].status === 422) {
          throw new Error(
            'Validering fejlede. Indtast venligst alle oplysninger!'
          );
        }
        else if (resData.errors) {
          throw new Error(`Oprettelse af ${authData.signupForm.role.value} bruger fejlede.`);
        }
        else {
          alert(`Ny konto er oprettet! Der er sendt en mail til ${authData.signupForm.email.value}`)
        }
        this.setState({ 
          authLoading: false });
      })
      .catch(err => {
        this.setState({
          authLoading: false,
          error: err
        });
      });
  };

  setAutoLogout = milliseconds => {
    setTimeout(() => {
      this.logoutHandler();
    }, milliseconds);
  };

  errorHandler = () => {
    this.setState({ error: null, errorButtonText: null });
  };

  pageStyle = function() {
    return {
      paddingLeft: window.toolbarSize
    }
  }
  
  render() {
    let routes = (
      <Switch>
        <Route
          path="/login"
          exact
          render={props => (
            <LoginPage
              {...props}
              onLogin={this.loginHandler}
              loading={this.state.authLoading}
              onForgotPassword={this.forgotPasswordHandler}
            />
          )}
        />
        <Route 
          path="/"
          render={props => (
            <LoginPage
              {...props}
              onLogin={this.loginHandler}
              loading={this.state.authLoading}
              onForgotPassword={this.forgotPasswordHandler}
            />
          )}
        />
      </Switch>
    );
    if (this.state.isAuth && this.state.role === 'SuperAdmin') {
      routes = (
        <Switch>
          <Route
            path="/customer_service_chats/:roomId"
            exact
            render={props => (
              <CustomerServiceChatPage
                {...props}
                userId={this.state.uId}
                token={this.state.token}
                email={this.state.email}
                totalNotifications={this.state.totalNotifications}
              />
            )}
          />
          <Route
            path="/customer_service_chats"
            exact
            render={props => (
              <CustomerServiceChat
                {...props}
                userId={this.state.uId}
                token={this.state.token}
                email={this.state.email}
                totalNotifications={this.state.totalNotifications}
              />
            )}
          />
          <Route
            path="/signup"
            exact
            render={props => (
              <SignupPage
                {...props}
                onSignup={this.companyAdminSignupHandler}
                loading={this.state.authLoading}
                role={this.state.role}
                totalNotifications={this.state.totalNotifications}
              />
            )}
          />
          <Route
            path="/projects"
            exact
            render={props => (
              <ProjectPage 
                {...props}
                userId={this.state.uId} 
                token={this.state.token}
                role={this.state.role}
                firstname={this.state.firstname}
                lastname={this.state.lastname}
                totalNotifications={this.state.totalNotifications} />
            )}
          />
          <Route
            path="/projects/:projectId"
            render={props => (
              <SingleProjectPage
                {...props}
                userId={this.state.uId}
                token={this.state.token}
                role={this.state.role}
                totalNotifications={this.state.totalNotifications}
              />
            )}
          />
          <Route
            path="/chats/:projectId"
            render={props => (
              <ChatPage
                {...props}
                userId={this.state.uId}
                token={this.state.token}
                email={this.state.email}
                totalNotifications={this.state.totalNotifications}
              />
            )}
          />
          <Route
            path="/calender"
            render={props => (
              <CalenderPage
                {...props}
                userId={this.state.uId}
                token={this.state.token}
                email={this.state.email}
                role={this.state.role}
                totalNotifications={this.state.totalNotifications}
              />
            )}
          />
          <Route
            path="/clients"
            exact
            render={props => (
              <CustomerPage
                {...props}
                onSignup={this.signupHandler}
                userId={this.state.uId}
                token={this.state.token}
                loading={this.state.authLoading}
                role={this.state.role}
                totalNotifications={this.state.totalNotifications}
              />
            )}
          />
          <Route
            path="/craftsmen"
            exact
            render={props => (
              <CraftsmanPage
                {...props}
                onSignup={this.craftsmansignupHandler}
                userId={this.state.uId}
                token={this.state.token}
                loading={this.state.authLoading}
                role={this.state.role}
                totalNotifications={this.state.totalNotifications}
              />
            )}
          />
          <Route
            path="/dashboard"
            exact
            render={props => (
              <Dashboard
                {...props}
                userId={this.state.uId}
                userFirstName={this.state.firstname}
                userLastName={this.state.lastname}
                userEmail={this.state.email}
                token={this.state.token}
                loading={this.state.authLoading}
                role={this.state.role}
                totalNotifications={this.state.totalNotifications}
              />
            )}
          />
        </Switch>
      );
    } if (this.state.isAuth && this.state.role === 'Company') {
    routes = (
      <Switch>
        <Route
            path="/customer_service_chats/:roomId"
            exact
            render={props => (
              <CustomerServiceChatPage
                {...props}
                userId={this.state.uId}
                token={this.state.token}
                email={this.state.email}
                totalNotifications={this.state.totalNotifications}
              />
            )}
          />
          <Route
            path="/customer_service_chats"
            exact
            render={props => (
              <CustomerServiceChat
                {...props}
                userId={this.state.uId}
                token={this.state.token}
                email={this.state.email}
                totalNotifications={this.state.totalNotifications}
              />
            )}
          />
        <Route
          path="/projects"
          exact
          render={props => (
            <ProjectPage 
              {...props}
              userId={this.state.uId} 
              token={this.state.token}
              role={this.state.role}
              firstname={this.state.firstname}
              lastname={this.state.lastname}
              totalNotifications={this.state.totalNotifications} />
          )}
        />
        <Route
          path="/projects/:projectId"
          render={props => (
            <SingleProjectPage
              {...props}
              userId={this.state.uId}
              token={this.state.token}
              role={this.state.role}
              totalNotifications={this.state.totalNotifications}
            />
          )}
        />
        <Route
          path="/chats/:projectId"
          render={props => (
            <ChatPage
              {...props}
              userId={this.state.uId}
              token={this.state.token}
              email={this.state.email}
              totalNotifications={this.state.totalNotifications}
            />
          )}
        />
        <Route
          path="/calender"
          render={props => (
            <CalenderPage
              {...props}
              userId={this.state.uId}
              token={this.state.token}
              email={this.state.email}
              role={this.state.role}
              totalNotifications={this.state.totalNotifications}
            />
          )}
        />
        <Route
          path="/clients"
          exact
          render={props => (
            <CustomerPage
              {...props}
              onSignup={this.signupHandler}
              userId={this.state.uId}
              token={this.state.token}
              loading={this.state.authLoading}
              role={this.state.role}
              totalNotifications={this.state.totalNotifications}
            />
          )}
        />
        <Route
          path="/craftsmen"
          exact
          render={props => (
            <CraftsmanPage
              {...props}
              onSignup={this.craftsmansignupHandler}
              userId={this.state.uId}
              token={this.state.token}
              loading={this.state.authLoading}
              role={this.state.role}
              totalNotifications={this.state.totalNotifications}
            />
          )}
        />
        <Route
          path="/dashboard"
          exact
          render={props => (
            <Dashboard
              {...props}
              userId={this.state.uId}
              userFirstName={this.state.firstname}
              userLastName={this.state.lastname}
              userEmail={this.state.email}
              token={this.state.token}
              loading={this.state.authLoading}
              role={this.state.role}
              totalNotifications={this.state.totalNotifications}
              userCompanyName={this.state.companyName}
            />
          )}
        />
      </Switch>
    );
  }
    return (
      <Fragment>
        <ReactNotification />
        {this.state.showBackdrop && (
          <Backdrop onClick={this.backdropClickHandler} />
        )}
        <ErrorHandler error={this.state.error} buttonText={this.state.errorButtonText} onHandle={this.errorHandler} />
        {this.state.isAuth && (
          <Layout
            header={
              <Toolbar ref={this.toolbarRef}>
                <MainNavigation
                  onChooseItem={this.mobileNavHandler.bind(this, false)}
                  onLogout={this.logoutHandler}
                  isAuth={this.state.isAuth}
                  role={this.state.role}
                />
              </Toolbar>
            }
            mobileNav={
              <MobileNavigation
                onOpenMobileNav={this.mobileNavHandler.bind(this, !this.state.showMobileNav)}
                open={this.state.showMobileNav}
                mobile
                onChooseItem={this.mobileNavHandler.bind(this, false)}
                onLogout={this.logoutHandler}
                isAuth={this.state.isAuth}
              />
            }
          />
        )}
        <ReactNotification />
        <div className="page-container" style={{paddingLeft: window.toolbarSize + 'px'}}>
          {routes}
        </div>
        {this.state.onFinishAddCompany ? 
        <Modal onAcceptModal={()=>{this.setState({onFinishAddCompany: false})}} accept='Ok' acceptEnabled={true}>
         {`Ny konto er oprettet! Der er sendt en mail til ${this.state.companyEmail}`}
        </Modal>
        : null}
      </Fragment>
    );
  }
}

export default withRouter(App);
