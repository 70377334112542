import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import './MessageList.css';

const Message = ({ message, senderUID, userId }) => {
  return (
    <li className='listed-items' style={{ listStyleType: 'none' }}>
      <div className='message-chat'>
        <p className='message-text'>
          <p className='message-user'>
            {message.userFirstname + ' ' + message.userLastname}
            <span style={{ display: 'list-item' }}>{message.role}</span>
          </p>
          <p className='message-time'>
            {moment(message.created).format('DD/MM/YYYY h:mm')}
          </p>
        </p>
        <p className='message-content'>{message.text}</p>
      </div>
    </li>
  );
};

const MessageList = ({ messages, userId }) => {
  const el = useRef(null);

  useEffect(() => {
    el.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
  });

  useEffect(() => {
    el.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
  });

  return (
    <div>
      <ul className="message-list">
        {Object.keys(messages)
          .map(messageKey => ({ ...messages[messageKey], id: messageKey }))
          .map(message => {
            return (
            <div key={message.id}>
              {userId === message.author ? (
                <li className="listed-items" style={{ listStyleType: "none", cursor: "default" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="message-time">
                      {moment(message.created).fromNow('DD/MM/YYYY h:mm')} siden
                                        </div>

                    <div style={{ maxWidth: "70%" }}>
                      <div className="message-time" style={{ textAlign: "right", marginRight: "10px", marginBottom: "5px" }}>
                        {message.userFirstname} {message.userLastname} - {message.role}
                      </div>
                      {message.text === "" ?
                      <a href={`${message.imageId}`} target="_blank"> 
                      <img height="100" width="100" src={message.imageId} alt='files' /> 
                      </a>
                      :
                      <div className="message-chat">
                      {message.text} 
                      </div>
                      }

                    </div>
                  </div>
                </li>
              ) : (
                  <li className="listed-otheritems" style={{ listStyleType: "none", cursor: "default" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ width: "100%" }}>
                        <div className="message-othertime" style={{ textAlign: "left", marginLeft: "5px", marginBottom: "5px" }}>
                          {message.userFirstname} {message.userLastname} - {message.role}
                        </div>
                        {message.text === "" ? (
                          <p className="message-othercontent">
                            <a href={`${message.imageId}`} target="_blank"> 
                            <img height="100" width="100" src={message.imageId} alt='files' />
                            </a>
                          </p>
                        ) : (
                            <p className="message-otherchat">
                              {message.text} {message.imageId}
                            </p>
                          )}
                      </div>
                      <div className="message-othertime" style={{width:"10%"}}>
                        {moment(message.created).fromNow('DD/MM/YYYY h:mm')} siden
                                                    </div>
                    </div>
                  </li>
                )
              }
            </div>
          );
          }
          )
        }
      </ul>
      <div id={'el'} ref={el}>
      </div>
    </div>
  );
};

export default MessageList;
