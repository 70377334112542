import React from 'react';
import DatePicker, { registerLocale} from 'react-datepicker';
import da from "date-fns/locale/da";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import './Input.css';
import moment from 'moment';

registerLocale("da", da);

const input = props => (
  <div className="input">
    {props.label && <label htmlFor={props.id}>{props.label}</label>}
    {props.control === 'input' && (
      <input
        className={[
          !props.valid ? 'invalid' : 'valid',
          props.touched ? 'touched' : 'untouched'
        ].join(' ')}
        type={props.type}
        id={props.id}
        required={props.required}
        value={props.value}
        placeholder={props.placeholder}
        onChange={e => props.onChange(props.id, e.target.value, e.target.files)}
        onBlur={props.onBlur}
      />
    )}
    {props.label !== 'Beskrivelse' && props.control === 'textarea' && (
      <textarea
        className={[
          !props.valid ? 'invalid' : 'valid',
          props.touched ? 'touched' : 'untouched'
        ].join(' ')}
        id={props.id}
        rows={props.rows}
        required={props.required}
        value={props.value}
        placeholder={props.placeholder}
        onChange={e => props.onChange(props.id, e.target.value)}
        onBlur={props.onBlur}
        style={{resize:"none"}}
      />
    )}
    {props.label === 'Beskrivelse' && props.control === 'textarea' && (
      <textarea
        className={[
          !props.valid ? 'invalid' : 'valid',
          props.touched ? 'touched' : 'untouched'
        ].join(' ')}
        id={props.id}
        rows={props.rows}
        required={props.required}
        value={props.value}
        placeholder={props.placeholder}
        onChange={e => props.onChange(props.id, e.target.value)}
        onBlur={props.onBlur}
        style={{resize:"none", height: '240px'}}
      />
    )}
    {props.control === 'select' && (
    <div>
      <select
        className={[
          !props.valid ? 'invalid' : 'valid',
          props.touched ? 'touched' : 'untouched'
        ].join(' ')}
        type={props.type}
        id={props.id}
        required={props.required}
        value={props.value}
        placeholder={props.placeholder}
        onChange={e => props.onChange(props.id, e.target.value)}
        onBlur={props.onBlur}
        option={props.option}
        multiple={props.multiple}
        defaultValue={props.defaultValue}
      >
        {props.option}
      </select>
    </div>
    )}
    {props.control === 'startDate' && (
    <div className="customDatePicker">
      <DatePicker
        locale="da"
        className={[
          !props.valid ? 'invalid' : 'valid',
          props.touched ? 'touched' : 'untouched'
        ].join(' ')}
        type={props.type}
        id={props.id}
        required={props.required}
        value={moment(props.value).format('DD-MM-YYYY')}
        placeholder={props.placeholder}
        onChange={e => props.onChange(props.id, e.toLocaleDateString('en-US'))}
        onBlur={props.onBlur}
        selected={props.selected}
        dateFormat={"dd/MM/yyyy"}
      />
      </div>
    )}
    {props.control === 'finishDate' && (
    <div className="customDatePicker">
      <DatePicker
        locale="da"
        className={[
          !props.valid ? 'invalid' : 'valid',
          props.touched ? 'touched' : 'untouched'
        ].join(' ')}
        type={props.type}
        id={props.id}
        required={props.required}
        value={moment(props.value).format('DD-MM-YYYY')}
        placeholder={props.placeholder}
        onChange={e => props.onChange(props.id, e.toLocaleDateString('en-US'))}
        onBlur={props.onBlur}
        selected={props.selected}
        dateFormat={"dd/MM/yyyy"}
      />
      </div>
    )}
    {props.control === 'deadlineDate' && (
    <div className="customDatePicker">
      <DatePicker
        locale="da"
        style={{width:"100%"}}
        className={[
          !props.valid ? 'invalid' : 'valid',
          props.touched ? 'touched' : 'untouched'
        ].join(' ')}
        type={props.type}
        id={props.id}
        required={props.required}
        value={moment(props.value).format('DD-MM-YYYY')}
        placeholder={props.placeholder}
        onChange={e => props.onChange(props.id, e.toLocaleDateString('en-US'))}
        onBlur={props.onBlur}
        selected={props.selected}
        dateFormat={"dd/MM/yyyy"}
      />
    </div>
    )}
    {props.control === 'selectMultiple' && (
      <div>
        <Select
          className={[
            !props.valid ? 'invalid' : 'valid',
            props.touched ? 'touched' : 'untouched'
          ].join(' ')}
          type={props.type}
          id={props.id}
          required={props.required}
          value={props.selectedOption, props.selectedCustomer}
          placeholder={props.placeholder}
          onChange={e => props.onChange(e)}
          onBlur={props.onBlur}
          options={props.options}
          isMulti={props.isMulti}
        />
      </div>
    )}
  </div>
);

export default input;
