import * as React from 'react';
import TableCell from './TableCell';
import './Table.css';


export default class Table extends React.Component {


    renderHeading = (cell, index) => {
        const { headings } = this.props;

        return (
            <TableCell
                key={`heading-${index}`}
                content={headings[index]}
                header={true}
            />
        )
    };

    

    renderRow = (row, index) => {
        const { rows } = this.props;
        return (
            <tr onClick={e => e.target.value} key={`row-${index}`}>
                {rows[index].rows.map((cell, cellIndex) => {
                    return (
                        <TableCell
                            key={`${index}-${cellIndex}`}
                            content={rows[index].rows[cellIndex]}
                            onClick={e => e.target.value}
                            projectId={rows[index].id}
                            changeRoute={this.props.changeRoute}
                        />
                    )
                })}
            </tr>
        )
    };

    render() {
        const { headings, rows, tableClass } = this.props;

        this.renderHeadingRow = this.renderHeading.bind(this);
        this.renderRow = this.renderRow.bind(this);

        const tableHead = (
            <tr key="heading">
                {headings.map(this.renderHeadingRow)}
            </tr>
        );

        const tableBody = rows.map(this.renderRow);

        return (
            <div className="table">
                <table className={tableClass} >
                    <thead>{tableHead}</thead>
                    <tbody>{tableBody}</tbody>
                </table>
            </div>
        );
    }

}
