import * as React from 'react';
import Badge from "../Badge/Badge";
import IconButton from '@material-ui/core/IconButton';
import AutoLinkText from 'react-autolink-text2';
import './TableCell.css';
import defaultAvatar from '../../assets/default.png'
import { ReactComponent as IconInfo } from "../../assets/info-sq.svg";
import { ReactComponent as IconEdit } from "../../assets/edit.svg";
import { ReactComponent as IconDelete } from "../../assets/delete.svg";

export default function TableCell({
  content,
  header,
  projectId,
  changeRoute,
}) {

  let outputText;
  let imageUrl = defaultAvatar;;
  let email;

  if(content.type == "status"){
    outputText = <Badge className={content.class}><td class="td-status">{content.text}</td></Badge>;
  } else if(content.type == "actions"){
    
    outputText = (Object.keys(content.actions)
      .map(key => {
        if(content.actions[key].icon == 'info-squarenew'){
          return <IconButton className={`icon-${content.actions[key].icon}`} style={{ fontSize: 20}} aria-label="edit" onClick={content.actions[key].onClick}><IconInfo/> </IconButton>
        }
        if(content.actions[key].icon == 'editnew'){
          return <IconButton className={`icon-${content.actions[key].icon}`} style={{ fontSize: 20}} aria-label="edit" onClick={content.actions[key].onClick}><IconEdit/> </IconButton>
        }
        if(content.actions[key].icon == 'deletenew'){
          return <IconButton className={`icon-${content.actions[key].icon}`} style={{ fontSize: 20}} aria-label="edit" onClick={content.actions[key].onClick}><IconDelete/> </IconButton>
        }
      })
    );
  } else if (content.type == "url") {
    outputText = <AutoLinkText text={content.text}/>
  }else if (content.type == 'name'){
    outputText = content.text;
    email = content.email;
    if(content.avatarImage != null && content.avatarImage != ''){
      imageUrl = content.avatarImage;
    }
  }else if (content.type == 'virksomheder') {
    outputText = content.text;
    email = content.email;
  }else {
    outputText = content.text;
  }

  const cell = header ? (
    content === 'Navn og Email' || content === 'Titel og Kunde' || content === 'Virksomhedsnavn'?
    <th class="th-header-title">
      {content}
    </th>
    :
    <th class="th-header">
      {content}
    </th>
  ) : (
    content.type == 'name' && content.typeOf == 'projectpage'?
    <td class="td-avatar-projectpage" onClick={() => {changeRoute(projectId)}}>
      {<div class="image-cropper-projectpage"><img src={imageUrl} alt="avatar" class="profile-pic-projectpage"/></div>}
      <div class="td-text">
        <div class="td-name">{outputText}</div>
        <div class="td-email">{email}</div>
      </div>
    </td>
    :
    content.type == 'name' ?
    <td class="td-avatar">
      {<div class="image-cropper"><img src={imageUrl} alt="avatar" class="profile-pic"/></div>}
      <div class="td-text">
        <div class="td-name">{outputText}</div>
        <div class="td-email">{email}</div>
      </div>
    </td> 
    :
    content.type == 'phone' ?
    <td class="td-phone">
      {outputText}
    </td>
    :
    content.type == 'date' ?
    <td class='td-default-date'>
      {outputText}
    </td>
    :
    content.type == 'status' ?
    <td class='td-default-status'>
      {outputText}
    </td>
    :
    content.type == 'actions' && content.typeOf != null ?
    <td class='td-default-info'>
      {outputText}
    </td>
    :
    content.type == 'virksomheder' && content.typeOf == 'name' ?
    <td class='td-virksomheder'>
      <div class="td-text-virksomheder">
        <div class="td-name-virksomheder">{outputText}</div>
        <div class="td-email-virksomheder">{email}</div>
      </div>
    </td> 
    :
    content.type == 'virksomheder' && content.typeOf == 'address' ?
    <td class='td-virksomheder-address'>
      <div class="td-text-virksomheder">
        <div class="td-name-virksomheder">{outputText}</div>
        <div class="td-email-virksomheder">{email}</div>
      </div>
    </td> 
    :
    content.type == 'virksomheder' && content.typeOf == 'jobtitel' ?
    <td class='td-default-virksomheder-jobtitel'>
      {outputText}
    </td>
    :
    content.type == 'virksomheder' && content.typeOf == 'phone' ?
    <td class='td-default-virksomheder-phone'>
      {outputText}
    </td>
    :
    <td class='td-default'>
      {outputText}
    </td>
    
  );

  return (cell);
}
