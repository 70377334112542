import React from 'react';
import { NavLink } from 'react-router-dom';

import {ReactComponent as Icon } from '../../../assets/logo-small.svg';
import {ReactComponent as IconText } from '../../../assets/dashboard-text.svg';
import NavigationItems from '../NavigationItems/NavigationItems';
import MobileToggle from '../MobileToggle/MobileToggle';
import './MobileNavigation.css';

const mobileNavigation = props => (
  
    <div>
      <MobileToggle onOpen={props.onOpenMobileNav} />
      <nav className={['mobile-nav', props.open ? 'open' : ''].join(' ')}>
        <div className="main-nav__logo">
        <NavLink to="/dashboard">
          <Icon className="small-icon"/>
          <IconText />
        </NavLink>
        </div>
        <ul
          className={['mobile-nav__items', props.mobile ? 'mobile' : ''].join(' ')}
        >
          <NavigationItems
            mobile
            onChoose={props.onChooseItem}
            isAuth={props.isAuth}
            onLogout={props.onLogout}
          />
        </ul>
      </nav>
    </div>
);

export default mobileNavigation;
