import React, { Component, Redirect } from 'react';

import Input from '../../components/Form/Input/Input';
import Button from '../../components/Button/Button';
import { required, length, email } from '../../util/validators';
import BellIcon from 'react-bell-icon';
import '../Auth/SignUp/SignUpAuth.css';
import SignUpAuth from '../Auth/SignUp/SignUpAuth';
import Auth from './Auth';
import { ReactComponent as ChatLogo } from '../../assets/chat_icon.svg';
import { ReactComponent as BellLogo } from '../../assets/bell_icon.svg';
import { NavLink } from 'react-router-dom';

class Signup extends Component {
  state = {
    onSignup: false,
    signupForm: {
      email: {
        value: '',
        valid: false,
        touched: false,
        validators: [required, email],
      },
      role: {
        value: 'Company',
        valid: true,
        validators: [required],
      },
      firstname: {
        value: '',
        valid: false,
        touched: false,
        validators: [required],
      },
      companyName: {
        value: '',
        valid: false,
        touched: false,
        validators: [required],
      },
      phone: {
        value: '',
        valid: false,
        touched: false,
        validators: [required],
      },
      cvr: {
        value: '',
        valid: false,
        touched: false,
        validators: [required],
      },
      companyUrl: {
        value: '',
        valid: false,
        touched: false,
        validators: [required],
      },
      address: {
        value: '',
        valid: false,
        touched: false,
        validators: [required],
      },
      formIsValid: false,
    },
  };

  inputChangeHandler = (input, value) => {
    this.setState((prevState) => {
      let isValid = true;
      for (const validator of prevState.signupForm[input].validators) {
        isValid = isValid && validator(value);
      }
      const updatedForm = {
        ...prevState.signupForm,
        [input]: {
          ...prevState.signupForm[input],
          valid: isValid,
          value: value,
        },
      };
      let formIsValid = true;
      for (const inputName in updatedForm) {
        formIsValid = formIsValid && updatedForm[inputName].valid;
      }
      return {
        signupForm: updatedForm,
        formIsValid: formIsValid,
      };
    });
  };

  inputBlurHandler = (input) => {
    this.setState((prevState) => {
      return {
        signupForm: {
          ...prevState.signupForm,
          [input]: {
            ...prevState.signupForm[input],
            touched: true,
          },
        },
      };
    });
  };

  handleNotificationsList = () => {
    this.setState((state) => {
      return {
        bellRing: false,
        bellActive: false,
        openList: !state.openList,
        unreadNotifications: false,
      };
    });
  };

  render() {
    const messageEmpty = ['Ingen nye notifikationer'];
    if (this.state.toSignupPage === true) {
      return <Redirect to='/signup' />;
    }
    return (
      <div>
        <div className='notification_projects'>
        </div>
        <div>
          <div style={{display:'flex'}}>
            <h1 id='customerHeader'>Signup</h1>
            <div className='dashboard_icons-signup'>
            <div
              className='notificationBell'
              onClick={this.handleNotificationsList}
            >
              {this.state.openList && this.state.listEmpty ? (
                <div></div>
              ) : (
                <div className='notificationBell_circle'>
                  {this.props.totalNotifications.length}
                </div>
              )}
              <BellLogo
                width='30'
                active={this.state.bellActive ? true : false}
                animate={this.state.bellRing ? true : false}
                count={9}
                color='#C5C7CD'
              />
              {this.state.openList && (
                <div class='dropdown'>
                  <h3 className='dropdown_headline'>Dine notifikationer</h3>
                  <ul>
                    {this.props.totalNotifications.length === 0 ? (
                      <li>{messageEmpty}</li>
                    ) : (
                      this.props.totalNotifications.map((item, i) => (
                        <li key={i}>
                          {item.id.title}
                          <div>{item.id.text}</div>
                        </li>
                      ))
                    )}
                  </ul>
                </div>
              )}
            </div>
            <NavLink className='chatLogo' to='/customer_service_chats' exact>
              <ChatLogo />
            </NavLink>
            <div className='dashboard_indicator' />
            <div>
              <h2 style={{ paddingRight: '12px' }} class='dashboard-user-name'>
                {localStorage.firstname}
              </h2>
            </div>
            <img
              class='profile_icon'
              src='https://cdn1.iconfinder.com/data/icons/avatars-1-5/136/51-512.png'
            />
            <img />
          </div>
          </div>
          
          <SignUpAuth>
            <h2>Tilføj virksomhed</h2>
            <form onSubmit={(e) => this.props.onSignup(e, this.state)}>
              <Input
                id='email'
                label='Virksomhedens email'
                placeholder='james@example.com'
                type='email'
                control='input'
                onChange={this.inputChangeHandler}
                onBlur={this.inputBlurHandler.bind(this, 'email')}
                value={this.state.signupForm['email'].value}
                valid={this.state.signupForm['email'].valid}
                touched={this.state.signupForm['email'].touched}
              />
              <Input
                id='firstname'
                label='Kontaktpersonens fulde navn'
                placeholder='Per'
                type='firstname'
                control='input'
                onChange={this.inputChangeHandler}
                onBlur={this.inputBlurHandler.bind(this, 'firstname')}
                value={this.state.signupForm['firstname'].value}
                valid={this.state.signupForm['firstname'].valid}
                touched={this.state.signupForm['firstname'].touched}
              />
              <Input
                id='companyName'
                label='Firmanavn'
                placeholder='ex. Sales A/S'
                type='companyName'
                control='input'
                onChange={this.inputChangeHandler}
                onBlur={this.inputBlurHandler.bind(this, 'companyName')}
                value={this.state.signupForm['companyName'].value}
                valid={this.state.signupForm['companyName'].valid}
                touched={this.state.signupForm['companyName'].touched}
              />
              <Input
                id='phone'
                label='Telefonnummer'
                placeholder='ex. 11223344'
                type='phone'
                control='input'
                onChange={this.inputChangeHandler}
                onBlur={this.inputBlurHandler.bind(this, 'phone')}
                value={this.state.signupForm['phone'].value}
                valid={this.state.signupForm['phone'].valid}
                touched={this.state.signupForm['phone'].touched}
              />
              <Input
                id='address'
                label='Virksomhedens adresse'
                placeholder='ex. Rolighedsvej 123a'
                control='textarea'
                rows='3'
                onChange={this.inputChangeHandler}
                onBlur={this.inputBlurHandler.bind(this, 'address')}
                value={this.state.signupForm['address'].value}
                valid={this.state.signupForm['address'].valid}
                touched={this.state.signupForm['address'].touched}
              />
              <Input
                id='cvr'
                label='Virksomheds cvr'
                placeholder='ex 87654321'
                type='cvr'
                control='input'
                onChange={this.inputChangeHandler}
                onBlur={this.inputBlurHandler.bind(this, 'cvr')}
                value={this.state.signupForm['cvr'].value}
                valid={this.state.signupForm['cvr'].valid}
                touched={this.state.signupForm['cvr'].touched}
              />
              <div className='grid-container'>
                <div className='grid-item1'>
                  <Input
                    id='companyUrl'
                    label='Virksomhedens URL'
                    placeholder='ex. www.virksomheds-url.dk'
                    type='companyUrl'
                    control='input'
                    onChange={this.inputChangeHandler}
                    onBlur={this.inputBlurHandler.bind(this, 'companyUrl')}
                    value={this.state.signupForm['companyUrl'].value}
                    valid={this.state.signupForm['companyUrl'].valid}
                    touched={this.state.signupForm['companyUrl'].touched}
                  />
                  <Input
                    id='role'
                    type='hidden'
                    onBlur={this.inputBlurHandler.bind(this, 'role')}
                    value={this.state.signupForm['role'].value}
                    valid={this.state.signupForm['role'].valid}
                    touched={this.state.signupForm['role'].touched}
                  />
                </div>
                <div className='grid-item3'>
                  <Button
                    design='raised'
                    type='submit'
                  >
                    Opret virksomhed
                  </Button>
                </div>
              </div>
            </form>
          </SignUpAuth>
        </div>
      </div>
    );
  }
}

export default Signup;
