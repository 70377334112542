import { Icon } from '@material-ui/core';
import PostAddIcon from '@material-ui/icons/PostAdd';
import React, { useEffect, useRef, useState } from 'react';
import MessageList from '../MessageList/MessageList';
import QuickReply from '../QuickReply/QuickReply';
import SendMessageForm from '../SendMessageForm/SendMessageForm';
import './ChatPanel.css';
import {storage} from '../../util/fire';
import api from '../../util/config';

const ChatPanel = (props) => {
  const [selectedFile, setSelectedFile] = useState('');

  const onChangeHandlerFile = (event) => {
    setSelectedFile(event.target.files[0]);
  }

  const onFinishFile = () => {
    if(selectedFile !== ''){
      setSelectedFile('');
    }
  }

  const sendChatNotification = () => {
    let creator = '';
    let assigneeIDS = [];
    let client = props.clientIds;
    for (let i = 0; i < props.assigneeIds?.length; i++) {
      const assigneeId = props.assigneeIds[i]?._id;
      assigneeIDS.push(assigneeId);
  }
  if(localStorage.role === 'Company' && props.assigneeIds === null || props.assigneeIds === undefined){
       let aIds = localStorage.sortedAssignees.split(',');
        assigneeIDS = aIds;
    }
    if(localStorage.role === 'SuperAdmin' && assigneeIDS.length > 0){
      assigneeIDS = [props.assigneeID]
  }
    if(client === null || client === undefined){
        client = localStorage.uId;
    }
    if(localStorage.role === 'Company'){
        creator = localStorage.companyName;
      }
      else if(localStorage.role === 'SuperAdmin'){
        creator = localStorage.firstname;
      }
      else {
        creator = `${localStorage.firstname} ${localStorage.lastname}`;
      }
    const graphqlQuery = {
        query: `
        query sendChatNotifications($users:[String]!, $title: String!, $text: String!, $dataID: String) {
            sendChatNotifications(users: $users, title: $title, text: $text, dataID: $dataID)
          }
        `,
        variables: {
          users: [client, ...assigneeIDS],
          title: 'Ny besked',
          text: `${creator} har sendt en ny besked`,
          dataID: props.roomId,
        }
      }
      fetch(api.baseUrl, {
        method: 'POST',
        body: JSON.stringify(graphqlQuery),
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
          'Content-Type': 'application/json'
        }
      });
}

  const onFileSend = (e, url) => {
    e.preventDefault();
    let message = {
        created: Date.now(),
        text: '',
        author: props.userId,
        roomId: props.roomId,
        email: props.email,
        userFirstname: localStorage.firstname,
        userLastname: localStorage.lastname !== null && localStorage.lastname !== 'null' ? localStorage.lastname : '',
        role: localStorage.role,
    }
    if(selectedFile !== ''){
        let uploadTask = storage
        .ref(`${selectedFile.name}`)
        .put(selectedFile);
        uploadTask.on(
            "state_changed",
            snapshot => 
            {},
            error => {
                //TODO HANDLE ERROR
            },() => {
            storage.ref()
            .child(selectedFile.name)
            .getDownloadURL()
            .then(url => {
                message = {
                    created: Date.now(),
                    text: '',
                    author: props.userId,
                    roomId: props.roomId,
                    email: props.email,
                    userFirstname: localStorage.firstname,
                    userLastname: localStorage.lastname !== null && localStorage.lastname !== 'null' ? localStorage.lastname : '',
                    role: localStorage.role,
                    imageId: url,
                }
               props.sendMessage(message);
                onFinishFile();
                sendChatNotification();
            })
        })
    } 
  }

  return (
  <div style={{overflowY:"hidden"}}>
    <div className='chatpanel-messageList'>
      <MessageList
        id='message-list-chat'
        messages={props.messages}
        userId={props.userId}
        senderUID={props.senderUID}
      />
    </div>
    <div className='container'>
      <div className='item1'>
        <SendMessageForm
          sendMessage={props.sendMessage}
          email={props.email}
          roomId={props.roomId}
          userId={props.userId}
          cId={props.cId}
          aId={props.aId}
          userFirstname={localStorage.firstname}
          userLastname={localStorage.firstname}
          role={localStorage.role}
          attachment={selectedFile}
          onFinishFile={onFinishFile}
          assigneeIds={props.assigneeIds}
          clientIds={props.clientIds}
          assigneeID={props.assigneeID}
        />
      </div>
      <div className='item2'>
        <label for='file-input'>
          <PostAddIcon style={{ fontSize: 40, cursor: 'pointer' }}>add_circle</PostAddIcon>
          <input id='file-input' type='file' onChange={(event)=>{onChangeHandlerFile(event)}} />
        </label>
        <p>{selectedFile && selectedFile.name}</p>
        {<button onClick={onFileSend} style={{marginRight:'15px'}}>Send fil</button>}
        {<button onClick={()=>{onFinishFile()}}>Fjern fil</button>}
      </div>
    </div>
  </div>

);
}
export default ChatPanel;
